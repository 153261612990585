<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import { assetViewServiceKey } from '../../keys/asset-view-service.key';
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import Upload from '@/general/ui/components/upload.vue';
import BetterTranslationService from '@/general/services/translations/better-translation.service';

const service = useService(assetViewServiceKey, AssetViewService);
const ts = new BetterTranslationService().platform('supplier').module('media');
</script>

<template>
  <div class="p-grid">
    <div>
      <div
        v-if="service.matchMediaModal.gtinsFound.length > 0"
        class="align-items-center flex flex-wrap gap-2 pup-mb-3 pup-px-3"
      >
        <span class="font-semibold">{{ ts.translate('matchMedia.foundGtins') }}:</span>
        <Tag
          v-for="gtin in service.matchMediaModal.gtinsFound.map((gtin) => gtin.gtin)"
          :key="gtin"
          severity="secondary"
          icon="mdi mdi-barcode-scan"
          :value="gtin"
          :pt="{ icon: { class: 'h-auto text-2xl mr-3' } }"
        />
      </div>
      <div v-else>
        <Message severity="error" icon="mdi mdi-barcode-scan">
          <span class="ml-2">{{ ts.translate('matchMedia.noGtinsFound') }}</span>
        </Message>
      </div>
      <div class="p-col-12">
        <div v-for="file in service.checkedAssets" :key="file.id" class="p-fileupload-file-list">
          <div class="p-fileupload-file">
            <Upload v-if="file.public_thumbnail_url" preview :upload="file" class="w-4rem" />
            <SvgIcon v-else :filename="file.public_url" custom-style="width: 4rem" />
            <div class="p-fileupload-file-info">
              <div class="p-fileupload-file-name">
                {{ file.filename }}
              </div>
              <span class="p-fileupload-file-size">
                {{ file.file_size }}
              </span>
            </div>
            <div class="p-fileupload-file-actions">
              <Button
                severity="danger"
                text
                rounded
                icon="mdi mdi-trash-can-outline"
                @click="service.deselectAsset(file)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
