<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import BrandSelect from '@/general/ui/components/selects/brand-select.vue';
import CategorySelect from '@/general/ui/components/selects/category-select.vue';
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import { isNegative } from '@/general/utils/isNegative';
import useFormValidation from 'composables/form-validation';
import { Brand, BrandsRestService } from 'platform-unit2-api/brands';
import { Category } from 'platform-unit2-api/categories';
import { Datamodel } from 'platform-unit2-api/datamodels';
import { CreateProductRequest, ProductsRestService } from 'platform-unit2-api/products';
import CrudSidebar from 'ui/components/crud-sidebar.vue';
import { onMounted, ref } from 'vue';
import { RouteLocationRaw, RouteParamsRaw, useRoute, useRouter } from 'vue-router';

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'refresh'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const tsBrands = new TranslationService('supplier', 'brands');
const tsCategories = new TranslationService('supplier', 'categories');
const brandsApi = new BrandsRestService();
const productsApi = new ProductsRestService();

/** Consts */
const router = useRouter();
const route = useRoute();
const { resetFormErrors, parseFormError, fieldErrorMessage, hasError } = useFormValidation();

const newProductName = ref('');
const gtin = ref('');
const selectedCategory = ref<Category>();
const selectedDatamodel = ref<Datamodel>();
const selectedBrand = ref<Brand>();
const introducedAt = ref<Date>();
const delistedAt = ref<Date>();

const saving = ref(false);

const handleSubmit = async (): Promise<void> => {
  resetFormErrors();
  try {
    saving.value = true;
    const createProduct: CreateProductRequest = {
      display_name: newProductName.value ?? '',
      gtin: gtin.value ?? '',
      category_id: selectedCategory.value?.id,
      datamodel_id: selectedDatamodel.value?.id ?? 0,
      brand_id: selectedBrand.value?.id,
      introduced_at: introducedAt.value ? introducedAt.value : undefined,
      delisted_at: delistedAt.value ? delistedAt.value : undefined,
    };
    const createdProduct = await productsApi.post(createProduct);
    emit('refresh');
    hideDetails();

    router.push({
      name: 'product-attributes',
      params: {
        id: createdProduct.id.toString(),
      } as RouteParamsRaw,
    } as RouteLocationRaw);
    toastService.displaySuccessToast(ts.createSuccess(createdProduct.display_name));
  } catch (err) {
    parseFormError(err, () => {
      toastService.displayErrorToast(ts.createFailed());
    });
  } finally {
    saving.value = false;
  }
};

const hideDetails = () => {
  emit('hide');
};

const loadBrandData = async (): Promise<void> => {
  if (isNegative(route.params.brandId)) {
    toastService.displayErrorToast(ts.loadFailed());
    return;
  }

  try {
    selectedBrand.value = await brandsApi.get(Number(route.params.brandId));
  } catch (err) {
    toastService.displayErrorToast(ts.loadFailed());
  }
};

onMounted(() => {
  if (route.params.brandId) loadBrandData();
});
</script>

<template>
  <CrudSidebar
    :disable-save="newProductName == '' || selectedDatamodel == null"
    :saving="saving"
    @cancel="$emit('hide')"
    @save="handleSubmit"
  >
    <template #sidebar-data>
      <div class="field mb-3">
        <label for="display_name">{{ ts.tModule('product_name') }}</label>
        <InputText
          id="display_name"
          v-model="newProductName"
          class="w-full"
          :class="{ 'p-invalid': hasError('display_name') }"
          type="text"
        />
        <small
          v-if="hasError('display_name')"
          :class="{ 'p-error block': hasError('display_name') }"
          class="hidden"
          >{{ fieldErrorMessage('display_name').toString() }}</small
        >
      </div>
      <div class="field mb-3">
        <label for="gtin">{{ ts.tGlobal('gtin') }}</label>
        <InputText id="gtin" v-model="gtin" class="w-full" type="text" />
        <small
          v-if="hasError('gtin')"
          :class="{ 'p-error block': hasError('gtin') }"
          class="hidden"
          >{{ fieldErrorMessage('gtin').toString() }}</small
        >
      </div>

      <div class="field mb-3">
        <DatamodelSelect id="datamodel" v-model="selectedDatamodel" />
        <small
          v-if="hasError('datamodel_id')"
          :class="{ 'p-error block': hasError('datamodel_id') }"
          class="hidden"
          >{{ fieldErrorMessage('datamodel_id').toString() }}</small
        >
      </div>

      <div class="field mb-3">
        <CategorySelect
          v-model="selectedCategory"
          :label="tsCategories.title + ' (' + ts.tGlobal('optional') + ')'"
        />
      </div>

      <div class="field mb-3">
        <label for="brand">{{ tsBrands.title + '(' + ts.tGlobal('optional') + ')' }}</label>
        <BrandSelect v-model="selectedBrand" hide-label />
      </div>

      <div class="field mb-3">
        <label for="introduced_at">
          {{ ts.tModule('introduced_at') + ' (' + ts.tGlobal('optional') + ')' }}
        </label>
        <DatePicker
          v-model="introducedAt"
          show-time
          show-icon
          show-button-bar
          date-format="dd/mm/yy"
          class="w-full"
        />
      </div>

      <div class="field mb-3">
        <label for="delisted_at">
          {{ ts.tModule('delisted_at') + ' (' + ts.tGlobal('optional') + ')' }}
        </label>
        <DatePicker
          v-model="delistedAt"
          show-time
          show-icon
          show-button-bar
          date-format="dd/mm/yy"
          class="w-full"
        />
      </div>
    </template>
  </CrudSidebar>
</template>
