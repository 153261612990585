import { DebugLogger } from '@/core/main/debug.logger';
import { ToastService } from '@/general/services/toasts/toast.service';
import {
  ProductsRestService,
  DatamodelsRestService,
  VariantsRestService,
  UploadsRestService,
  ProductStatusesRestService,
  ModulesRestService,
  CategoryRestService,
  ProductStoriesRestService,
} from 'platform-unit2-api';

type DetailsLoading =
  | 'loadingProduct'
  | 'loadingDatamodels'
  | 'loadingVariants'
  | 'loadingProductStatuses'
  | 'loadingModules'
  | 'loadingCategories'
  | 'loadingProductStories'
  | 'loadingAssets'
  | 'loadingPrevAndNextProducts'
  | 'loadingChangeStatus';

export interface TryCatchLoadingWrapperParams {
  loading: DetailsLoading;
  successMessage?: string;
  errorMessage?: string;
  callback: Function;
  errorCallback?: (e: unknown) => void;
}

export class ProductDetailsRestHelper {
  toastService = ToastService.getInstance();
  loadingProduct = false;
  loadingChangeStatus = false;
  loadingDatamodels = false;
  loadingVariants = false;
  loadingProductStatuses = false;
  loadingModules = false;
  loadingCategories = false;
  loadingProductStories = false;
  loadingAssets = false;
  loadingPrevAndNextProducts = false;

  protected _productRest = new ProductsRestService();
  protected _datamodelRest = new DatamodelsRestService();
  protected _variantRest = new VariantsRestService();
  protected _uploadRest = new UploadsRestService();
  protected _productStatusApi = new ProductStatusesRestService();
  protected _moduleRest = new ModulesRestService();
  protected _categoryRest = new CategoryRestService();
  protected _productStoryRest = new ProductStoriesRestService();

  async tryCatchLoadingWrapper(params: TryCatchLoadingWrapperParams) {
    this[params.loading] = true;
    try {
      await params.callback();
      params.successMessage && this.toastService.displaySuccessToast(params.successMessage);
    } catch (error) {
      DebugLogger.error(error);
      params.errorCallback?.(error);
      params.errorMessage && this.toastService.displayErrorToast(params.errorMessage);
    } finally {
      this[params.loading] = false;
    }
  }

  async executeAsyncFunctionsAtTheSameTime(asyncFunctions: TryCatchLoadingWrapperParams[]) {
    for (const fn of asyncFunctions) {
      this.tryCatchLoadingWrapper(fn);
    }
  }

  async awaitEveryAsyncFunction(asyncFunctions: TryCatchLoadingWrapperParams[]) {
    for (const fn of asyncFunctions) {
      await this.tryCatchLoadingWrapper(fn);
    }
  }
}
