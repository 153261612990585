<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import LazyLoader from '@/general/ui/components/lazyLoader.vue';
import ActivitiesSkeleton from '@/general/ui/components/skeletons/customs/activities-skeleton.vue';
import { formatDate } from '@/general/utils/format-date';
import { groupBy, map, sortBy } from 'lodash';
import { Locale, LocalesRestService, ProductsRestService } from 'platform-unit2-api';
import { Activity } from 'platform-unit2-api/activities';
import ActivityService from 'supplier/modules/activities/activity.service';
import { onMounted, ref } from 'vue';
import ActivitiesAccordion from '../components/activities-accordion.vue';
import { useService } from '@/general/composables/useService';
import { activityServiceServiceKey } from '../activity.key';

/** Define the interface for the props */
interface ActivityProps {
  isActive: boolean;
  moduleId?: number;
}

interface ActivityGroupedByDate {
  date: string;
  activities: Activity[];
}

export interface ActivityGroupedByTime {
  time: string;
  activities: Activity[];
}

interface ActivityGroupedByDateAndTime {
  date: string;
  activities: ActivityGroupedByTime[];
}

/** Props */
withDefaults(defineProps<ActivityProps>(), {
  isActive: false,
  moduleId: undefined,
});

/** Emits */
defineEmits<{
  (e: 'hide'): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');
const locales = ref<Locale[]>([]);
const loadingLocales = ref(false);

/** constants */
const activityService = useService(activityServiceServiceKey, ActivityService);

const groupByDate = (activities: Activity[]): ActivityGroupedByDate[] => {
  return map(
    groupBy(activities, (activity) => formatDate(new Date(activity.created_at), 'MMM DD, YYYY')),
    (activities, date) => ({
      date,
      activities,
    }),
  );
};

const groupByTime = (activities: Activity[]): ActivityGroupedByTime[] => {
  return map(
    groupBy(activities, (activity) => formatDate(new Date(activity.created_at), 'hh:mm:ss')),
    (activities, time) => ({
      time,
      activities,
    }),
  );
};

const sortChronologically = (data: ActivityGroupedByDate[]) => {
  return sortBy(data, (record) => new Date(record.date)).reverse();
};

const api = new ProductsRestService();

const getTimelineData = (activities: Activity[]): ActivityGroupedByDateAndTime[] => {
  const sortedByDate = sortChronologically(groupByDate(activities));
  const groupedByTime = sortedByDate.map((record) => {
    return {
      date: record.date,
      activities: groupByTime(record.activities),
    };
  });
  return groupedByTime;
};

onMounted(async () => {
  const localeApi = new LocalesRestService();
  loadingLocales.value = true;
  locales.value = (await localeApi.getAll()).data;
  loadingLocales.value = false;
});
</script>
<template>
  <Drawer
    :visible="isActive"
    class="p-drawer-md"
    :dismissable="false"
    :modal="false"
    position="right"
    :header="ts.tModule('product_activities.title')"
    :pt="{
      content: { class: 'overflow-hidden' },
    }"
    @update:visible="$emit('hide')"
  >
    <div v-if="loadingLocales || moduleId == null"></div>
    <!-- Activity list -->
    <LazyLoader
      v-else
      :api="api"
      fetch-function-name="getProductActivities"
      :params="[moduleId]"
      :style="{ height: 'calc(100vh - 75px)' }"
      class="mt-5"
    >
      <template #layout="slotProps: { data: Activity[] }">
        <Timeline
          :value="getTimelineData(slotProps.data)"
          :pt="{ eventOpposite: { class: 'hidden' } }"
        >
          <template #content="dateActivity: { item: ActivityGroupedByDateAndTime }">
            <div class="font-semibold mb-2">
              {{ dateActivity.item.date }}
            </div>
            <div class="w-full">
              <div v-for="timeActivity in dateActivity.item.activities" :key="timeActivity.time">
                <ActivitiesAccordion
                  v-if="timeActivity.activities.length > 1"
                  :locales="locales"
                  :time-activity="timeActivity"
                />

                <Component
                  :is="activityService.generateSingleActivityBox(activity)"
                  v-for="activity in timeActivity.activities"
                  v-else
                  :key="activity.id"
                  :activity="activity"
                  :locales="locales"
                  class="pup-my-6"
                />
              </div>
            </div>
          </template>
        </Timeline>
      </template>

      <!-- Loading -->
      <template #loading>
        <ActivitiesSkeleton />
      </template>
    </LazyLoader>
  </Drawer>
</template>
