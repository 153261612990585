<script setup lang="ts">
import { inject } from 'vue';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { isNegative } from '@/general/utils/isNegative';
import { Datamodel } from 'platform-unit2-api';
import { useService } from '@/general/composables/useService';
import { productDetailsServiceKey } from '../key/product-details-service.key';
import { ProductDetailsService } from '../services/product-details.service';

/** Props */
interface Props {
  datamodel?: Datamodel;
  isSelected: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  datamodel: undefined,
  isSelected: false,
});

const productAttributeFieldService = inject(productAttributeFieldsServiceKey)!;
const productDetailsService = useService(productDetailsServiceKey, ProductDetailsService);

const countFilledInAttributes = () => {
  const dmAttributes = (props.datamodel?.attributes ?? []).map((attribute) => attribute.id);
  const result = productAttributeFieldService.value.getFilledInAttributesForDatamodel(
    dmAttributes ?? [],
  );

  if (result == null || isNegative(result) || result === 0) {
    return 0;
  }

  return result;
};

const getPercentage = () => {
  if (countFilledInAttributes() === 0) {
    return 0;
  }

  return (countFilledInAttributes() / (props.datamodel?.attributes_count ?? 1)) * 100;
};
</script>
<template>
  <div
    class="border-1 border-200 border-round cursor-pointer flex flex-column hover:bg-primary mb-3 p-3"
    :class="isSelected ? 'bg-primary' : 'bg-white'"
    @click="
      () => {
        productDetailsService.selectedDatamodel = productDetailsService.productDatamodels.find(
          (dm) => dm.id === props.datamodel?.id,
        )
        productAttributeFieldService.datamodels = [productDetailsService.selectedDatamodel!.id]
    }
    "
  >
    <h5 :class="'m-0 mb-2'">{{ datamodel?.name }}</h5>
    <ProgressBar
      :progress="Math.round(getPercentage())"
      :label="countFilledInAttributes() + '/' + (datamodel?.attributes_count ?? 1)"
      :tooltip="Math.round(getPercentage()) + '%'"
      :class="isSelected ? 'is-selected' : ''"
    />
  </div>
</template>
<style lang="scss" scoped>
#datamodel-select:hover :deep(.p-progressbar) {
  background-color: #0066c7;
}
#datamodel-select:hover :deep(.p-progressbar .p-progressbar-value) {
  background-color: #ffffff;
}

.is-selected :deep(.p-progressbar) {
  background-color: #0066c7;
}
.is-selected :deep(.p-progressbar .p-progressbar-value) {
  background-color: #ffffff;
}
</style>
