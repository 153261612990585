import { UserMode } from 'platform-unit2-api/core';
import { FeaturePermission } from 'platform-unit2-api/roles';
import { authorizationMethods } from 'composables/authorization.plugin';

import store from '@/core/store/index';

import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { RouterService } from '@/core/router/router.service';
import { KeycloakService } from '@/core/security/keycloak.service';
import cacheService from '../services/cache.service';

export default async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> => {
  if (!to.path.includes(from.path)) {
    cacheService.emptyCache();
  }

  const routeService = RouterService.getInstance();

  await routeService.resolveRoutes();
  const { hasAllPermissions, hasModePermission } = authorizationMethods;

  const router = routeService.router;

  if (!router.hasRoute(to.name ?? '')) {
    return next({ name: 'default' });
  }

  document.title = (to.meta.title as string) ?? '';

  const noPermissionRoute = { name: 'noPermission' };

  for (const route of to.matched) {
    // Accept if authentication disabled
    if (route.meta.hasOwnProperty('disableAuthentication') && route.meta.disableAuthentication) {
      return next();
    }

    // User should have a valid refreshToken or accessToken
    if (!KeycloakService.getInstance().isAuthenticated()) {
      await KeycloakService.getInstance().login(window.location.href);
    } else {
      await KeycloakService.getInstance().updateToken();

      // // // Make sure the current authenticated user details are available
      if (!store.getters?.['users/currentUser']) {
        try {
          await store.dispatch('users/GET_CURRENT_USER_DETAILS');
        } catch (ex) {
          // Logout to prevent infinite redirects
          await KeycloakService.getInstance().logout(window.location.href);
        }
      }

      // If user is not authorized navigate to dashboard
      if (
        route.meta.hasOwnProperty('rights') &&
        !hasAllPermissions(...((route.meta.rights as FeaturePermission[]) ?? []))
      ) {
        return next({ name: 'default' });
      }

      //If user is not in the right panel mode
      if (
        route.meta.hasOwnProperty('userMode') &&
        !hasModePermission((route.meta.userMode as UserMode) ?? UserMode.None)
      ) {
        return next(noPermissionRoute);
      }
    }
  }

  return next();
};
