<script setup lang="ts">
import BetterTranslationService from '@/general/services/translations/better-translation.service';
import { ActivityEventTypeEnum } from 'platform-unit2-api';
import ActivityBox, { ActivityBoxProps } from './activity-box.vue';

const props = defineProps<ActivityBoxProps>();

const ts = new BetterTranslationService().platform('supplier').module('activities');

const isFromMatchMedia = () => {
  return props.activity?.event === ActivityEventTypeEnum.DETACH;
};
</script>
<template>
  <ActivityBox v-bind="{ ...props }">
    <div class="flex">
      <i class="mdi mdi-image-check pup-mr-3 text-green-500" />
      <div class="w-full">
        <div class="font-semibold pup-mb-2 w-full">
          {{ ts.translate('assetAttached') }}
          <span v-if="isFromMatchMedia()">(via Auto-match)</span>
        </div>

        <div>{{ activity.modified?.upload?.new?.filename }}</div>
      </div>
    </div>
  </ActivityBox>
</template>
