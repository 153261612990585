<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import Upload from '@/general/ui/components/upload.vue';
import { formatDate } from '@/general/utils/format-date';
import { MatchedProductUploadsResponse } from 'platform-unit2-api';
import { assetViewServiceKey } from '../../keys/asset-view-service.key';

interface Props {
  result: MatchedProductUploadsResponse;
}
defineProps<Props>();
const service = useService(assetViewServiceKey, AssetViewService);
</script>

<template>
  <div class="mr-5 shadow-1 w-full">
    <div class="bg-white flex flex-column gap-2 p-3">
      <div class="flex">
        <Upload :upload="result.product?.thumbnail" preview class="mr-3 w-3rem" />
        <div class="overflow-hidden text-overflow-ellipsis white-space-nowrap">
          <p
            v-tooltip.top="result.product?.display_name"
            class="overflow-hidden text-overflow-ellipsis"
          >
            {{ result.product?.display_name }}
          </p>
          <small>
            <Tag
              :value="`${Math.round(result.product?.completeness ?? 0)}%`"
              rounded
              class="mr-2 px-2 py-0"
            />
            {{ result.product != null ? formatDate(new Date(result.product.updated_at)) : '' }}
          </small>
        </div>
        <Tag
          severity="secondary"
          icon="mdi mdi-barcode-scan"
          :value="result.value"
          class="align-self-center ml-auto"
          :pt="{ icon: { class: 'h-auto text-2xl mr-3' } }"
        />
      </div>
    </div>

    <div class="grid p-3 pb-5">
      <div
        v-for="upload in result.thumbnails"
        :key="upload.id"
        class="align-content-center align-items-center col-3 flex flex-column h-12rem justify-content-center"
      >
        <div
          class="align-items-center border-round-md flex flex-column h-full justify-content-center pup-p-3 relative shadow-1 w-full"
        >
          <Upload
            v-if="upload.public_thumbnail_url"
            :preview="false"
            :upload="upload"
            :size="'100'"
            class="mb-2 w-5rem"
          />
          <SvgIcon v-else :filename="upload.public_url" custom-style="width: 5rem" />
          <span>{{ upload.filename }}</span>
          <Tag :value="upload?.asset_type?.name" severity="secondary" class="my-2" />
          <Button
            icon="mdi mdi-close"
            text
            plain
            class="absolute right-0 top-0"
            @click="service.matchMediaModal.removeUploadFromMatch(upload.id, result.product)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
