<script setup lang="ts">
//Core
import { onBeforeMount, onMounted, provide, ref, Ref, watch } from 'vue';
import { useStore } from 'vuex';

//Components
import EmptyState from '@/general/ui/components/empty-state.vue';
import ProgressBar from '@/general/ui/components/progress-bar.vue';
import CategorySelect from '@/general/ui/components/selects/category-select.vue';
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import ProductHierarchySelect from '@/general/ui/components/selects/product-hierarchy-select.vue';
import StatusSelect from '@/general/ui/components/selects/product-status-select.vue';
import VariantSelect from '@/general/ui/components/selects/variant-select.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import Upload from '@/general/ui/components/upload.vue';

//Composables and Services
import { authorizationMethods } from '@/general/composables/authorization.plugin';

//Types
import { TranslationService } from '@/general/services/translations/translation.service';
import BrandSelect from '@/general/ui/components/selects/brand-select.vue';
import ClientSelect from '@/general/ui/components/selects/client-select.vue';
import { formatDate } from '@/general/utils/format-date';
import { ActionStatusSeverityEnum } from 'platform-unit2-api/action-statuses';
import { Product, ProductUpload } from 'platform-unit2-api/products';
import { FeaturePermission } from 'platform-unit2-api/roles';
import { DataTablePageEvent, DataTableSortEvent } from 'primevue/datatable';
import { productTableServiceKey } from '../../key/product-table-service.key';
import ProductTableService from '../../services/product-table.service';
import ProductTableDateFilter from './product-table-date-filter.vue';
import ProductsTableHeader from './products-table-header.vue';

/** Props */
interface Props {
  productIds?: number[];
  excludedProductsIds?: number[];
  module?: string;
  selectedProducts?: any[];
  attachingMedia?: boolean;
  refreshProducts?: boolean;
  loadData?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  productIds: () => [],
  excludedProductsIds: () => [],
  module: undefined,
  selectedProducts: () => [],
  attachingMedia: false,
  refreshProducts: false,
  loadData: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'update:checkedRows', checkedRows: Product[]): void;
  (e: 'reset-refresh'): void;
  (e: 'create-product'): void;
}>();

const store = useStore();
const workspaceName = store.getters['users/currentUser'].workspace?.name;
const { hasPermission } = authorizationMethods;

/** Services */
const productTableService = ref(
  new ProductTableService(workspaceName, props.excludedProductsIds, props.productIds),
);
provide(productTableServiceKey, productTableService as unknown as Ref<ProductTableService>);

watch(
  () => props.refreshProducts,
  () => {
    props.refreshProducts && productTableService.value.getProducts();
  },
);

watch(
  () => productTableService.value.checkedProductRows,
  () => emit('update:checkedRows', productTableService.value.checkedProductRows),
);

onBeforeMount(async () => {
  productTableService.value.onBeforeMountInit();
});

onMounted(async () => {
  await productTableService.value.getHierarchyPositions();
  props.module === 'media' && (productTableService.value.limit = 5);

  await productTableService.value.checkUserPreferences();

  props.selectedProducts && (productTableService.value.checkedProductRows = props.selectedProducts);
  productTableService.value.getProducts();
});
</script>
<template>
  <DataTable
    v-model:selection="productTableService.checkedProductRows"
    v-model:filters="productTableService.productFilters"
    style="position: sticky; bottom: 0; width: 100%"
    :global-filter-fields="[
      'variant_name',
      'hierarchy_position',
      'gtin',
      'owner_id',
      'datamodel_id',
      'brand_id',
      'product_status_id',
      'gtins',
      'module_id',
      'created_at',
      'hierarchy_position',
    ]"
    size="small"
    :rows="productTableService.limit"
    :total-records="productTableService.total"
    :first="productTableService.first"
    :rows-per-page-options="[5, 15, 50, 100, 250, 500]"
    current-page-report-template="Showing {first} to {last} of {totalRecords}"
    paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    show-gridlines
    :value="productTableService.products"
    :loading="productTableService.loading || attachingMedia"
    filter-display="menu"
    :row-hover="true"
    data-key="id"
    :sort-order="productTableService.productFilters.table_sort?.sortOrder ?? undefined"
    :sort-field="productTableService.productFilters.table_sort?.fieldName ?? undefined"
    scrollable
    scroll-height="flex"
    removable-sort
    lazy
    :pt="{
      header: {
        style: 'border: 0; background: inherit;',
      },
    }"
    class="border-0"
    :paginator="productTableService.total > 0"
    @row-click="productTableService.onRowClick($event)"
    @page="($event:DataTablePageEvent) => productTableService.onDatatablePageEvent($event)"
    @sort="($event:DataTableSortEvent) => {productTableService.onModifiedTableSort($event, true), productTableService.applyFilterCustom(false, true)}"
    @filter="productTableService.applyFilterCustom(true, true)"
  >
    <template #empty>
      <EmptyState
        v-if="!productTableService.loading"
        style="max-width: 80vw"
        class="pt-5"
        :translation-service="(productTableService.ts as TranslationService)"
        :icon-name="'products'"
        :button-icon="'mdi mdi-plus'"
        @clicked="emit('create-product')"
      />
    </template>
    <!-- Header of the table used to display search, column filter, filter removals,
    next iteration also bulk actions and export -->
    <template #header>
      <ProductsTableHeader />
    </template>

    <!-- First two column, These are frozen. Shows the checkbox to select the product and the thumbnail.
    These are both the header and the body,-->
    <!-- Checkbox column-->
    <Column frozen style="min-width: 4rem; flex-grow: 0" class="z-1">
      <template #header>
        <div class="flex justify-content-center w-full">
          <Checkbox v-model="productTableService.isAllChecked" :binary="true" />
        </div>
      </template>
      <template #body="slotProps">
        <div id="select-checkbox" class="flex justify-content-center w-full">
          <Checkbox
            v-model="productTableService.checkedProductRows"
            name="products"
            :value="slotProps.data"
          />
        </div>
      </template>
    </Column>
    <!-- thumbnail column-->
    <Column
      frozen
      style="flex-grow: 1"
      class="z-1"
      :style="'border-right: 1px solid #dfe7ef; box-shadow: 0 0 15px rgba(90, 113, 135, 0.28)!important; clip-path: inset(0px -15px 0px 0px);'"
    >
      <template #body="slotProps">
        <Upload
          preview
          :upload="slotProps.data.thumbnail"
          additional-container-style="width: 48px !important;"
        />
      </template>
    </Column>

    <!-- Dynamically generated columns based on the columns selected.
    the given templates are for the #body and the #filterModel-->

    <Column
      v-for="(col, index) of productTableService.tableSelectedColumns"
      :key="col.field + '_' + index"
      :field="col.field"
      :header="col.header"
      :sortable="col.sortable"
      :show-filter-match-modes="false"
      style="cursor: pointer"
      :style="
        col.type === 'gtin' ? 'border-left: 0px; min-width: ' + col.size : 'min-width: ' + col.size
      "
      :class="[col.type === 'images_included' ? 'flex-grow-1' : '', col.type]"
    >
      <template #body="{ data }">
        <template v-if="col.type === 'gtin'">
          {{ data.gtin ? data.gtin : '' }}
        </template>
        <template v-if="col.type === 'display_name'">
          {{ data.display_name }}
        </template>

        <template v-if="col.type === 'variant'">
          {{ data.variant_name ? data.variant_name : 'Master Data' }}
        </template>
        <template v-if="col.type === 'hierarchy_position'">
          {{
            data.hierarchy_position &&
            productTableService.hierarchyPositions[data.hierarchy_position]
          }}
        </template>

        <template v-if="col.type === 'shared_retailer' && data.variant_name != null">
          <Checkbox v-model="data.public" :binary="true" :disabled="true" class="ml-2" />
        </template>

        <template v-if="col.type === 'owner'">
          {{ data.owner.name }}
        </template>

        <template v-if="col.type === 'category'">
          {{ data.category ? data.category.name : '' }}
        </template>

        <template v-if="col.type === 'datamodel'">
          {{ data.datamodel ? data.datamodel.name : '' }}
        </template>

        <template v-if="col.type === 'brand'">
          {{ data.brand ? data.brand.name : '' }}
        </template>

        <template v-if="col.type === 'module'">
          {{ data.module ? data.module.name : '' }}
        </template>

        <template v-if="col.type === 'completeness'">
          <ProgressBar :progress="Math.round(data.completeness) ?? 0" width="w-10rem" />
        </template>

        <template v-if="col.type === 'updated_at'">
          {{ formatDate(data.updated_at) }}
        </template>
        <template v-if="col.type === 'created_at'">
          {{ formatDate(data.created_at) }}
        </template>
        <template v-if="col.type === 'status'">
          <StatusChip
            :label="data.status?.label"
            :severity="ActionStatusSeverityEnum[data.status?.label?.toUpperCase() as keyof typeof ActionStatusSeverityEnum]"
            label-only
          />
        </template>
        <template v-if="col.type === 'images_included'">
          <div class="flex flex-row gap-1">
            <div
              v-for="item in data.images_included.sort((a: ProductUpload, b: ProductUpload) => a.order - b.order)"
              :key="item.id"
              style="width: 4rem !important; padding-right: 4px"
            >
              <Upload
                v-if="item.public_thumbnail_url"
                preview
                :upload="item"
                additional-container-style="width: 4rem !important;"
              />

              <SvgIcon
                v-else
                :filename="item.public_url"
                custom-style="width: 4rem !important"
                class="flex-shrink-0"
              />
            </div>
          </div>
        </template>
      </template>

      <template
        v-if="
          col.type === 'variant' ||
          col.type === 'hierarchy_position' ||
          col.type === 'owner' ||
          col.type === 'category' ||
          col.type === 'datamodel' ||
          col.type === 'brand' ||
          col.type === 'module' ||
          col.type === 'status' ||
          col.type === 'created_at'
        "
        #filter="{ filterModel }"
      >
        <template v-if="col.type === 'variant'">
          <VariantSelect
            v-model="filterModel.value"
            class="filter-style"
            display="comma"
            multiselect
            hide-label
          />
        </template>
        <template v-if="col.type === 'hierarchy_position'">
          <ProductHierarchySelect
            v-model="filterModel.value"
            class="filter-style"
            display="comma"
            multiselect
            hide-label
          />
        </template>

        <template v-if="col.type === 'owner'">
          <ClientSelect
            v-model="filterModel.value"
            class="filter-style"
            multiselect
            only-used-by-products
            display="comma"
            hide-label
          />
        </template>

        <template v-if="col.type === 'datamodel'">
          <div class="filter-style">
            <DatamodelSelect
              v-model="filterModel.value"
              :only-used-by-products="true"
              display="comma"
              hide-label
              multiselect
            />
          </div>
        </template>

        <template v-if="col.type === 'category'">
          <CategorySelect
            v-model="filterModel.value"
            class="filter-style"
            display="comma"
            multiselect
            hide-label
          />
        </template>

        <template v-if="col.type === 'brand'">
          <BrandSelect
            v-model="filterModel.value"
            multiselect
            class="filter-style"
            only-used-by-products
            hide-label
            display="comma"
          />
        </template>

        <template v-if="col.type === 'module'">
          <ModuleSelect
            v-model="filterModel.value"
            class="filter-style"
            :only-used-by-products="true"
            display="comma"
            multiselect
            hide-label
          />
        </template>

        <template v-if="col.type === 'status'">
          <StatusSelect
            v-model="filterModel.value"
            class="filter-style"
            display="comma"
            multiselect
            hide-label
          />
        </template>

        <template v-if="col.type === 'created_at'">
          <ProductTableDateFilter
            :filter-model="filterModel"
            @update:model-value="($event: null[]|string[]) => filterModel.value = $event"
          />
        </template>
      </template>
    </Column>

    <Column
      v-if="hasPermission(FeaturePermission.MANAGE_PRODUCTS) && module !== 'media'"
      style="min-width: 6rem; flex-grow: 0"
      :style="'border-left: 1px solid #dfe7ef; box-shadow: 0 0 15px rgba(90, 113, 135, 0.28)!important; clip-path: inset(0px 0px 0px -15px);'"
      frozen
      class="text-center"
      align-frozen="right"
    >
      <template #header>
        <div class="flex justify-content-center w-full">
          <p>{{ productTableService.ts.tGlobal('actions') }}</p>
        </div>
      </template>

      <template #body="slotProps">
        <Button
          v-tooltip.bottom="productTableService.ts.tGlobal('delete')"
          icon="mdi mdi-delete-outline"
          text
          rounded
          severity="danger"
          :disabled="productTableService.loadingProductIds.includes(slotProps.data.variant_uuid)"
          @click="(event: MouseEvent) => productTableService.confirmProductDelete(event, slotProps.data)"
        />
      </template>
    </Column>
  </DataTable>
  <ConfirmPopup group="products" />
  <ConfirmDialog group="bulk-delete-dialog" />
</template>
<style lang="scss" scoped>
.filter-style {
  max-width: 150px;
}

.p-datatable :deep(.p-datatable-thead > tr > th) {
  font-size: 14px;
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  font-weight: 500;
  color: #13293d;
  background: #fff;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-datatable {
  overflow: auto;
  border: none;
  padding: 0px !important;
}

.p-datatable :deep(.p-datatable-header) {
  padding: 18px 0px 0px 0px !important;
}
</style>
