<script setup lang="ts" generic="API">
import { computed, onMounted, ref } from 'vue';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { RefetchService } from '@/general/services/overview-service/refetch.service';
import { PaginationObject } from 'platform-unit2-api';

// get function keys of an object
type FunctionKeys<T> = {
  [K in keyof T]: T[K] extends (...args: any) => any ? K : never;
}[keyof T];

interface Props<API> {
  api: API;
  fetchFunctionName: FunctionKeys<API>;
  params?: any;
}

const props = defineProps<Props<API>>();

/** constants */
const data = ref<any[]>([]);
const pagination = computed<PaginationObject>(() => ({
  page: refetchService.page,
  limit: 100,
  query: refetchService.query,
  sortBy: 'created_at',
}));

const refetchService = new RefetchService();
refetchService.refetch = () => {};

const loading = ref(false);

/** custom directive for scrolling */
const scrollDivHeight = ref(0);

const vScroll = {
  mounted: (el: HTMLElement) => {
    let lastScrollTop = 0; // To detect if the user is scrolling up or down
    scrollDivHeight.value = el!.scrollHeight;
    el.addEventListener('scroll', () => {
      if (el!.scrollTop < lastScrollTop) {
        // user is scrolling up
        return;
      }

      lastScrollTop = el!.scrollTop <= 0 ? 0 : el!.scrollTop;
      if (Math.ceil(el!.scrollTop + (el! as HTMLElement).offsetHeight + 300) >= el!.scrollHeight) {
        // User has reached to the end of scrollbar
        fetchTheNextPageData();
      }
    });
  },
};

const fetchData = async () => {
  loading.value = true;

  const response = await (props.api[props.fetchFunctionName] as Function)(
    ...(props.params ?? []),
    pagination.value,
  );
  data.value = [...data.value, ...response.data];
  refetchService.total = response.meta.total;
};

const fetchTheNextPageData = async () => {
  if (refetchService.total > data.value.length && !loading.value) {
    refetchService.page!++;
    await fetchData();
  }

  loading.value = false;
};

onMounted(async () => {
  loading.value = true;
  await fetchData();

  loading.value = false;
});
</script>

<template>
  <div v-scroll class="overflow-scroll pb-5">
    <slot name="layout" :data="data" :loading="loading"></slot>
    <div v-if="loading" class="m-2">
      <slot name="loading"><LoadingIndicator /></slot>
    </div>
  </div>
</template>
