<script lang="ts" setup>
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import LabeledTag from '@/general/ui/components/labeled-tag.vue';
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import Upload from '@/general/ui/components/upload.vue';
import { assetViewServiceKey } from '@/platforms/supplier/modules/media/assets/keys/asset-view-service.key';
import { Upload as UploadType } from 'platform-unit2-api/uploads';

interface Props {
  image: UploadType;
}

defineProps<Props>();

// Services
const service = useService(assetViewServiceKey, AssetViewService);
</script>

<template>
  <div class="col-12 flex flex-column md:col-6 p-2" :class="'lg:col-' + 3 + ' xl:col-' + 3">
    <Card
      :class="`${!service?.disabled ? 'clickable' : ''} ${
        service?.checkedAssets.includes(image) ? 'border-primary' : ''
      }`"
      class="align-items-between flex flex-column justify-content-between w-full"
      @click="() => service?.openCrudComponent(image)"
    >
      <template #content>
        <div class="align-items-center flex flex-column justify-content-center">
          <div
            class="align-items-center flex h-18rem justify-content-center max-w-full mb-4 w-18rem"
          >
            <Upload
              v-if="image.public_thumbnail_url"
              :preview="service?.disabled"
              :upload="image"
            />
            <SvgIcon v-else :filename="image.filename" custom-style="width: 8rem" />
          </div>

          <!-- <p
            v-if="service?.checkedIds?.includes(image.id)"
            class="absolute align-items-center bg-white-alpha-70 bottom-0 cursor-auto flex h-full justify-content-center left-0 right-0 text-align-center z-1"
          >
            <label class="font-bold text-xl">{{ ts.tModule('media_picker.image_selected') }}</label>
          </p> -->

          <div
            class="my-3 overflow-hidden text-center text-overflow-ellipsis w-full white-space-nowrap"
          >
            <span>{{ image.filename }}</span>
          </div>
        </div>

        <div v-if="service?.disabled" class="align-items-between flex">
          <Checkbox v-model="service.checkedAssets" :value="image" />

          <label v-if="image.asset_type" class="mr-auto">{{ image.asset_type.name }}</label>
          <LabeledTag :filename="image.filename" />
        </div>

        <div v-if="service" class="flex justify-content-between">
          <Checkbox v-model="service.checkedAssets" :value="image" @click.stop />

          <label v-if="image.asset_type" class="mr-auto">{{ image.asset_type.name }}</label>
          <LabeledTag :filename="image.filename" />
        </div>
      </template>
    </Card>
  </div>
</template>
