<script setup lang="ts">
import ApplicationLoader from '@/core/app/components/application-loader.vue';
import UnifiedNavigation from '@/core/app/components/unified-navigation.vue';
import { RouterService } from '@/core/router/router.service';
import { KeycloakService } from '@/core/security/keycloak.service';
import store from '@/core/store';
import { authorizationMethods } from '@/general/composables/authorization.plugin';
import { AdminModeToggleService } from '@/general/services/admin-mode-toggle.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Client, User } from 'platform-unit2-api';
import { computed, onMounted, ref } from 'vue';
import Upload from '../../components/upload.vue';
import AccountMenu from '../../layout/components/account-menu.vue';
import HelpAndSupportSidebar from '../../layout/components/help-and-support-sidebar.vue';

const helpAndSupportVisible = ref(false);
const searchWord = ref('');

const currentUser = computed<User>(() => store.getters['users/currentUser']);
const adminModeToggleService = new AdminModeToggleService();
const ts = new TranslationService('general', 'components');
const loading = ref(false);
const helpAndSupportToVisible = (value: boolean) => {
  helpAndSupportVisible.value = value;
};

const hasAccessToCrossNavigation = ref(false);

const { hasRole } = authorizationMethods;

const sortedWorkspaces = computed<Client[]>(() => {
  return (currentUser?.value.clients ?? [])
    .map((item: Client) => item)
    .sort((a: Client, b: Client) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
});

const filteredWorkspaces = computed<Client[]>(() => {
  return sortedWorkspaces.value.filter((workspace) =>
    workspace.name.toLocaleLowerCase().includes(searchWord.value.toLocaleLowerCase()),
  );
});

const updateWorkspace = async (id: number) => {
  try {
    if (currentUser.value?.workspace?.id === id) {
      return;
    }

    loading.value = true;
    await store.dispatch('users/UPDATE_WORKSPACE', {
      userId: currentUser.value.id,
      workspaceId: id,
    });
    ToastService.getInstance().displaySuccessToast(
      ts.tModule('applicationHeader.switchSuccessful'),
    );
  } catch (err) {
    ToastService.getInstance().displayErrorToast(ts.tModule('applicationHeader.switchFailed'));
  } finally {
    await removeFiltersFromStorage();
    await RouterService.getInstance().go({ name: 'default' });
    loading.value = false;
  }
};

const removeFiltersFromStorage = async () => {
  await store.dispatch('products/RESET_FILTERS');
  await store.dispatch('products/SAVE_SEARCH', '');
};

const switchToAdminMode = async () => {
  loading.value = true;
  try {
    await adminModeToggleService.switchToAdminPanel();
    ToastService.getInstance().displaySuccessToast(ts.tModule('userMenu.switchSuccessful'));
  } catch (err) {
    ToastService.getInstance().displayErrorToast(ts.tModule('userMenu.switchFailed'));
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  hasAccessToCrossNavigation.value = KeycloakService.getInstance().hasAccessToResource('platform');
});
</script>
<template>
  <section class="flex h-full w-full">
    <UnifiedNavigation v-if="hasAccessToCrossNavigation" />
    <ApplicationLoader v-if="loading" class="w-full" />
    <div v-else class="overflow-hidden w-full">
      <div class="align-items-center bg-white border-bottom flex justify-content-between pup-p-4">
        <div></div>
        <div>
          <div class="flex">
            <Button
              v-tooltip.bottom="{
                value: 'Help and Support',
                class: 'text-sm',
              }"
              text
              plain
              rounded
              class="pup-h-3 pup-mr-4 pup-w-3"
              @click="helpAndSupportToVisible(true)"
              ><template #icon>
                <Image src="/src/assets/images/pup_ic_question.svg"></Image>
              </template>
            </Button>
            <AccountMenu />
            <!-- Sidebar, it is not visible in layout -->
            <HelpAndSupportSidebar
              :visible="helpAndSupportVisible"
              @hide="helpAndSupportToVisible(false)"
            />
          </div>
        </div>
      </div>
      <div class="flex h-full justify-content-center w-full">
        <div
          class="align-items-center flex flex-column h-9 pup-mt-10 pup-workspace-selection-panel w-6 w-full"
        >
          <h2 class="pup-mb-10">
            {{ ts.tModule('navigation.welcomeMessage', { params: { name: currentUser.name } }) }}
          </h2>

          <InputText
            v-model="searchWord"
            type="text"
            placeholder="Search"
            class="pup-mb-10 w-full"
            icon="mdi mdi-magnify"
          />
          <ul class="overflow-scroll overflow-x-hidden w-full">
            <li
              v-for="workspace in filteredWorkspaces"
              :key="workspace.id"
              class="align-items-center cursor-pointer flex hover:bg-gray-100 justify-content-between pup-py-3 w-full"
              :class="{ 'bg-gray-100': currentUser?.workspace?.id === workspace.id }"
              @click="updateWorkspace(workspace.id)"
            >
              <div class="align-items-center flex">
                <div class="logo">
                  <Upload :upload="workspace.thumbnail" />
                </div>
                <span
                  class="font-bold overflow-hidden pup-ml-6 text-overflow-ellipsis"
                  style="max-width: 80%"
                >
                  {{ workspace.name }}
                </span>
              </div>

              <span class="pup-mr-5 text-gray-500">
                {{ workspace.products_count }}
                {{ ts.tModule('navigation.entities.products').toLowerCase() }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="absolute bottom-0 pup-m-6 right-0">
        <!-- Admin mode switch -->
        <div v-if="hasRole({ name: 'admin' })">
          <Button
            text
            icon="mdi mdi-open-in-new"
            icon-pos="right"
            label="Switch to Admin"
            class="ml-2 mt-3"
            @click="switchToAdminMode()"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped lang="scss">
.border-bottom {
  border-bottom: 2px solid #e5e5e5;
}

.pup-workspace-selection-panel {
  height: 80%;
}

.logo {
  width: 46px;
  border-radius: 3px;
  margin: 0 10px;

  img {
    object-fit: cover;
    border-radius: 3px;
  }
}
</style>
