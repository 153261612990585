<script setup lang="ts">
import { inject, ref, watch, watchEffect } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Attribute } from 'platform-unit2-api/attributes';
import ProductDatamodelSelect from './product-datamodel-select.vue';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { useService } from '@/general/composables/useService';
import { productDetailsServiceKey } from '../key/product-details-service.key';
import { ProductDetailsService } from '../services/product-details.service';

/** Services */
const ts = new TranslationService('supplier', 'products');
const tsAttributes = new TranslationService('supplier', 'attributes');
const productAttributeFieldService = inject(productAttributeFieldsServiceKey);
const productDetailsService = useService(productDetailsServiceKey, ProductDetailsService);

const datamodels = ref(productDetailsService.productDatamodels);

watchEffect(() => {
  productDetailsService.selectedDatamodel =
    productDetailsService?.productDatamodels.find(
      (pd) => pd.id === productAttributeFieldService?.value.datamodels?.[0],
    ) ?? productDetailsService?.productDatamodels[0];
  datamodels.value = productDetailsService.productDatamodels;
});

watch(
  () => productAttributeFieldService?.value.searchQuery,
  () => {
    if (productAttributeFieldService?.value.searchQuery) {
      datamodels.value = [];
      productDetailsService.productDatamodels.forEach((datamodel) => {
        const foundAttributeKeys: Attribute[] = datamodel.attributes.filter((attribute) =>
          attribute.key
            .trim()
            .toLocaleLowerCase()
            .includes(
              (productAttributeFieldService?.value.searchQuery ?? '').trim().toLocaleLowerCase(),
            ),
        );

        foundAttributeKeys.length !== 0 && datamodels.value.push(datamodel);
      });

      return;
    }

    datamodels.value = productDetailsService.productDatamodels;
  },
);
</script>
<template>
  <div class="col-fixed h-full overflow-y-auto w-19rem" style="padding-top: 0 !important">
    <div class="bg-gray-50 pt-3 sticky top-0 z-1">
      <div class="p-3">
        <h4 class="mb-3">{{ ts.tModule('product_details.sidebar_navigation.title') }}:</h4>
        <ul class="list-style-none text-lg">
          <router-link
            active-class="text-primary font-bold"
            inactive-class="text-primary font-bold"
            class="block cursor-pointer hover:text-primary mb-2 py-1 text-gray-500"
            :class="[$route.name === 'product-attributes' && 'text-primary']"
            :to="{ name: 'product-attributes' }"
          >
            <i class="mdi mdi-format-list-bulleted mr-2"></i>
            <span class="2xl text-xl">{{ tsAttributes.tModule('title', { choice: 2 }) }}</span>
          </router-link>
          <router-link
            active-class="text-primary font-bold"
            inactive-class="text-primary font-bold"
            class="block cursor-pointer hover:text-primary mb-2 py-1 text-gray-500"
            :class="[$route.name === 'product-assets' && 'text-primary']"
            :to="{ name: 'product-assets' }"
          >
            <i class="mdi mdi-image mr-2"></i>
            <span class="2xl text-xl">{{ ts.tGlobal('assets', { choice: 2 }) }}</span>
          </router-link>
          <router-link
            active-class="text-primary font-bold"
            inactive-class="text-primary font-bold"
            class="block cursor-pointer hover:text-primary mb-2 py-1 text-gray-500"
            :class="[$route.name === 'product-story' && 'text-primary']"
            :to="{ name: 'product-story' }"
          >
            <i class="mdi mdi-format-size mr-2 text-2xl"></i>
            <span class="text-xl">{{ ts.tModule('story') }}</span>
          </router-link>
          <!-- Category -->

          <router-link
            v-if="productDetailsService.currentVariant"
            active-class="text-primary font-bold"
            inactive-class="text-primary font-bold"
            class="block cursor-pointer hover:text-primary mb-2 py-1 text-gray-500"
            :class="[$route.name === 'product-category' && 'text-primary']"
            :to="{ name: 'product-category' }"
          >
            <i class="mdi mdi-shape mr-2 text-2xl"></i>
            <span class="text-xl">{{ ts.tGlobal('category', { params: { count: 2 } }) }}</span>
            <i
              v-tooltip.right="ts.tModule('categoriesHelpText')"
              class="cursor-pointer mdi mdi-help-circle-outline ml-2 text-xl"
            />
          </router-link>

          <!--  Hierarchy -->
          <router-link
            active-class="text-primary font-bold"
            inactive-class="text-primary font-bold"
            class="block cursor-pointer hover:text-primary mb-2 py-1 text-gray-500"
            :class="[$route.name === 'product-hierarchy' && 'text-primary']"
            :to="{ name: 'product-hierarchy' }"
          >
            <i class="mdi mdi-sitemap-outline mr-2 text-2xl"></i>
            <span class="text-xl">{{ ts.tModule('hierarchy') }}</span>
          </router-link>
        </ul>
      </div>
      <div v-if="$route.name === 'product-attributes'" class="p-3 pb-0">
        <!-- is done to showcase how many data models are connected
        previously it was dm + all atributes button now its only dm's -->
        <OverlayBadge
          v-if="productDetailsService.productDatamodels.length - 1 > 0"
          :value="productDetailsService.productDatamodels.length"
          class="w-max"
        >
          <h4 class="inline-block mb-3">
            {{ ts.tModule('product_details.sidebar_navigation.attribute_groups') }}:
          </h4>
        </OverlayBadge>
        <h4 v-else class="inline-block mb-3">
          {{ ts.tModule('product_details.sidebar_navigation.attribute_groups') }}:
        </h4>

        <!-- Search input -->
        <IconField icon-position="left">
          <InputIcon class="pi pi-search" />
          <InputText
            v-model="productAttributeFieldService!.searchQuery"
            :placeholder="ts.tModule('attribute_search_placeholder')"
            :disabled="productDetailsService.loadingDatamodels"
            class="w-full"
          />
        </IconField>
      </div>
    </div>

    <div v-if="$route.name === 'product-attributes'" class="p-3 sticky top-auto">
      <!-- DATAMODELS SELECTORS -->
      <span v-if="productDetailsService.loadingDatamodels">
        <Skeleton class="mb-5" width="100%" height="8rem" border-radius="10px" />
        <Skeleton class="mb-5" width="100%" height="8rem" border-radius="10px" />
        <Skeleton class="mb-5" width="100%" height="8rem" border-radius="10px" />
      </span>
      <template v-for="(datamodel, index) in datamodels" v-else :key="datamodel.id">
        <ProductDatamodelSelect
          :datamodel="datamodel"
          :is-selected="productDetailsService.selectedDatamodel?.id === datamodel.id"
        />
        <Divider v-if="index == 0" class="border-1 border-white" />
      </template>
    </div>
  </div>
</template>
