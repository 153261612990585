<script setup lang="ts">
import BetterTranslationService from '@/general/services/translations/better-translation.service';
import { Activity } from 'platform-unit2-api';
defineProps<{ activity: Activity }>();
const ts = new BetterTranslationService().platform('supplier').module('activities');
</script>
<template>
  <div class="flex flex-column">
    <div>
      <i class="mdi mdi-electric-switch-closed pup-mr-3 text-primary" />
      <span class="font-semibold"> {{ ts.translate('updatedStatus') }} </span>
    </div>

    <div class="pup-mt-2">
      <Chip :label="activity.modified?.productstatus_label?.old?.toString() ?? '...'" />
      <i class="mdi mdi-arrow-right pup-mx-2" />
      <Chip :label="activity.modified?.productstatus_label?.new?.toString() ?? '...'" />
    </div>
  </div>
</template>
