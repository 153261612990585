import { Activity } from 'platform-unit2-api/activities';
<script setup lang="ts">
import ActivityBox, { ActivityBoxProps } from './activity-box.vue';
import BetterTranslationService from '@/general/services/translations/better-translation.service';

const props = defineProps<ActivityBoxProps>();
const ts = new BetterTranslationService().platform('supplier').module('activities');
</script>
<template>
  <ActivityBox v-if="activity?.modified?.display_name?.new" v-bind="{ ...props }">
    <div class="flex">
      <i class="align-items-center mdi mdi-plus pup-mr-3 text-green-500" />
      <div>
        <div class="font-semibold pup-mb-2">
          {{ ts.translate('productCreated') }} -
          {{ activity.modified?.variant_name.new ?? 'Master data' }}
          <span v-if="activity.resource == null">(deleted after)</span>
        </div>
        <div>
          {{ activity?.modified?.display_name?.new }}
        </div>
      </div>
    </div>
  </ActivityBox>
</template>
