import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const update: TranslationObjectAction = {
  title: 'Edit upload',
  subTitle: 'Here you can edit your upload',
};

const create: TranslationObjectAction = {
  title: 'Created',
  subTitle: 'Here you can edit your upload',
};

const translationObject = {
  title: 'Assets',
  placeholder: 'Select a datamodel | Select datamodels',
  emptyStateSubtitle:
    "Start by uploading your first media file. Use our 'Match media' feature to match media files to existing products.",
  create: create,
  update: update,

  assets: 'Asset | Assets',
  variant_name: 'Variant name',
  resolution: 'Resolution',
  choose_image: 'Choose image',
  collection: {
    title: 'Collection | Collections',
    emptyState: {
      subtitle:
        'A collection is automatically generated based on Variant name, Datamodel name, Brand or Status\nwhen the media is attached to a product.',
      button: 'Go to assets',
    },
  },
  datamodels: {
    title: 'Datamodel | Datamodels',
  },
  brands: {
    title: 'Brand | Brands',
  },
  actions: {
    delete_images: 'Delete',
    attach_labels: 'Add to product(s)',
    download_images: 'Download',
  },
  media_picker: {
    title: 'Add assets',
    search_placeholder: 'Search assets',
    image_selected: '{count} image selected | {count} images selected',
    view_original: 'View original',
    choose_image: 'Choose image',
  },
  match: 'Match',
  matchMedia: {
    title: 'Automatch',
    subtitle: 'Automatch assets to products',
    tooltip: 'Automatically match assets to your products',
    overview: 'Overview',
    result: 'Result',
    matched: 'Matched to products',
    notMatched: 'Not matched to products',
    foundGtins: 'GTINs found',
    noGtinsFound: 'No GTINs found in selected assets labels',
    noProductsMatched: 'We could not match any products',
    noProductsUnmatched: 'Everything seems to be matched',
    noConfiguration: 'No configuration has been made',
    successfullyAssigned: 'Successfully assigned assets to products',
    couldNotMatch: 'Could not match any assets to products',
    couldNotAssign: 'Could not assign assets to products',
    overwrite: 'Overwrite existing filenames',
    updateVariants: 'Add media to all variants',
    uploadAssets: 'Upload assets',
  },
  startOver: 'Start over',
  filter_placeholder: 'Filter for collection',
  import_assets: {
    title: 'Import assets',
    success: 'Successfully imported files.',
    error: 'Failed to import matched assets. Please try again.',
    no_asset_info:
      "Bulk media imports let you automatically add product images, video's and documents to many products at once by matching the GTIN in the filename. You can also add the order in which the files should be imported. For example:",
    match_description:
      'You are about to attach {amountUploads} files to {amountProducts} products. Do you wish to continue?',
    existing_files: 'Overwrite existing filenames',
    overwrite_tooltip:
      'When selecting this option, duplicate filenames will be overwritten with the newly imported media.',
    add_to_variants: {
      title: 'Add media to all variants',
      tooltip:
        'When selecting this option, the uploaded media will be added to all variants of a product.',
    },
    unmatched_assets: 'The following {amount} file(s) could not be matched automatically',
    selected: '{amount} selected files',
    match_product: 'Attach to product',
    matched_assets: 'The following products will be updated',
    match_failed: 'Failed to match assets to products. Please try again.',
  },
  informations: 'Information | Informations',
  attach_media_dialog: {
    title: 'Select products',
    subtitle: 'Select the products you want to attach images to',
    attach_to:
      'Attach {imageCount} asset to {productCount} product | Attach {imageCount} assets to {productCount} products',
    success: 'The assets have been added to the selected products.',
    error: 'There was an error adding the assets to the selected products.',
  },
  sort: {
    new_old: 'Newest first',
    old_new: 'Oldest first',
  },
  uploads: 'Upload | Uploads',
} as const;

export default translationObject;
