<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import { assetViewServiceKey } from '../../keys/asset-view-service.key';
import MatchedProduct from './matched-product.vue';
import BetterTranslationService from '@/general/services/translations/better-translation.service';

const service = useService(assetViewServiceKey, AssetViewService);
const ts = new BetterTranslationService().platform('supplier').module('media');
</script>

<template>
  <div>
    <div
      v-if="service.matchMediaModal.loading"
      class="align-items-center flex h-30rem items-center justify-center"
    >
      <ProgressSpinner style="width: 40px; height: 40px" stroke-width="5" fill="transparent" />
    </div>
    <div v-else>
      <div v-if="service.matchMediaModal.matchedAssets.length > 0">
        <div
          v-for="result in service.matchMediaModal.matchedAssets"
          :key="result.value"
          class="pup-mb-4"
        >
          <MatchedProduct :result="result" />
        </div>
      </div>
      <div
        v-else
        class="align-items-center flex flex-column h-30rem items-center justify-content-center"
      >
        <i class="mdi mdi-barcode-scan text-gray-400" style="font-size: 100px" />
        <span class="font-semibold text-xl">{{
          ts.translate('matchMedia.noProductsMatched')
        }}</span>
      </div>
    </div>
  </div>
</template>
