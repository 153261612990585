<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { TranslationService } from '@/general/services/translations/translation.service';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';
import Upload from '@/general/ui/components/upload.vue';
import { ProductHierarchyService } from '@/platforms/supplier/modules/products/services/product-hierarchy.service';
import { ProductHierarchy } from 'platform-unit2-api/products';
import ProductMetaLinkProducts from 'supplier/modules/products/components/product-meta-link-products.vue';
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { productDetailsServiceKey } from '../key/product-details-service.key';
import { ProductDetailsService } from '../services/product-details.service';

const productDetailsService = useService(productDetailsServiceKey, ProductDetailsService);

const route = useRoute();
const router = useRouter();
const translationService = new TranslationService('supplier', 'products');
const hierarchyService = ref(new ProductHierarchyService());

const closeLinkedProductsModal = async (reload = false) => {
  hierarchyService.value.isLinkedProductsModalVisible = false;
  if (reload) {
    if (chart.value) {
      chart.value.style.left = 'initial';
      chart.value.style.top = 'initial';
      chart.value.style.position = 'absolute';
    }

    await hierarchyService.value.init();
  }
};

const manageNodeClick = ({
  productId,
  currentProduct,
}: {
  productId: number;
  currentProduct: boolean;
}) => {
  if (!isDragging.value) {
    if (currentProduct) {
      hierarchyService.value.isLinkedProductsModalVisible = true;
    } else {
      router.push({ name: 'product-hierarchy', params: { id: productId } });
      productDetailsService.getProduct(productId);
    }
  }
};

// #region zooming
const scale = ref(1);
const isDragging = ref(false); //Chart node click event won't trigger if it's in the dragging state
const chart = ref<HTMLElement>();

const changeScale = (value: number) => {
  scale.value += value;
  scale.value < 0.3 ? (scale.value = 0.3) : scale.value > 3 && (scale.value = 3);
};

const reset = () => {
  scale.value = 1;
  if (chart.value) {
    chart.value.style.left = 'initial';
    chart.value.style.top = 'initial';
    chart.value.style.position = 'absolute';
  }
};

const drag = (evt: PointerEvent) => {
  if (evt.currentTarget instanceof HTMLElement) {
    const el = evt.currentTarget! as HTMLElement;
    el.style.touchAction = 'none';
    const move = (evt: any) => {
      el.style.cursor = 'grabbing';
      el.style.left = `${el.offsetLeft + evt.movementX}px`;
      el.style.top = `${el.offsetTop + evt.movementY}px`;
    };

    const up = () => {
      el.style.cursor = 'grab';
      removeEventListener('pointermove', move);
      removeEventListener('pointerup', up);
    };

    addEventListener('pointermove', move);
    addEventListener('pointerup', up);
  }
};

//#endregion

/** if the variant changes */
watch(
  () => route,
  async () => {
    await hierarchyService.value.init();
  },
  {
    deep: true,
  },
);

/** Life cycle hooks */
onMounted(async () => {
  chart.value?.addEventListener('pointerdown', drag);
  await hierarchyService.value.init();
});
</script>
<template>
  <div class="wrapper">
    <!--  -->
    <div class="title">
      <h3>{{ translationService.tModule('product_details.hierarchies.title') }}</h3>
      <Button
        :loading="hierarchyService.loading"
        icon="mdi mdi-link"
        :label="translationService.tModule('product_details.hierarchies.link_products')"
        @click="hierarchyService.isLinkedProductsModalVisible = true"
      ></Button>
    </div>
    <div class="hierarchy-container">
      <div class="chart-header">
        <Select
          v-model="hierarchyService.selectedHierarchy"
          :options="hierarchyService.hierarchyOptions"
          :placeholder="translationService.tModule('product_details.hierarchies.select_hierarchy')"
        />
        <Button
          v-show="false"
          class="bg-primary-reverse"
          disabled
          icon="mdi mdi-export"
          :label="translationService.tAction('export', 'title')"
        ></Button>
      </div>
      <!-- Hierarchy Chart -->
      <div class="chart-visible-container">
        <div ref="chart" class="chart">
          <LoadingIndicator v-if="hierarchyService.loading" />
          <OrganizationChart
            v-else-if="hierarchyService.selectedHierarchyData"
            :value="hierarchyService.selectedHierarchyData"
            :style="{ transform: `scale(${scale})` }"
          >
            <template #default="slotProps: { node: ProductHierarchy }">
              <div
                class="chart-node"
                :class="{ currentNode: slotProps.node.current }"
                @click="
                  manageNodeClick({
                    productId: slotProps.node.data.product.id,
                    currentProduct: slotProps.node.current,
                  })
                "
                @mousemove="isDragging = true"
                @mousedown="isDragging = false"
              >
                <!-- Product image -->
                <div class="thumbnail">
                  <Upload :upload="slotProps.node.data.product.thumbnail" />
                </div>

                <!-- Product data -->
                <div class="align-items-start flex flex-column">
                  <span class="gtin">
                    {{ slotProps.node.data.product.gtin }}
                  </span>
                  <span class="name">{{ slotProps.node.data.product.display_name }}</span>
                  <span v-if="slotProps.node.data.quantity" class="quantity"
                    >X{{ slotProps.node.data.quantity }}
                  </span>
                </div>

                <!-- Current product identifier -->
                <div v-if="slotProps.node.current" class="dot"></div>
              </div>
            </template>
          </OrganizationChart>
        </div>
      </div>
      <!-- Zooming buttons -->
      <div class="zoom">
        <Button
          v-tooltip.top="{ value: 'center view' }"
          class="zoom-reset"
          icon="mdi mdi-crosshairs-gps"
          @click="reset"
        ></Button>
        <Button class="zoom-in" label="+" @click="changeScale(0.3)"></Button>
        <Button class="zoom-out" label="-" @click="changeScale(-0.3)"></Button>
      </div>
      <!--  -->
    </div>
    <ProductMetaLinkProducts
      v-if="hierarchyService.currentProduct"
      :is-active="hierarchyService.isLinkedProductsModalVisible"
      :current-product="hierarchyService.currentProduct"
      :excluded-products="hierarchyService.excludedProductsids"
      @hide="closeLinkedProductsModal"
    ></ProductMetaLinkProducts>
  </div>
</template>
<style lang="scss" scoped>
.p-organizationchart :deep(.p-organizationchart-line-down) {
  background-color: #88a9c9;
}

.p-organizationchart :deep(.p-organizationchart-line-right) {
  border-left: 1px solid #88a9c9;
}

.p-organizationchart :deep(.p-organizationchart-line-top) {
  border-top: 1px solid #88a9c9;
}
.wrapper {
  width: 100%;

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
}

.chart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 16px;
  z-index: 1;
  position: inherit;
}

.hierarchy-container {
  background-color: #f4f7fa;
  width: 100%;
  height: 45vh;
  border-radius: 8px;
  border: 1px solid #dce5ef;
  background-image: radial-gradient(#dce5ef 1px, transparent 0);
  background-size: 14px 14px;
  padding: 16px 0;
  position: relative;

  & > * ::selection {
    background: rgba(0, 0, 0, 0);
  }
}

.chart-visible-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  padding-top: 60px;

  //Hide Scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.chart {
  position: absolute;
  cursor: grab;
}

.chart-node {
  min-width: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 11px;
  line-height: 1.5rem;
  position: relative;
  cursor: pointer;

  .thumbnail {
    width: 40px;
    max-height: 60px;
    margin-right: 11px;
    object-fit: cover;
    overflow: hidden;
  }

  .gtin,
  .quantity {
    font-weight: 600;
  }
  .name {
    font-weight: 400;
  }

  .dot {
    position: absolute;
    width: 11px;
    height: 11px;
    right: 0;
    top: 0;
    background-color: #377ef2;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  }
}

.zoom {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: end;
  flex-direction: column;
  padding: 16px;

  button {
    width: clamp(32px, 1.5vw, 50px);
    aspect-ratio: 1;
    border: 1px solid #dce5ef;
    background-color: #fff;
    color: #172533;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
  }
}

.zoom-in {
  border-radius: 3px 3px 0px 0px;
  border-bottom: none;
}

.zoom-out {
  border-radius: 0px 0px 3px 3px;
}

.zoom-reset {
  margin-bottom: 10px;
}

.p-organizationchart :deep(.p-organizationchart-node-content) {
  border-radius: 5px;

  &:has(.currentNode) {
    border: 1.5px solid #377ef2;
  }
}
</style>
