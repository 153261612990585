<script lang="ts" setup>
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import BetterTranslationService from '@/general/services/translations/better-translation.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import ActionsMenu from '@/general/ui/components/actions-menu.vue';
import EmptyState from '@/general/ui/components/empty-state.vue';
import LabeledTag from '@/general/ui/components/labeled-tag.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import UploadVue from '@/general/ui/components/upload.vue';
import { formatDate } from '@/general/utils/format-date';
import { Upload } from 'platform-unit2-api/uploads';
import { DataTablePageEvent } from 'primevue/datatable';
import { DataViewPageEvent } from 'primevue/dataview';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { FileUploadUploaderEvent } from 'primevue/fileupload';
import { ref } from 'vue';
import { assetViewServiceKey } from '../keys/asset-view-service.key';
import AssetCard from './asset-card.vue';

const service = useService(assetViewServiceKey, AssetViewService);
const ts = new TranslationService('supplier', 'media');
const newTs = new BetterTranslationService().platform('supplier').module('media');
const emit = defineEmits<{
  (e: 'setImages'): void;
}>();

const actionMenu = ref();

const actionItems = ref([
  {
    label: newTs.translate('actions.attach_labels'),
    icon: 'mdi mdi-plus-box-multiple',
    command: () => {
      service.attachAssetsModal.toggleVisible();
    },
  },
  {
    label: newTs.translate('actions.download_images'),
    icon: 'mdi  mdi-tray-arrow-down',
    command: () => {
      service.bulkDownload();
    },
  },
  {
    separator: true,
  },
  {
    label: newTs.translate('actions.delete_images'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      service.bulkDeleteAction();
    },
  },
]);

const optionItems = (asset: Upload): Array<Object> => {
  const items = [
    {
      label: newTs.global.translate('edit'),
      icon: 'mdi mdi-pencil-outline',
      command: () => {
        service.redirectToUpdate(asset.id);
      },
    },
    {
      label: newTs.global.translate('download'),
      icon: 'mdi mdi-download',
      command: () => {
        service.download(asset);
      },
    },
    {
      separator: true,
    },
    {
      label: newTs.global.translate('delete'),
      icon: 'mdi mdi-delete-outline',
      class: 'delete',
      command: () => {
        service.deleteAction(asset.id);
      },
    },
  ];

  return items;
};

const toggleActionMenu = (event: any): void => {
  actionMenu.value.toggle(event);
};

const fileUpload = ref();
</script>

<template>
  <div class="flex-auto">
    <div class="h-full">
      <div v-if="service.assets.length === 0" class="empty-state-container">
        <EmptyState
          :empty-state-title="
            newTs.global.translate('emptyStateTitle', { entity: newTs.translate('title') }, 2)
          "
          :translation-service="ts"
          :icon-name="'media'"
          :button-visible="false"
        >
          <template #body>
            <FileUpload
              ref="service.uploader"
              multiple
              auto
              class="mr-2"
              mode="basic"
              :choose-label="newTs.platform('global').module('words').translate('upload')"
              :disabled="service.isUploading"
              :custom-upload="true"
              @uploader="(e: FileUploadUploaderEvent) => service.uploadAssets(e)"
            />
          </template>
        </EmptyState>
      </div>
      <DataView
        v-else
        lazy
        scrollable
        scroll-height="flex"
        :value="service.assets"
        :layout="service.layout.value"
        paginator
        :rows-per-page-options="[20, 50, 100, 250, 500]"
        :rows="service.limit"
        :first="service.first"
        :total-records="service.total"
        class="flex flex-column h-full"
        :class="{ 'bg-white': service.layout.value === 'list' }"
        data-key="id"
        @page="(e: DataViewPageEvent) => service.onDatatablePageEvent(e)"
      >
        <template #header>
          <div class="flex justify-content-between">
            <div class="align-items-center flex gap-2 justify-content-between">
              <IconField icon-position="left" class="pup-pl-1">
                <InputIcon class="pi pi-search" />
                <InputText
                  v-model="service.query"
                  :placeholder="newTs.translate('media_picker.search_placeholder')"
                />
              </IconField>
              <Select
                :model-value="service.sortFields.find((f) => f.field === service.sortBy)"
                :options="service.sortFields"
                :option-value="''"
                option-label="label"
                @change="(e: DropdownChangeEvent) => service.onSortChange({sortField: e.value.field} as DataTablePageEvent)"
              />

              <SelectButton
                v-model="service.layout"
                :options="service.listTreeViewOptions"
                option-label="value"
                data-key="value"
              >
                <template #option="slotProps">
                  <i :class="slotProps.option.icon" />
                </template>
              </SelectButton>

              <Button
                text
                plain
                :label="service.renderSelectButtonLabel"
                :icon="service.renderSelectedStatusIcon"
                @click="service.manageSelectingAllFiles()"
              />
            </div>

            <!-- On assets page -->
            <div v-if="!service.disabled" class="align-items-center flex gap-2 justify-content-end">
              <Button
                v-tooltip.bottom="newTs.translate('matchMedia.tooltip')"
                :label="newTs.translate('matchMedia.title')"
                icon="mdi mdi-image-search-outline"
                :disabled="service.checkedAssets.length === 0"
                class="bg-white"
                outlined
                plain
                @click="service.matchMediaModal.toggleVisible()"
              />
              <FileUpload
                ref="fileUpload"
                :choose-label="newTs.global.translate('upload')"
                :disabled="service.isUploading"
                choose-icon="mdi mdi-cloud-upload-outline"
                auto
                multiple
                custom-upload
                mode="basic"
                @uploader="(e: FileUploadUploaderEvent) => service.uploadAssets(e)"
              />
              <Button
                :disabled="service.checkedAssets.length === 0"
                :model="actionItems"
                :label="newTs.global.translate('actions')"
                outlined
                plain
                class="bg-white"
                icon="mdi mdi-chevron-down"
                icon-pos="right"
                aria-haspopup="true"
                aria-controls="action_menu"
                @click="toggleActionMenu"
              />
              <Menu ref="actionMenu" :model="actionItems" popup />
            </div>

            <!-- On media picker page -->
            <div v-if="service.disabled" class="align-items-center flex gap-2 justify-content-end">
              <FileUpload
                ref="fileUpload"
                :choose-label="newTs.global.translate('upload')"
                :disabled="service.isUploading"
                choose-icon="mdi mdi-cloud-upload-outline"
                outlined
                plain
                class="bg-white border-gray-300 hover:bg-gray-50 text-gray-900"
                auto
                multiple
                custom-upload
                mode="basic"
                @uploader="(e: FileUploadUploaderEvent) => service.uploadAssets(e)"
              />
              <Button
                :disabled="service.checkedAssets.length === 0"
                :label="`${newTs.global.translate('add')} (${service.checkedAssets.length})`"
                @click="emit('setImages')"
              />
            </div>
          </div>
        </template>

        <template #list="slotProps">
          <div
            v-for="(item, index) in slotProps.items"
            :key="index"
            class="align-items-center col-12 cursor-pointer flex pup-px-4"
            :class="`${
              service.isChecked(item) ? 'bg-indigo-50 hover:bg-indigo-300' : 'hover:bg-gray-50'
            }`"
            @click="service.toggleChecked(item)"
          >
            <div :style="{ visibility: service.isChecked(item) ? 'visible' : 'hidden' }">
              <StatusChip class="text-2xl" label="" :icon-only="true" />
            </div>

            <div class="col-1">
              <div class="col-fixed">
                <UploadVue
                  v-if="item.public_thumbnail_url"
                  :upload="item"
                  preview
                  class="h-4rem pup-ml-3 w-4rem"
                />
                <SvgIcon v-else :filename="item.filename" class="h-4rem pup-ml-3 w-4rem" />
              </div>
            </div>
            <div class="col-5 overflow-hidden pup-ml-3 text-overflow-ellipsis">
              <p class="inline w-full">
                {{ item.filename }}
              </p>
            </div>
            <div class="col-1"><LabeledTag :filename="item.filename" /></div>
            <div class="col-1 overflow-hidden text-overflow-ellipsis">
              <Tag v-if="item.asset_type" :value="item?.asset_type?.name" severity="secondary" />
            </div>
            <div class="col-1 overflow-hidden text-overflow-ellipsis">
              {{ service.getFileSize(item) }}
            </div>
            <div class="col-2 overflow-hidden text-overflow-ellipsis">
              {{ formatDate(item.created_at) }}
            </div>
            <div class="col-1 flex justify-content-end pup-pr-8">
              <ActionsMenu :menu-items="optionItems(item)" />
            </div>
          </div>
        </template>

        <template #grid="slotProps">
          <div class="grid justify-content-between p-3 row-gap-2">
            <div v-for="(item, index) in slotProps.items" :key="index" class="col-20">
              <AssetCard :image="item" />
            </div>
          </div>
        </template>
      </DataView>
      <ConfirmDialog group="media-assets" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.col-20 {
  width: calc(100% / 5.1);
}

@media screen and (max-width: 992px) {
  .col-20 {
    width: calc(100% / 3.1);
  }
}

.buttonset {
  width: 80px;
  :deep(.p-button.p-button-icon-only) {
    width: 40px;
    height: 33px;
  }
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.p-dataview :deep(.p-dataview-content) {
  flex: 1;
}
</style>
