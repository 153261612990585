<script setup lang="ts">
import { DebugLogger } from '@/core/main/debug.logger';
import { useService } from '@/general/composables/useService';
import { FormValidationService } from '@/general/services/form-validation/form-validation.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import BrandSelect from '@/general/ui/components/selects/brand-select.vue';
import CategorySelect from '@/general/ui/components/selects/category-select.vue';
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import ModuleSelect from '@/general/ui/components/selects/module-select.vue';
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import { UpdateProductRequest } from 'platform-unit2-api';
import { computed, ref } from 'vue';
import { productDetailsServiceKey } from '../key/product-details-service.key';
import { ProductDetailsService } from '../services/product-details.service';

/**Props */

interface Props {
  isActive: boolean;
}

withDefaults(defineProps<Props>(), {
  isActive: false,
});

/**Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');
const productDetailsService = useService(productDetailsServiceKey, ProductDetailsService);
const formValidationService = new FormValidationService();
const productData = computed(() => ({ ...productDetailsService.currentVariant }));

//** Constants */
const introducedAt = ref<Date | undefined>(
  productDetailsService.currentVariant?.introduced_at != null
    ? new Date(productDetailsService.currentVariant?.introduced_at)
    : undefined,
);
const delistedAt = ref<Date | undefined>(
  productDetailsService.currentVariant?.delisted_at != null
    ? new Date(productDetailsService.currentVariant?.delisted_at)
    : undefined,
);

const handleSubmit = async () => {
  if (!productDetailsService.currentVariant) {
    DebugLogger.error('Product variant is not set');
    return;
  }

  productDetailsService.tryCatchLoadingWrapper({
    loading: 'loadingProduct',
    successMessage: ts.updateSuccess(),
    callback: async () => {
      await productDetailsService.updateProduct(productDetailsService.currentVariant!.id, {
        ...productData.value,
        category_id: productData.value.category?.id,
        introduced_at: introducedAt.value,
        delisted_at: delistedAt.value,
      } as UpdateProductRequest);

      formValidationService.resetErrors();

      emit('hide');
    },
    errorCallback: (ex) => {
      formValidationService.handleError(ex, () => {
        ts.updateFailed();
      });
    },
  });
};
</script>

<template>
  <Drawer
    :visible="isActive"
    class="p-drawer-md"
    :dismissable="false"
    position="right"
    :header="ts.tModule('product_details.product_meta_edit.update_product_data')"
    @update:visible="$emit('hide')"
  >
    <div class="h-full">
      <SidebarSkeleton
        v-if="!productDetailsService.currentVariant || productDetailsService.loadingProduct"
      />
      <div v-else class="flex flex-column h-full">
        <div class="field mb-3">
          <label for="product-name">{{ ts.tModule('product_name') }}</label>
          <InputText
            id="product-name"
            v-model="productData.display_name"
            class="w-full"
            type="text"
          />
        </div>

        <div class="field mb-3">
          <label for="gtin">{{ ts.tGlobal('gtin') }}</label>
          <InputText id="gtin" v-model="productData.gtin" class="w-full" type="text" />
          <small
            :class="{ 'p-error block': formValidationService.hasError('gtin') }"
            class="hidden"
            >{{ formValidationService.fieldErrorMessage('gtin').toString() }}</small
          >
        </div>

        <div v-if="productData.variant_name" class="field mb-3">
          <ModuleSelect id="module" v-model="productData.module" />
        </div>

        <div class="field mb-3">
          <BrandSelect v-model="productData.brand" />
        </div>
        <!-- just show category select in Master data -->
        <div v-if="!productData.module_id" class="field mb-3">
          <CategorySelect v-model="productData.category" />
        </div>
        <div v-if="productData.module?.template_id != null" class="field mb-3">
          <DatamodelSelect
            v-model="productData.datamodel"
            :template-id="productData.module?.template_id"
          />
        </div>

        <div class="field mb-3">
          <label for="introduced_at">
            {{
              ts.tModule('product_details.product_meta_edit.introduced_at') +
              ' (' +
              ts.tGlobal('optional') +
              ')'
            }}
          </label>
          <DatePicker
            v-model="introducedAt"
            show-time
            show-icon
            show-button-bar
            date-format="dd/mm/yy"
            class="w-full"
          />
        </div>

        <div class="field mb-3">
          <label for="delisted_at">
            {{
              ts.tModule('product_details.product_meta_edit.delisted_at') +
              ' (' +
              ts.tGlobal('optional') +
              ')'
            }}
          </label>
          <DatePicker
            v-model="delistedAt"
            :min-date="introducedAt"
            show-time
            show-icon
            show-button-bar
            date-format="dd/mm/yy"
            class="w-full"
          />
        </div>

        <div
          class="bg-white border-100 border-top-1 bottom-0 flex justify-content-end mt-auto py-3 sticky"
        >
          <Button text plain :label="ts.tGlobal('cancel')" class="mr-2" @click="$emit('hide')" />
          <Button
            :disabled="productDetailsService.loadingProduct"
            :label="ts.tGlobal('save')"
            icon-pos="right"
            :loading="productDetailsService.loadingProduct"
            @click="handleSubmit()"
          />
        </div>
      </div>
    </div>
  </Drawer>
</template>
