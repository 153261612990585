<script setup lang="ts">
import { ProductAttributeFieldsService } from '@/general/services/attribute-fields/product-attribute-fields.service';
import {
  productAttributeFieldsServiceKey,
  productAttributeValidationServiceKey,
} from '@/general/services/attribute-fields/service-keys';
import { Locale } from 'platform-unit2-api/locales';
import { ProductField } from 'platform-unit2-api/product-fields';
import ProductActionsGroup from 'supplier/modules/products/components/product-actions-group.vue';
import ProductDatamodelHeader from 'supplier/modules/products/components/product-datamodel-header.vue';
import ProductMeta from 'supplier/modules/products/components/product-meta.vue';
import ProductStatusBar from 'supplier/modules/products/components/product-status-bar.vue';
import SidebarNavigation from 'supplier/modules/products/components/product-sidebar-navigation.vue';
import ProductDetailsValidationPanel from 'supplier/modules/products/components/validation-rules/product-details-validation-panel.vue';
import { onMounted, provide, ref, Ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { ProductAttributesValidationService } from '../../../../../general/services/attribute-fields/product-attribute-validation.service';
import { useService } from '@/general/composables/useService';
import { productDetailsServiceKey } from '../key/product-details-service.key';
import { ProductDetailsService } from '../services/product-details.service';
import { ToastService } from '@/general/services/toasts/toast.service';

const route = useRoute();
const showShadow = ref(false);

const selectedLocales = ref<Locale[]>([]);
const filteredLocales = ref<Locale[]>([]);

const fields = ref<ProductField[]>([]);

const productAttributeValidationService = ref(new ProductAttributesValidationService());
const productAttributeFieldService = ref(new ProductAttributeFieldsService());
const productDetailsService = useService(productDetailsServiceKey, ProductDetailsService);

provide(
  productAttributeFieldsServiceKey,
  productAttributeFieldService as Ref<ProductAttributeFieldsService>,
);

provide(
  productAttributeValidationServiceKey,
  productAttributeValidationService as Ref<ProductAttributesValidationService>,
);

const getProductData = async () => {
  if (typeof +route.params.id === 'number') {
    await productDetailsService.getProduct(+route.params.id);
  }

  if (productDetailsService.currentVariant == null) {
    ToastService.getInstance().displayErrorToast('');
  }
};

/** Search in attributes */
const searchedWord = ref<string>();

watch(
  () => selectedLocales.value,
  () => {
    filteredLocales.value = selectedLocales.value;
    if (selectedLocales.value.length > 0) {
      productAttributeFieldService.value.locales = selectedLocales.value.map((locale) => locale.id);
    }
  },
);

// ^ Required watch
watch(
  () => productDetailsService.currentVariant,
  () => {
    productAttributeFieldService.value.currentProduct = productDetailsService.currentVariant;
  },
);

onMounted(async () => {
  await getProductData();
});
</script>
<template>
  <section class="flex flex-column h-full">
    <div class="flex flex-column h-full justify-content-around overflow-hidden">
      <div class="flex h-full overflow-hidden relative">
        <!-- Navigatoin -->
        <SidebarNavigation />
        <Card class="m-3 overflow-y-auto p-0 text-left w-full">
          <template #content>
            <div
              class="bg-white flex justify-content-between p-4 sticky top-0 z-3"
              :class="{ 'shadow-2 transition-duration-300': showShadow }"
              role="heading"
            >
              <ProductMeta />
              <ProductActionsGroup />
            </div>
            <div class="p-4">
              <!-- DATAMODEL HEADER -->
              <ProductDatamodelHeader />
              <router-view
                class="pt-3"
                :selected-datamodel="productDetailsService.selectedDatamodel"
                :fields="fields"
                :locales="selectedLocales"
                :filtered-locales="filteredLocales"
                :loading="productDetailsService.loadingProduct"
                :product="productDetailsService.currentVariant"
                :searched-word="searchedWord"
              />
            </div>
          </template>
        </Card>

        <!-- Validation Panel-->
        <ProductDetailsValidationPanel />
      </div>
      <ProductStatusBar />
    </div>
  </section>
</template>

<style lang="scss" scoped>
div[role='heading'] {
  color: $primary-text-color;
}
</style>
