import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonConstructorInterface } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-buttons-contructor.interface';
import MatchMediaStepperMatched from '@/platforms/supplier/modules/media/assets/components/match-media/match-media-stepper-matched.vue';
import MatchMediaStepperOverview from '@/platforms/supplier/modules/media/assets/components/match-media/match-media-stepper-overview.vue';
import MatchMediaStepperResult from '@/platforms/supplier/modules/media/assets/components/match-media/match-media-stepper-result.vue';
import MatchMediaStepperUnmatched from '@/platforms/supplier/modules/media/assets/components/match-media/match-media-stepper-unmatched.vue';
import { first } from 'lodash';
import {
  AttachUploadsRequest,
  MatchedProductUploadsResponse,
  MatchProductUpload,
  ProductCompact,
  ProductsRestService,
  Upload,
} from 'platform-unit2-api';
import AssetViewService from './asset-view.service';

export default class MatchMediaService {
  private _ts: TranslationService;
  private toastService;

  visible = false;
  loading = false;
  currentStep = 1;
  productApi = new ProductsRestService();
  steps = [
    { value: 1, label: 'matchMedia.overview', component: MatchMediaStepperOverview },
    { value: 2, label: 'matchMedia.matched', component: MatchMediaStepperMatched },
    { value: 3, label: 'matchMedia.notMatched', component: MatchMediaStepperUnmatched },
    { value: 4, label: 'matchMedia.result', component: MatchMediaStepperResult },
  ];
  gtinsFound: MatchProductUpload[] = [];
  matchedAssets: MatchedProductUploadsResponse[] = [];
  unmatchedAssets: MatchedProductUploadsResponse[] = [];
  overwrite = false;
  updateVariants = false;
  assetsViewServiceInstance: AssetViewService;

  get finalResult(): MatchedProductUploadsResponse[] {
    return [...this.matchedAssets, ...this.unmatchedAssets].filter(
      (result) => result.product != null,
    );
  }

  errorMatching = false;

  constructor(ts: TranslationService, assetsViewServiceInstance: AssetViewService) {
    this._ts = ts;
    this.toastService = ToastService.getInstance();
    this.assetsViewServiceInstance = assetsViewServiceInstance;
  }

  nextStep() {
    this.currentStep++;
  }

  public toggleVisible() {
    this.visible = !this.visible;
    if (!this.visible) {
      this.errorMatching = false;
      this.matchedAssets = [];
      this.unmatchedAssets = [];
      this.currentStep = 1;
    }
  }

  public getNextModalButtonConfig(): CrudButtonConstructorInterface {
    switch (this.currentStep) {
      case 1:
        return {
          label: this._ts.tGlobal('next'),
          position: CrudButtonPosition.RIGHT,
          onClick: async () => this.calculateProductMatches(),
        };
      case 2:
        return {
          label: this._ts.tGlobal('next'),
          position: CrudButtonPosition.RIGHT,
          onClick: () => this.nextStep(),
        };
      case 3:
        return {
          label: this._ts.tGlobal('next'),
          position: CrudButtonPosition.RIGHT,
          onClick: () => this.nextStep(),
        };
      case 4:
        return {
          label: this._ts.tGlobal('finish'),
          position: CrudButtonPosition.RIGHT,
          onClick: () => this.assignMatchesToProducts(),
        };
      default:
        return {
          label: this._ts.tGlobal('close'),
          position: CrudButtonPosition.RIGHT,
          onClick: () => this.toggleVisible(),
        };
    }
  }

  private async assignMatchesToProducts() {
    this.loading = true;
    try {
      const requestBody: AttachUploadsRequest[] = this.finalResult.map((matchedResult) => ({
        product_id: matchedResult.product!.id,
        uploads: matchedResult.thumbnails.map((thumbnail) => thumbnail.id),
      }));
      await this.productApi.attachProductUploads(requestBody, this.overwrite, this.updateVariants);
      this.overwrite = false;
      this.updateVariants = false;
      this.toastService.displaySuccessToast(this._ts.tModule('matchMedia.successfullyAssigned'));
    } catch (e) {
      this.toastService.displayErrorToast(this._ts.tModule('matchMedia.couldNotAssign'));
    } finally {
      this.loading = false;
      this.toggleVisible();
      // Refetch all assets
      this.assetsViewServiceInstance.refetch();
    }
  }

  private async calculateProductMatches() {
    this.nextStep();
    this.loading = true;
    try {
      const result = await this.productApi.matchProductUploads(this.gtinsFound);
      this.matchedAssets = result.filter((matchedProduct) => matchedProduct.product != null);
      this.unmatchedAssets = result.filter((matchedProduct) => matchedProduct.product == null);
    } catch (e) {
      this.errorMatching = true;
      this.toastService.displayErrorToast(this._ts.tModule('matchMedia.couldNotMatch'));
    } finally {
      this.loading = false;
    }
  }

  extractGTIN(fileName: string): string | undefined {
    // Regular expression to match potential GTINs at the start
    const gtinRegex = /\d{8}(?:\d{4,6})?/;
    const match = first(fileName.match(gtinRegex));
    return match;
  }

  assignGTINsToAssets(assets: Upload[]) {
    this.gtinsFound = [];
    assets.forEach((asset) => {
      const gtin = this.extractGTIN(asset.filename);

      if (gtin != null) {
        const existingRecord = this.gtinsFound.find((gtinFound) => gtinFound.gtin === gtin);

        if (existingRecord == null) {
          this.gtinsFound.push({ gtin, upload_ids: [asset.id] });
        } else {
          existingRecord.upload_ids.push(asset.id);
        }
      }
    });

    if (this.gtinsFound.length === 0) {
      this.errorMatching = true;
    } else {
      this.errorMatching = false;
    }
  }

  removeUploadFromMatch(assetId: number, product?: ProductCompact) {
    if (product?.id == null) {
      return;
    }

    const matchedProduct =
      this.matchedAssets.find((matchedProduct) => matchedProduct.product?.id === product.id) ??
      this.unmatchedAssets.find((matchedProduct) => matchedProduct.product?.id === product.id);

    if (matchedProduct == null) {
      return;
    }

    matchedProduct.thumbnails = matchedProduct.thumbnails.filter(
      (thumbnail) => thumbnail.id !== assetId,
    );

    if (matchedProduct.thumbnails.length !== 0) {
      return;
    }

    const fromMatchedProducts =
      this.matchedAssets.find((matchedProduct) => matchedProduct.product?.id === product.id) !=
      null;

    if (fromMatchedProducts) {
      this.matchedAssets = this.matchedAssets.filter(
        (matchedProduct) => matchedProduct.product?.id !== product.id,
      );
      return;
    }

    this.unmatchedAssets = this.unmatchedAssets.filter(
      (matchedProduct) => matchedProduct.product?.id !== product.id,
    );
  }
}
