<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import useFormValidation from 'composables/form-validation';
import { Attribute } from 'platform-unit2-api/attributes';
import { DatamodelAttributeConfig } from 'platform-unit2-api/datamodels';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { inject } from 'vue';
import { overrideServiceKey } from '../ts/keys/override-service.key';
import AttributeDisplay from './attribute-display.vue';
import ConditionalChoiceField from './conditional-renders/conditional-choice-field.vue';
import ConditionalFinancialField from './conditional-renders/conditional-financial-field.vue';
import ConditionalInputField from './conditional-renders/conditional-input-field.vue';
import ConditionalKeyValueField from './conditional-renders/conditional-key-value-field.vue';

/** Services */
const ts = new TranslationService('supplier', 'datamodels');
const tsDatamodelConfiguration = new TranslationService('supplier', 'datamodelsConfig');

/** Constants */
const { fieldErrorMessage, hasError } = useFormValidation();

/** Props */
defineProps<{
  attribute: Attribute;
}>();

/** Emits */
defineEmits<{
  (e: 'created', override: DatamodelAttributeConfig): void;
  (e: 'deleted'): void;
  (e: 'close'): void;
}>();

const service = inject(overrideServiceKey)!;
</script>

<template>
  <p class="my-5">
    {{ tsDatamodelConfiguration.tModule('configuration.override.informationText') }}
  </p>
  <div class="flex text-gray-800">
    <AttributeDisplay :attribute="attribute" />

    <!-- Divider -->
    <div :style="{ width: '6%' }">
      <Divider layout="vertical">
        <i class="mdi mdi-progress-pencil rotate-90 text-3xl text-gray-600"></i
      ></Divider>
    </div>

    <!-- override form -->
    <div
      v-if="service.loading"
      class="align-items-center flex justify-content-center"
      :style="{ width: '47%' }"
    >
      <LoadingIndicator />
    </div>
    <div v-else :style="{ width: '47%' }">
      <span class="font-bold text-lg">{{ ts.tModule('datamodels_overwrite.form.overwrite') }}</span>
      <div class="my-2 p-3 pb-0">
        <div class="grid justify-content-center place-items-center text-center">
          <div class="flex flex-column text-left w-full">
            <!-- Name -->
            <div class="field mb-3">
              <label for="name" class="font-bold text-sm">{{
                ts.tModule('datamodels_overwrite.body.name')
              }}</label>
              <InputText
                id="name"
                v-model="service.overrideName"
                class="w-full"
                :class="{ 'p-invalid': hasError('key') }"
                type="text"
              />
              <small
                v-if="hasError('key')"
                :class="{ 'p-error block': hasError('key') }"
                class="hidden"
              >
                {{ fieldErrorMessage('key').toString() }}
              </small>
            </div>

            <!-- required -->
            <div class="align-items-center field flex mb-3">
              <label class="font-bold mr-3 text-sm"
                >{{ ts.tModule('datamodels_overwrite.body.required') }} ?</label
              >
              <div>
                <ToggleSwitch v-model="service.options.required" />
              </div>
            </div>

            <!-- Help text -->
            <div class="field mb-3">
              <label class="font-bold text-sm">{{
                ts.tModule('datamodels_overwrite.body.help_text')
              }}</label>
              <div>
                <Textarea
                  v-model="service.options.helpText"
                  rows="2"
                  class="w-full"
                  :auto-resize="true"
                />
              </div>
            </div>

            <!-- Max length -->
            <div
              v-if="
                service.conditionallyRender('DEFAULT') ||
                service.conditionallyRender('GTIN_FIELD') ||
                service.conditionallyRender('RICH_TEXT_FIELD') ||
                service.conditionallyRender('TEXT_AREA_FIELD')
              "
              class="field mb-3"
            >
              <label class="font-bold text-sm">{{
                ts.tModule('datamodels_overwrite.body.max_length')
              }}</label>
              <div>
                <InputText
                  v-model="service.options.maxLength"
                  rows="2"
                  class="w-full"
                  :auto-resize="true"
                />
              </div>
            </div>

            <ConditionalChoiceField
              v-if="
                service.conditionallyRender('MULTIPLE_CHOICE_FIELD') ||
                service.conditionallyRender('CHOICE_FIELD')
              "
              v-model:choices="service.options.choices"
            />

            <!-- Input-select values -->
            <ConditionalInputField
              v-if="service.conditionallyRender('INPUT_SELECT_FIELD')"
              v-model:default-unit="service.options.default_unit"
              v-model:values="service.options.selectValues"
            />

            <ConditionalFinancialField
              v-if="service.conditionallyRender('FINANCIAL_FIELD')"
              v-model:default-unit="service.options.default_unit"
              v-model:currencies="service.options.currencies"
            />

            <ConditionalKeyValueField
              v-if="service.conditionallyRender('KEY_VALUE_FIELD')"
              v-model:multi-select="service.options.multiSelect"
              v-model:choices="service.options.choices"
            />
          </div>
        </div>
      </div>
    </div>
    <ConfirmPopup group="resetOverrides" />
  </div>
</template>
