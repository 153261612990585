<script setup lang="ts">
import { authorizationMethods } from '@/general/composables/authorization.plugin';
import { useService } from '@/general/composables/useService';
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { UploadedFile } from 'platform-unit2-api/core';
import { ProductStoriesRestService, ProductStory } from 'platform-unit2-api/product-stories';
import { FeaturePermission } from 'platform-unit2-api/roles';
import Tiptap from 'supplier/modules/editor/components/tiptap.vue';
import UploadBox from 'ui/components/upload-box.vue';
import { onMounted, ref, watch } from 'vue';
import { productDetailsServiceKey } from '../key/product-details-service.key';
import { ProductDetailsService } from '../services/product-details.service';

/**Props */
const props = defineProps<{
  story: ProductStory;
}>();

const { hasPermission } = authorizationMethods;

/** Services */
const productDetailsService = useService(productDetailsServiceKey, ProductDetailsService);

const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const confirmService = new ConfirmService();

/** Consts */
const editing = ref(false);
const storyValue = ref<string>('');
const showNewImageComponent = ref(false);
const imageUrl = ref<string>();
const productStoryApi = new ProductStoriesRestService();

const saveProductStory = async (): Promise<void> => {
  productDetailsService.loadingProductStories = true;

  try {
    await productStoryApi.update(props.story.id, {
      id: props.story.id,
      data: storyValue.value,
      product_id: productDetailsService.currentVariant!.id,
    });
  } catch (err) {
    toastService.displayErrorToast(ts.updateFailed());
  } finally {
    productDetailsService.loadingProductStories = false;
  }
};

const deleteStory = async (): Promise<void> => {
  productDetailsService.loadingProductStories = true;
  try {
    await productStoryApi.delete(props.story.id);
    productDetailsService.getProductStories();
  } catch (err) {
    toastService.displayErrorToast(ts.deleteFailed());
  } finally {
    productDetailsService.loadingProductStories = false;
  }
};

const clickDeleteStory = (event: MouseEvent): void => {
  if (storyValue.value !== '' && props.story.id > 0) {
    confirmService.confirmDelete({
      event: event,
      callback: () => deleteStory(),
      group: 'story',
      message: ts.deleteConfirm(),
    });
  } else {
    deleteStory();
  }
};

const cancelEditing = async (): Promise<void> => {
  if (storyValue.value === '' && props.story.data === '') {
    await deleteStory();
  } else {
    storyValue.value = props.story.data;
  }

  showNewImageComponent.value = false;
  editing.value = false;
};

const toggleEditing = async (): Promise<void> => {
  if (editing.value) {
    await saveProductStory();
    await productDetailsService.getProductStories();
  }

  editing.value = !editing.value;
};

const showImageComponent = (): void => {
  showNewImageComponent.value = true;
};

const hideNewImageComponent = (): void => {
  showNewImageComponent.value = false;
};

const insertImage = (file: UploadedFile): void => {
  if (file) {
    imageUrl.value = file.url;
    hideNewImageComponent();
  }
};

watch(
  () => props.story,
  () => {
    if (props.story.data !== '' && storyValue.value === '') {
      storyValue.value = props.story.data;
    }
  },
);

onMounted(() => {
  storyValue.value = props.story.data;
  if (!storyValue.value || storyValue.value === '') {
    editing.value = true;
  }
});
</script>
<template>
  <div class="border-1 border-200 border-round mb-5 p-4">
    <div class="flex justify-content-between mb-3">
      <div>
        <Button
          plain
          text
          :icon="
            !productDetailsService.loadingProductStories
              ? 'mdi mdi-delete'
              : 'mdi mdi-loading pi-spin'
          "
          @click="(event: MouseEvent) => clickDeleteStory(event)"
        />
      </div>
      <div>
        <Button v-if="editing" plain text class="mr-2" @click="cancelEditing">
          {{ ts.tGlobal('cancel') }}
        </Button>
        <Button
          :icon="editing ? 'mdi mdi-content-save-outline' : 'mdi mdi-pencil-outline'"
          @click="toggleEditing"
        />
      </div>
    </div>
    <div v-if="!editing && storyValue !== ''" class="content editor-content" v-html="storyValue" />
    <div
      v-if="editing && hasPermission(FeaturePermission.MANAGE_PRODUCTS)"
      class="border-1 border-200 editor-wrapper w-full"
      style="min-height: 300px"
    >
      <Tiptap
        :model-value="storyValue"
        :image-url="imageUrl"
        @update:model-value="(event) => (storyValue = event)"
        @add-image="showImageComponent"
      ></Tiptap>
    </div>
    <div v-if="showNewImageComponent" class="editor__uploadBox mt-3">
      <UploadBox @input="insertImage($event)" />
    </div>

    <ConfirmPopup group="story" />
  </div>
</template>
<style lang="scss">
/* Basic editor styles */
.ProseMirror,
.editor-content {
  padding: 1rem;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem !important;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  ul {
    list-style-type: disc !important;
  }
  ol {
    list-style-type: decimal !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 80%;
    height: auto;
    margin: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }

  iframe,
  video {
    width: 100%;
    aspect-ratio: 16/9;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  &.ProseMirror-focused {
    outline: none;
  }
}
</style>
