<script setup lang="ts" generic="T">
import { useDataModelOrderable } from '@/general/composables/useDataModelOrderable';
import { OrderableItem } from '@/general/composables/useDataModelOrderable/types';
import Sortable, { SortableEvent } from 'sortablejs';
import { Ref, onMounted, ref, watch } from 'vue';
// Props
interface DragAndDropProps {
  listItems: T[];
  sortableOptions?: Sortable.Options;
  listItemClasses?: string;
  itemClasses?: string;
  handleClass?: string;
  removeHandle?: boolean;
  renderKey?: string;
  horizontal?: boolean;
}

// Variables
const props = defineProps<DragAndDropProps>();
defineSlots<{
  default(props: { item: T; itemIndex: number; itemUuid?: string; order?: number }): any;
  handle(props: { item: T; itemIndex: number; itemUuid?: string; order?: number }): any;
  additionalTabs(): any;
}>();
const listRef = ref<HTMLElement | undefined>();
const dragAndDropList = ref<T[]>(props.listItems);

const { reorder, orderBy, orderedList } = useDataModelOrderable(dragAndDropList as Ref<T[]>);

// Helper functions
function onEnd(sortableEvent: SortableEvent) {
  reorder(1, sortableEvent.oldIndex ?? 0, sortableEvent.newIndex ?? 0);
}

function createSortable() {
  const elementToAssignTo = listRef.value;
  if (elementToAssignTo != null) {
    Sortable.get(elementToAssignTo)?.destroy();
    // everything is overridable with props
    Sortable.create(elementToAssignTo, {
      handle: '.handle',
      onEnd: onEnd,
      animation: 400,
      direction: props.horizontal ? 'horizontal' : 'vertical',
      draggable: '.draggableItem',
      ...props.sortableOptions,
    });
  }
}

// Lyfecycle hooks
function handleComponentDidMount() {
  orderBy();
  createSortable();
}

function getKey<T>(item: OrderableItem<T>, index: number) {
  if (props.renderKey != null) {
    return props.renderKey + item.itemUUID;
  }

  return (item.value as any).id && item.itemUUID ? (item.value as any).id + item.itemUUID : index; // ensures that if the list is consisted of objects with ids only one object will update instead of the whole array
}

watch(() => props.sortableOptions, createSortable, { deep: true });

watch(
  () => props.listItems,
  () => ((dragAndDropList.value as T[]) = props.listItems),
);

onMounted(handleComponentDidMount);
</script>
<template>
  <ul ref="listRef" :class="`${listItemClasses != null ? listItemClasses : ''}`">
    <li
      v-for="(item, index) in orderedList"
      :key="getKey(item, index)"
      :class="`draggableItem ${itemClasses != null ? itemClasses : ''}`"
    >
      <span v-if="!removeHandle" class="handle">
        <slot
          name="handle"
          :item="(item.value as T)"
          :item-index="index"
          :item-uuid="item.itemUUID"
          :order="item.order"
        >
          <span
            style="cursor: grab"
            :class="`mdi mdi-drag-vertical text-400 text-xl ${
              sortableOptions?.disabled ? 'disabledHandle' : ''
            } ${handleClass}`"
          />
        </slot>
      </span>
      <slot
        :item="(item.value as T)"
        :item-index="index"
        :item-uuid="item.itemUUID"
        :order="item.order"
      ></slot>
    </li>
  </ul>
</template>
<style>
.handle {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.handle:active {
  cursor: move; /* fallback: no `url()` support or images disabled */
  cursor: -webkit-grabbing; /* Chrome 1-21, Safari 4+ */
  cursor: -moz-grabbing; /* Firefox 1.5-26 */
  cursor: grabbing; /* W3C standards syntax, should come least */
}
.handle .disabledHandle {
  cursor: not-allowed !important;
}
</style>
