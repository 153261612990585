import { Platform } from '@/core/router/route.factory';
import store from '@/core/store';
import { BaseViewService } from '@/general/services/overview-service/view/base-view.service';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import {
  CreateDatamodelRequest,
  Datamodel,
  DatamodelsRestService,
  UpdateDatamodelRequest,
} from 'platform-unit2-api/datamodels';
import { User } from 'platform-unit2-api/users';
import { TranslationService } from '../../translations/translation.service';

export class DatamodelViewService extends BaseViewService<
  DatamodelsRestService,
  Datamodel,
  CreateDatamodelRequest,
  UpdateDatamodelRequest
> {
  /**
   * @inheritdoc
   * Check if the current datamodel is valid. Name is required.
   */
  public get validated(): boolean {
    if (!this.current?.name) {
      return false;
    }

    if (this.current.name.length < 1) {
      return false;
    }

    return true;
  }

  /**
   * Get datamodels.
   */
  public get datamodels(): Datamodel[] {
    return this._data;
  }

  /**
   * @inheritdoc
      title: 'Productsup Platform - Data models',
   */
  constructor(ts?: TranslationService) {
    const currentUser: User | undefined = store.getters['users/currentUser'];
    let currentSpace: ClientTypeEnum =
      currentUser?.workspace?.workspace_type?.type ?? ClientTypeEnum.SUPPLIER;

    if (currentSpace.toString().toLowerCase() === 'dms') {
      currentSpace = ClientTypeEnum.SUPPLIER;
    }

    super({
      Api: DatamodelsRestService,
      fetchAllFunction: 'getAll',
      ts: ts ?? new TranslationService(currentSpace.toLowerCase() as Platform, 'datamodels'),
      overviewRouteName: 'datamodels',
      createRouteName: 'new-datamodel',
      updateRouteName: 'edit-datamodel',
      confirmPopUpGroup: 'datamodels',
    });
  }

  public refetch(): void {
    super.refetch();
  }

  public duplicationModalVisible = false;

  public downloadDatamodelExcel(datamodel: Datamodel): void {
    this._restService.downloadAttributes(datamodel.id).then((res) => {
      const link = document.createElement('a');

      link.href = URL.createObjectURL(res);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', datamodel?.name ?? '');
      link.click();
    });
  }

  /**
   * @inheritdoc
   * Create datamodel from current brand.
   */
  public createBody(): CreateDatamodelRequest | undefined {
    if (this.current == null || !this.validated) {
      return;
    }

    const body: CreateDatamodelRequest = {
      name: this.current.name!,
    };

    return body;
  }

  /**
   * @inheritdoc
   * Create UpdateBrandRequest from current brand.
   */
  public updateBody(): UpdateDatamodelRequest | undefined {
    if (this.current == null || !this.validated || this.current?.id == null) {
      return;
    }

    const body: UpdateDatamodelRequest = {
      id: this.current.id!,
      name: this.current.name!,
    };

    return body;
  }

  public confirmTableDelete(datamodel: Datamodel): void {
    this._confirmService.confirmDelete({
      callback: () => this.deleteDatamodel(datamodel.id),
      group: 'datamodels',
      header: this._ts.tModule('datamodel_delete_confirmation.title'),
      message: this._ts.tModule('datamodel_delete_confirmation.subtitle', {
        params: { name: datamodel.name },
      }),
    });
  }

  private deleteDatamodel(id: number): void {
    this._restService
      .delete(id)
      .then(() => {
        this._toastService.displaySuccessToast(this._ts.deleteSuccess());
        this.refetch();
      })
      .catch((err) => {
        this._toastService.displayErrorToast(err?.response?.data?.message);
      });
  }
}
