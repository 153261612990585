<script setup lang="ts">
import BetterTranslationService from '@/general/services/translations/better-translation.service';
import { Activity } from 'platform-unit2-api';

const props = defineProps<{ activity: Activity }>();
const ts = new BetterTranslationService().platform('supplier').module('activities');

const getIcon = () => {
  if (props.activity.modified?.completeness.new > props.activity.modified?.completeness.old) {
    return 'mdi mdi-trending-up text-green-500 pup-pr-3';
  } else {
    return 'mdi mdi-trending-down text-red-500 pup-pr-3';
  }
};
</script>
<template>
  <div>
    <div>
      <i :class="getIcon()" />
      <span class="font-semibold">
        {{ ts.translate('completenessScoreChange') }}
      </span>
    </div>
    <div class="align-items-center flex pup-mt-2">
      <ProgressBar :value="activity.modified?.completeness.new" class="pup-mr-2 w-full">
        {{}}
      </ProgressBar>
      <div>{{ activity.modified?.completeness.new }}%</div>
    </div>
  </div>
</template>
