<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { TranslationService } from '@/general/services/translations/translation.service';
import { isNegative } from '@/general/utils/isNegative';
import ProductFilter from 'supplier/modules/products/components/product-filter.vue';
import ProductVariants from 'supplier/modules/products/components/product-variants.vue';
import { inject, ref, watch } from 'vue';
import { productDetailsServiceKey } from '../key/product-details-service.key';
import { ProductDetailsService } from '../services/product-details.service';

/** Services */
const ts = new TranslationService('supplier', 'products');

/** Consts */
const filterActive = ref<boolean>(false);

const productDetailsService = useService(productDetailsServiceKey, ProductDetailsService);
const productAttributeFieldService = inject(productAttributeFieldsServiceKey)!;

const filterVisible = (value: boolean): void => {
  filterActive.value = value;
};

const calculatedFilledInFields = ref<number>(0);

watch(
  () =>
    productAttributeFieldService.value.isSaving ||
    productAttributeFieldService.value.hasLocalesChanged ||
    productDetailsService.selectedDatamodel?.name,
  () => {
    const dmAttributes = productDetailsService.selectedDatamodel?.attributes.map(
      (attribute) => attribute.id,
    );

    calculatedFilledInFields.value =
      productAttributeFieldService.value.getFilledInAttributesForDatamodel(dmAttributes ?? []) ??
      calculatedFilledInFields.value;
  },
  {
    deep: true,
  },
);
</script>
<template>
  <section>
    <ProductFilter v-if="filterActive" :is-active="filterActive" @hide="filterVisible(false)" />
    <div class="bg-white border-200 border-bottom-1 sticky top-0 z-2">
      <div class="align-items-center flex justify-content-between mb-3">
        <h3 v-if="productDetailsService.selectedDatamodel && $route.name === 'product-attributes'">
          {{ productDetailsService.selectedDatamodel?.name }}
          <h5 class="inline-block m-0 text-gray-400">
            ({{ isNegative(calculatedFilledInFields) ? 0 : calculatedFilledInFields }}/{{
              productDetailsService.selectedDatamodel?.attributes_count
            }})
          </h5>
        </h3>
        <h3 v-else-if="$route.name === 'product-assets' || $route.name === 'product-story'">
          {{ $route.name === 'product-assets' ? ts.tGlobal('assets') : ts.tModule('story') }}
        </h3>
        <div class="flex">
          <Button
            outlined
            plain
            icon="mdi mdi-filter-variant"
            :label="ts.tModule('filters')"
            :disabled="$route.name === 'product-assets' || $route.name === 'product-story'"
            @click="filterVisible(true)"
          />
        </div>
      </div>
      <ProductVariants />
    </div>
  </section>
</template>
