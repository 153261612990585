<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { ProductUpload } from 'platform-unit2-api/products';
import { UploadsRestService } from 'platform-unit2-api/uploads';
import { onMounted, ref } from 'vue';

/** Props */
interface Props {
  isActive: boolean;
  selectedAsset: ProductUpload;
}
const props = withDefaults(defineProps<Props>(), {
  isActive: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'refresh'): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');
const toastService = ToastService.getInstance();
const uploadApi = new UploadsRestService();

/** Consts */
const newFilename = ref<string>('');

const updateFileName = async (): Promise<void> => {
  const fileType = props.selectedAsset.filename.includes('.')
    ? props.selectedAsset.filename.substring(props.selectedAsset.filename.lastIndexOf('.'))
    : '';

  try {
    await uploadApi.update(props.selectedAsset.id, {
      filename: newFilename.value.concat(fileType),
    });
    toastService.displaySuccessToast(ts.updateSuccess(newFilename.value));
    emit('refresh');
    emit('hide');
  } catch (error) {
    toastService.displayErrorToast(ts.updateFailed(ts.tModule('product_assets.title')));
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const saveButton = new CreateButton({
  label: ts.tGlobal('save'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => newFilename.value.length > 0,
  onClick: () => updateFileName(),
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, saveButton],
});

onMounted(() => {
  newFilename.value =
    props.selectedAsset.filename.substring(0, props.selectedAsset.filename.lastIndexOf('.')) ||
    props.selectedAsset.filename;
});
</script>
<template>
  <BaseDialog
    :visible="isActive"
    :title="ts.tModule('product_assets_old.edit_filename')"
    size="small"
    :buttons-options="buttonsOptions"
    @update:visible="emit('hide')"
  >
    <InputText v-model="newFilename" class="w-full" />
  </BaseDialog>
</template>
