import { createStore as _createStore } from 'vuex';

//TODO add tech debt for stores
//The following stores still use store logic, this could be moved to pinia if we moved to it in a future sprint
import { productsStore } from 'retailer/modules/products/store/products.state';
import { dashboardStore } from 'supplier/modules/dashboard/store/dashboard.state';
import { uploadStore } from 'supplier/modules/uploads/store/uploads.state';
import { thumbnailsStore } from 'supplier/modules/thumbnails/store/thumbnails.state';
import { userStore } from 'supplier/modules/users/store/user.state';
import { localesStore } from 'supplier/modules/locales/store/locales.state';
import { storyManagerStore } from '@/platforms/supplier/modules/story-manager/store/story-manager.state';

export default _createStore({
  modules: {
    dashboard: dashboardStore,
    users: userStore,
    products: productsStore,
    uploads: uploadStore,
    thumbnails: thumbnailsStore,
    locales: localesStore,
    storyManager: storyManagerStore,
  },
});
