import { Activity, ActivityEventTypeEnum } from 'platform-unit2-api/activities';
import Created from './components/created-box.vue';
import Updated from './components/updated-box.vue';
import Deleted from './components/deleted-box.vue';
import Attached from './components/attached-box.vue';
import Restore from './components/restored-box.vue';
import BetterTranslationService from '@/general/services/translations/better-translation.service';

export default class ActivityService {
  blackList = [
    'attribute_id',
    'id',
    'locale_id',
    'locked',
    'product_id',
    'type',
    'product_status_id',
    'completeness',
    'category_id',
  ];

  generateSingleActivityBox = (activity: Activity) => {
    switch (activity?.event) {
      case ActivityEventTypeEnum.CREATE:
        return Created;
      case ActivityEventTypeEnum.UPDATE:
        return Updated;
      case ActivityEventTypeEnum.DELETE:
        return Deleted;
      case ActivityEventTypeEnum.ATTACH:
        return Attached;
      case ActivityEventTypeEnum.DETACH:
        return Attached;
      case ActivityEventTypeEnum.RESTORE:
        return Restore;
      default:
        return Updated;
    }
  };

  isMedia(activity: Activity) {
    return (
      activity?.modified?.thumbnail_filename != null || 'filename' in (activity?.resource ?? {})
    );
  }

  isStatusChange(activity: Activity) {
    return activity?.modified?.product_status_id != null;
  }

  isCompletenessScoreChange(activity: Activity) {
    return activity?.modified?.completeness != null;
  }

  isStoryChange(activity: Activity) {
    return activity?.modified?.data != null;
  }

  getAttributeName = (activity: Activity) => {
    const attr =
      activity?.modified &&
      Object.keys(activity?.modified).find((key) => !this.blackList.includes(key));
    return attr;
  };

  getAccordionIcon = (activity: Activity[]) => {
    switch (activity[0]?.event) {
      case ActivityEventTypeEnum.CREATE:
        return 'mdi-plus';
      case ActivityEventTypeEnum.UPDATE:
        return 'mdi-pencil';
      case ActivityEventTypeEnum.DELETE:
        return 'mdi-delete';
      case ActivityEventTypeEnum.ATTACH:
        return 'mdi-image-multiple-outline';
      case ActivityEventTypeEnum.DETACH:
        return 'mdi-image-multiple-outline';
      case ActivityEventTypeEnum.RESTORE:
        return 'mdi-restore';
      default:
        return 'mdi-pencil';
    }
  };

  getAccordionHeader = (activity: Activity[]) => {
    const ts = new BetterTranslationService().platform('supplier').module('activities');

    switch (activity[0]?.event) {
      case ActivityEventTypeEnum.CREATE:
        return 'Created';
      case ActivityEventTypeEnum.UPDATE: {
        const isMedia = this.isMedia(activity[0]);
        const isStatusChange = this.isStatusChange(activity[0]);
        const isCompletenessScoreChange = this.isCompletenessScoreChange(activity[0]);
        const isStoryChange = this.isStoryChange(activity[0]);

        if (!isMedia && !isStatusChange && !isCompletenessScoreChange && !isStoryChange) {
          const attr = this.getAttributeName(activity[0]);
          return ts.translate('updatedAttributes', {
            count: activity.length,
            attribute: attr,
          });
        }

        return ts.translate('updatedAttributes');
      }

      case ActivityEventTypeEnum.DELETE:
        return ts.translate('productDeleted', { count: activity.length });
      case ActivityEventTypeEnum.ATTACH:
        return ts.translate('assetAttached', { count: activity.length });
      case ActivityEventTypeEnum.DETACH:
        return (
          ts.translate('assetAttached', {
            count: activity.length,
          }) + ' (via Auto-match)'
        );
      case ActivityEventTypeEnum.RESTORE:
        return ts.translate('productRestored', { count: activity.length });
      default:
        return ts.translate('updatedAttributes');
    }
  };
}
