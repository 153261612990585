<script setup lang="ts">
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import AddProductVariantModal from 'supplier/modules/products/components/add-product-variant-modal.vue';
import ProductSingleVariant from 'supplier/modules/products/components/product-single-variant.vue';

import { useStore } from 'vuex';
import { RouteLocationRaw, RouteParamsRaw, useRoute, useRouter } from 'vue-router';
import { inject, ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { useService } from '@/general/composables/useService';
import { productDetailsServiceKey } from '../key/product-details-service.key';
import { ProductDetailsService } from '../services/product-details.service';

/** Services */
const ts = new TranslationService('supplier', 'products');
const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;
const productDetailsService = useService(productDetailsServiceKey, ProductDetailsService);

/** Consts */
const store = useStore();
const router = useRouter();
const route = useRoute();
const currentUser = store.getters['users/currentUser'];
const showVariantModal = ref(false);

const openVariantModal = () => {
  showVariantModal.value = true;
};

const closeVariantModal = () => {
  showVariantModal.value = false;
};

const navigateCallback = async (productId: number) => {
  productAttributeFieldsService.value.clearErrors();

  router.push({
    name: route.name,
    params: {
      id: productId.toString(),
    } as RouteParamsRaw,
  } as RouteLocationRaw);
  await productDetailsService.getProduct(productId);
};

const navigateToVariant = async (productId: number) => {
  if (route.params.id.toString() === productId.toString()) {
    return;
  }

  if (productAttributeFieldsService.value.attrFieldLinkedList.isTouched()) {
    productAttributeFieldsService.value.showDialog(async () => {});
    navigateCallback(productId);
    return;
  }

  navigateCallback(productId);
};

const variantCreated = async (productId: number) => {
  showVariantModal.value = false;
  navigateToVariant(productId);
};
</script>

<template>
  <div>
    <AddProductVariantModal
      v-if="showVariantModal"
      :is-active="showVariantModal"
      @hide="closeVariantModal"
      @saved="variantCreated"
    />
    <div class="align-items-center flex flex-row">
      <OverlayBadge :value="productDetailsService.productVariants.length">
        <p class="font-bold py-2 text-lg w-max">{{ ts.tGlobal('variants', { choice: 2 }) }}:</p>
      </OverlayBadge>
      <div class="flex mr-3 overflow-x-auto px-4 py-2 w-full white-space-nowrap">
        <div v-if="productDetailsService.loadingVariants" class="flex flex-row">
          <Skeleton
            v-for="i in 5"
            :key="i"
            width="10rem"
            height="3rem"
            border-radius="1rem"
            class="flex-shrink-0 mr-3"
          />
        </div>

        <ProductSingleVariant
          v-for="variant in productDetailsService.productVariants"
          v-else
          :key="variant.id.toString() + route.params?.id"
          :variant="variant"
          :is-selected="variant.id.toString() === route.params.id.toString()"
          @click="navigateToVariant(variant.id)"
        />
      </div>

      <Button
        v-if="currentUser.workspace.workspace_type?.type !== ClientTypeEnum.RETAILER"
        text
        plain
        class="flex-shrink-0 ml-auto"
        icon="mdi mdi-plus"
        :label="ts.tModule('add_variant')"
        @click="openVariantModal"
      />
    </div>
  </div>
</template>
