import { DebugLogger } from '@/core/main/debug.logger';
import { CacheServiceKeys } from '@/general/services/cache-service-keys.enum';
import cacheService from '@/general/services/cache.service';
import useStorage from 'utils/storage';
import {
  CategoryTreeItem,
  CreateUploadRequest,
  Datamodel,
  ModuleDetail,
  Product,
  ProductCategory,
  ProductCompact,
  ProductStatus,
  ProductStory,
  ProductUpload,
  UpdateProductRequest,
  UpdateProductStory,
  UpdateProductUpload,
  Upload,
} from 'platform-unit2-api';
import { AwsFileService } from '@/general/services/file-service/aws-file.service';
import BetterTranslationService from '@/general/services/translations/better-translation.service';
import { ProductDetailsRestHelper } from './product-details/product-details-rest-helper';

export class ProductDetailsService extends ProductDetailsRestHelper {
  protected storage;

  ts = new BetterTranslationService().platform('supplier').module('products');

  loading = false;
  // Meaning the current visible variant on the screen (might also be Master Data)
  currentVariant?: Product;
  previousProduct?: Product;
  nextProduct?: Product;

  productDatamodels: Datamodel[] = [];
  productStatuses: ProductStatus[] = [];
  productAssets: ProductUpload[] = [];
  productCategoryTree: CategoryTreeItem[] = [];
  productAttachedCategories: ProductCategory[] = [];
  productStories: ProductStory[] = [];
  mediaInstructions = '';

  //Variant related props
  selectedModule?: ModuleDetail;
  selectedDatamodel?: Datamodel;
  productVariants: ProductCompact[] = [];

  constructor() {
    super();
    this.storage = useStorage();
  }

  get currentMasterDataProduct(): Product | ProductCompact | undefined {
    return this.currentVariant?.variant_name == null
      ? this.currentVariant
      : this.productVariants.find((p) => p.variant_name == null);
  }

  private resetProduct() {
    this.currentVariant = undefined;
    this.productDatamodels = [];
    this.productVariants = [];
    this.previousProduct = undefined;
    this.nextProduct = undefined;
  }

  async getProduct(id: number): Promise<Product | undefined> {
    this.loadingProduct = true;
    this.resetProduct();
    try {
      this.currentVariant = await this._productRest.get(id);

      await this.executeAsyncFunctionsAtTheSameTime([
        {
          loading: 'loadingDatamodels',
          callback: () => this.getProductDatamodels(),
          errorMessage: 'Failed to load datamodels',
        },
        {
          loading: 'loadingVariants',
          callback: () => this.getVariantData(),
          errorMessage: 'Failed to load variants',
        },
        {
          loading: 'loadingProductStatuses',
          callback: () => this.getProductStatuses(),
          errorMessage: 'Failed to load product statuses',
        },
        {
          loading: 'loadingAssets',
          callback: () => this.getProductAssets(),
          errorMessage: 'Failed to load assets',
        },
        {
          loading: 'loadingCategories',
          callback: () => this.getProductCategory(),
          errorMessage: 'Failed to load categories',
        },
        {
          loading: 'loadingProductStories',
          callback: () => this.getProductStories(),
          errorMessage: 'Failed to load product stories',
        },
        {
          loading: 'loadingPrevAndNextProducts',
          callback: () => this.getPrevAndNextProducts(),
          errorMessage: 'Failed to load previous and next products',
        },
      ]);

      return this.currentVariant;
    } catch (e) {
      DebugLogger.error(e);
      return;
    } finally {
      this.loadingProduct = false;
    }
  }

  async getPrevAndNextProducts() {
    const products: Product[] | undefined = cacheService.hasItemsForRequest(
      CacheServiceKeys.PRODUCT_OVERVIEW,
    )
      ? (cacheService.getItemsForRequest(CacheServiceKeys.PRODUCT_OVERVIEW).items as Product[])
      : this.storage.getStorageItem('products');

    const indexOfCurrentProduct =
      products?.findIndex?.((p: Product) => p.id === this.currentVariant?.id) ?? -1;

    if (indexOfCurrentProduct === -1) {
      return;
    }

    // Product is first on the page
    if (indexOfCurrentProduct === 0) {
      this.nextProduct = products?.[indexOfCurrentProduct + 1];
      await this.getProductsFromNextOrPrevPage('prev', products);
      return;
    }
    // Product is last on the page
    else if (indexOfCurrentProduct === (products?.length ?? 0) - 1) {
      this.previousProduct = products?.[indexOfCurrentProduct - 1];
      await this.getProductsFromNextOrPrevPage('next', products);
      return;
    }

    this.previousProduct = products?.[indexOfCurrentProduct - 1];
    this.nextProduct = products?.[indexOfCurrentProduct + 1];
  }

  async getProductsFromNextOrPrevPage(sequence?: 'prev' | 'next', currentProducts?: Product[]) {
    const currentPagination = this.storage.getStorageItem('overviewPagination');
    if (currentPagination.page === 1 && sequence === 'prev') {
      return;
    }

    if ((currentProducts?.length ?? 0) < currentPagination.limit && sequence === 'next') {
      return;
    }

    const newProducts = await this._productRest.getAll({
      ...currentPagination,
      page: sequence === 'prev' ? currentPagination.page - 1 : currentPagination.page + 1,
    });

    this.nextProduct = sequence === 'next' ? currentProducts?.[0] : newProducts.data[0];
    this.previousProduct =
      sequence === 'prev' ? newProducts.data[newProducts.data.length - 2] : currentProducts?.[0];

    this.storage.setStorageItem('products', newProducts.data);
    cacheService.cacheResponseWithEnumKey(
      newProducts.data,
      CacheServiceKeys.PRODUCT_OVERVIEW,
      newProducts.meta?.total ?? 0,
    );
  }

  async getProductDatamodels() {
    let datamodels: Datamodel[] = [];
    if (this.currentVariant?.id == null) {
      DebugLogger.error('No product id found');
      return;
    }

    datamodels = (await this._productRest.getProductDatamodels(this.currentVariant?.id)).data;

    // sort aphabetically
    datamodels = datamodels.sort((datamodelA, datamodelB) =>
      ('' + datamodelA.name).localeCompare(datamodelB.name),
    );

    // add all attributes datamodel to display in the beginning
    if (this.currentVariant?.datamodel.id != null) {
      const allDatamodelAttributes = (
        await this._datamodelRest.getDatamodelFieldDefinitions(
          this.currentVariant?.datamodel.id,
          true,
        )
      ).data;

      const allAttributesDatamodel: Datamodel = {
        id: null!,
        name: 'All Attributes',
        attributes: allDatamodelAttributes,
        attributes_count: allDatamodelAttributes.length,
      } as Datamodel;

      datamodels = [allAttributesDatamodel, ...datamodels];

      if (this.selectedDatamodel == null) {
        this.selectedDatamodel = allAttributesDatamodel;
      }

      this.productDatamodels = datamodels;
    }
  }

  async getVariantData() {
    if (!this.currentMasterDataProduct?.variant_uuid && !this.currentVariant?.variant_uuid) {
      DebugLogger.error('No variant uuid found');
      return;
    }

    this.loading = true;
    this.productVariants = await this._variantRest.getProductVariants(
      this.currentMasterDataProduct?.variant_uuid ?? this.currentVariant!.variant_uuid,
    );

    // Sort master data to be the first element and then alphabetically
    const masterData = this.productVariants.find((v) => v.variant_name == null);
    this.productVariants = [
      masterData!,
      ...this.productVariants
        .filter((v) => v.variant_name != null)
        .sort((a, b) => (a.variant_name! < b.variant_name! ? -1 : 1)),
    ];
  }

  async getProductAssets() {
    const moduleId = this.currentVariant?.module_id;

    if (moduleId != null) {
      this.mediaInstructions = (await this._moduleRest.get(moduleId)).media_instructions ?? '';
    } else {
      this.mediaInstructions = '';
    }

    this.productAssets = await this._productRest.getProductAssets(this.currentVariant!.id);
    this.productAssets = this.sortAssetsByOrder(this.productAssets);
  }

  async getProductCategory() {
    if (this.currentVariant?.module_id != null) {
      this.productCategoryTree = (
        await this._categoryRest.getChannelCategoryTree(this.currentVariant!.module_id)
      ).data;
    }

    this.productAttachedCategories = await this._productRest.getAttachedCategories(
      this.currentVariant!.id,
    );
  }

  async getProductStories() {
    this.productStories = (await this._productRest.getProductStories(this.currentVariant!.id)).data;
  }

  sortAssetsByOrder(assets: ProductUpload[]): ProductUpload[] {
    return assets.sort((a: ProductUpload, b: ProductUpload) => {
      if (a.order < b.order) {
        return -1;
      } else if (a.order > b.order) {
        return 1;
      }

      return 0;
    });
  }

  async createVariant(
    variantGtin: string,
    variantName: string,
    copyDataFrom?: Product,
    copyAssetsFrom?: Product,
  ): Promise<Product | undefined> {
    if (this.currentVariant?.id == null) {
      DebugLogger.error('Product id is not set');
      return;
    }

    this.loading = true;
    const newVariant = await this._variantRest.createProductVariant(this.currentVariant.id, {
      variant_name: variantName,
      gtin: variantGtin,
      module_id: this.selectedModule?.id,
      replicate_data_id: copyDataFrom?.id,
      replicate_assets_id: copyAssetsFrom?.id,
      datamodel_id: this.selectedDatamodel?.id,
    });

    this.loading = false;
    return newVariant;
  }

  async deleteVariant(productId: number) {
    this.loading = true;
    await this._variantRest.deleteVariant(productId);
    this.loading = false;
  }

  async getProductStatuses() {
    this.loading = true;
    try {
      this.productStatuses = (await this._productStatusApi.getAll()).data;
    } catch (e) {
      DebugLogger.error(e);
    } finally {
      this.loading = false;
    }
  }

  async deleteProduct(productId: number) {
    this.loading = true;
    try {
      await this._productRest.delete(productId);
    } catch (e) {
      DebugLogger.error(e);
    } finally {
      this.loading = false;
    }
  }

  async attachUploadData(uploadIds: number[]) {
    if (this.currentVariant?.id == null) {
      DebugLogger.error('Product id is not set');
      return;
    }

    await this.tryCatchLoadingWrapper({
      loading: 'loadingAssets',
      callback: async () => {
        await this._uploadRest.attachManyUploadsToProduct([this.currentVariant!.id], uploadIds);
        this.currentVariant = await this._productRest.get(this.currentVariant!.id);
        await this.getProductAssets();
      },
    });
  }

  async detachUploadData(uploadIds: number[]) {
    if (this.currentVariant?.id == null) {
      DebugLogger.error('Product id is not set');
      return;
    }

    await this.tryCatchLoadingWrapper({
      loading: 'loadingAssets',
      callback: async () => {
        await this._productRest.detachUploads(this.currentVariant!.id, uploadIds);
        await this.getProductAssets();
        this.currentVariant!.thumbnail = this.productAssets[0] as unknown as Upload;
      },
    });
  }

  async updateProduct(id: number, data: UpdateProductRequest) {
    this.currentVariant = (await this._productRest.update(id, data)) as Product;
  }

  async reorderProductAssets(uploadId: number, order: number) {
    await this.tryCatchLoadingWrapper({
      loading: 'loadingAssets',
      callback: async () => {
        await this._productRest.orderProductUpload(this.currentVariant!.id, uploadId, {
          order,
        });

        await this.getProductAssets();
      },
    });
  }

  async signAndUpload(file: File) {
    await this.tryCatchLoadingWrapper({
      loading: 'loadingAssets',
      callback: async () => {
        const sign = await new AwsFileService().signAndUpload(file, (progressEvent: any) => {
          Math.round((progressEvent.loaded * 100) / progressEvent.event.total);
        });

        const createUpload: CreateUploadRequest = {
          filename: file.name,
          contenttype: file.type,
          reference: sign!,
          resolution_in_pixels_height: 0,
          resolution_in_pixels_width: 0,
        };

        const upload = await this._uploadRest.post(createUpload);
        await this.attachUploadData([upload.id]);
        this.currentVariant = await this._productRest.get(this.currentVariant!.id);
        await this.getProductAssets();
      },
    });
  }

  async updateProductAsset(data: UpdateProductUpload) {
    this.tryCatchLoadingWrapper({
      loading: 'loadingAssets',
      callback: async () => {
        await this._productRest.updateProductAsset(this.currentVariant!.id, data);
        await this.getProductAssets();
      },
    });
  }

  async addCategoriesToProduct(categoryIds: number[]) {
    this.loading = true;
    try {
      await this._productRest.addCategoriesToProduct(this.currentVariant!.id, categoryIds);
    } catch (e) {
      DebugLogger.error(e);
    } finally {
      this.loading = false;
    }
  }

  async createProductStory(text: string) {
    this.loading = true;
    try {
      await this._productStoryRest.post({
        product_id: this.currentVariant!.id,
        data: text,
      } as UpdateProductStory);
      await this.getProductStories();
    } catch (e) {
      DebugLogger.error(e);
      this.toastService.displayErrorToast(this.ts.translate('product_story.create_failed'));
    } finally {
      this.loading = false;
    }
  }
}
