<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { TranslationService } from '@/general/services/translations/translation.service';
import StoryComponent from 'supplier/modules/products/components/product-story.vue';
import { productDetailsServiceKey } from '../key/product-details-service.key';
import { ProductDetailsService } from '../services/product-details.service';

/** Services */
const productDetailsService = useService(productDetailsServiceKey, ProductDetailsService);
const ts = new TranslationService('supplier', 'products');
</script>

<template>
  <div class="mb-5">
    <section v-if="!productDetailsService.productStories.length">
      <div class="align-items-center flex flex-column">
        <img src="@/assets/images/no_stories.svg" alt="Nothing here" />
        <p class="font-bold mb-5">{{ ts.tModule('product_story.empty') }}</p>
        <Button
          class="font-bold"
          :loading="productDetailsService.loadingProductStories"
          @click="productDetailsService.createProductStory('')"
        >
          {{ ts.tModule('product_story.start_creating') }}
        </Button>
      </div>
    </section>
    <div v-else>
      <StoryComponent
        v-for="(story, i) in productDetailsService.productStories"
        :key="`key_${story.id}_${i}`"
        :story="story"
      />
      <div class="align-items-center flex flex-column">
        <Button
          class="font-bold"
          :disabled="productDetailsService.loadingProductStories"
          @click="productDetailsService.createProductStory('')"
        >
          {{ ts.tModule('product_story.create_story') }}
        </Button>
      </div>
    </div>
  </div>
</template>
