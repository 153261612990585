<script
  setup
  lang="ts"
  generic="API extends BaseRest<T, C, U, F> | Api<T, C, U>, T extends { id: number }, C, U extends { id: number }, F"
>
import { ColumnProps } from 'primevue/column';
import {
  DataTableFilterEvent,
  DataTableFilterMeta,
  DataTableProps,
  DataTableSlots,
} from 'primevue/datatable';
import EmptyState from './empty-state.vue';
import LoadingIndicator from './skeletons/loading-indicator.vue';
import { BaseViewService } from '../../services/overview-service/view/base-view.service';
import { RefetchService } from '@/general/services/overview-service/refetch.service';
import { Api, BaseRest } from 'platform-unit2-api';

interface Props extends DataTableProps {
  columns: ColumnProps[];
  service: BaseViewService<API, T, C, U, F> & RefetchService;
  emptyStateButtonIconName: string;
  checkedRows?: T[];
  displayFilters?: DataTableFilterMeta;
}

type TableSlots = DataTableSlots & any;
const props = defineProps<Props>();
const slots = defineSlots<TableSlots>();

const dataTableSlotKeys: (keyof DataTableSlots)[] = Object.keys(
  slots,
) as unknown as (keyof DataTableSlots)[];
</script>
<template>
  <DataTable
    v-bind="{ ...props, ...$attrs }"
    lazy
    paginator
    scrollable
    auto-layout
    removable-sort
    data-key="id"
    scroll-height="flex"
    filter-display="menu"
    responsive-layout="scroll"
    :filters="displayFilters"
    :loading="service.isLoadingOverView"
    :selection="checkedRows"
    :total-records="service.total"
    :rows="service.limit"
    :first="service.first"
    :rows-per-page-options="[5, 15, 50, 100]"
    :show-headers="true"
    @page="service.onPageChange($event.page)"
    @sort="service.onSortChange($event)"
    @row-click="rowHover ?? service?.openCrudComponent($event.data, true)"
    @filter="(event: DataTableFilterEvent) => service.applyFilter?.(event)"
  >
    <!-- Define and expose all slots from DataTable -->
    <template
      v-for="(tableSlot, index) of dataTableSlotKeys"
      :key="index"
      #[tableSlot]="tableSlotProps"
    >
      <slot
        :name="(tableSlot as keyof DataTableSlots)"
        v-bind="{
          data: 'data' in tableSlotProps! ? tableSlotProps.data : null,
          index: 'index' in tableSlotProps! ? tableSlotProps.index : -1,
          expanded: 'expanded' in tableSlotProps! ? tableSlotProps.expanded : false,
          class: 'class' in tableSlotProps! ? tableSlotProps.class : '',
        }"
      />
    </template>

    <!-- Empty state slot -->
    <template #empty>
      <EmptyState
        :translation-service="service.ts"
        :button-visible="true"
        :button-icon="'mdi mdi-plus'"
        :icon-name="emptyStateButtonIconName"
        @clicked="service.openCrudComponent()"
      />
    </template>

    <!-- Loading slot -->
    <template #loading>
      <LoadingIndicator />
    </template>

    <!-- Dynamic columns -->
    <slot v-for="column in columns" :key="JSON.stringify(column)" :name="(column.field as string)">
      <Column v-bind="column" />
    </slot>
  </DataTable>
</template>
