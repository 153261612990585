import { isEqual, uniqWith } from 'lodash';
import { PaginationObject } from 'platform-unit2-api/core';
import { CacheServiceKeys } from './cache-service-keys.enum';

class CacheService<T> {
  private static _instance = new CacheService();
  cachedItems: { [key: string]: { items: T[]; total: number; pagination?: PaginationObject } } = {};

  public static get instance() {
    return CacheService._instance;
  }
  getKey(module: string, params?: PaginationObject) {
    return [module, params?.limit, params?.page, params?.query, params?.sortBy].join('_');
  }

  cacheResponse(
    responseItems: T[],
    responseTotal: number,
    module: string,
    params?: PaginationObject,
  ) {
    this.cachedItems[this.getKey(module, params)] = {
      items: responseItems,
      total: responseTotal,
      pagination: params,
    };
  }

  cacheResponseWithEnumKey(
    responseItems: T[],
    module: CacheServiceKeys,
    total: number,
    params?: PaginationObject,
  ) {
    this.cachedItems[this.getKey(module)] = {
      items: responseItems,
      total: total,
      pagination: params,
    };
  }

  hasItemsForRequest(module: string, params?: PaginationObject): boolean {
    return this.cachedItems[this.getKey(module, params)] != null;
  }

  getItemsForRequest(
    module: string,
    params?: PaginationObject,
  ): { items: T[]; total: number; pagination?: PaginationObject } {
    return this.cachedItems[this.getKey(module, params)];
  }

  getItemsForModule(module: string): { items: T[]; total: number } {
    const keys = Object.keys(this.cachedItems).filter((key) => !key.includes(module));
    const allItems = keys.map((key) => this.cachedItems[key].items).flat();
    return { items: uniqWith(allItems, isEqual), total: allItems.length };
  }

  public resetItemsForCacheModuleRecord(module: string) {
    Object.keys(this.cachedItems).forEach((key) => {
      if (key.includes(module)) {
        delete this.cachedItems[key];
      }
    });
  }

  public emptyCache() {
    Object.keys(this.cachedItems).forEach((key) => {
      delete this.cachedItems[key];
    });
  }
}

export default CacheService.instance;
