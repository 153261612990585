<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import EmptyState from '@/general/ui/components/empty-state.vue';
import { CategoryTreeItem } from 'platform-unit2-api/categories';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import { ProductCategory } from 'platform-unit2-api/products';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { TreeNode } from 'primevue/treenode';
import LoadingIndicator from 'ui/components/skeletons/loading-indicator.vue';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { productDetailsServiceKey } from '../key/product-details-service.key';
import { ProductDetailsService } from '../services/product-details.service';

/** Services */
const store = useStore();
const productDetailsService = useService(productDetailsServiceKey, ProductDetailsService);
const currentUser = computed(() => store.getters['users/currentUser']);

const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const loading = ref(false);

/* Mounted hook */
onMounted(async () => {
  nodes.value = mapTree(productDetailsService.productCategoryTree);
  loadAttachedCategories();
});

/* Fetching categories */
const nodes = ref([] as TreeNode[]);

const mapTree = (items: CategoryTreeItem[]): TreeNode[] => {
  return items.map((item: CategoryTreeItem) => {
    return {
      id: item.id,
      key: item.id.toString(),
      label: item.name,
      parent_id: item.parent_id,
      owner_id: item.owner_id,
      children: mapTree(item.children),
      selectable: item.children.length ? false : true,
    } as TreeNode;
  });
};

/* Add category to the product */
const selectedNodes = ref<Record<number, boolean>>({});
const selectedCategoryIds = computed<number[]>(() => {
  return Object.keys(selectedNodes.value).map((key) => parseInt(key));
});

const addCategories = async (): Promise<void> => {
  try {
    loading.value = true;
    await productDetailsService.addCategoriesToProduct(selectedCategoryIds.value);
    toastService.displaySuccessToast(
      ts.getSuccess(ts.tModule('product_catrgories.categoryAddedSuccessfully')),
    );
  } catch {
    toastService.displayErrorToast(ts.getFailed(ts.tGlobal('unknown')));
  } finally {
    loading.value = false;
    loadAttachedCategories();
  }
};

/* Show attached categories */
const attributeIds = ref<number[]>([]);
const loadAttachedCategories = () => {
  try {
    loading.value = true;

    productDetailsService.productAttachedCategories.forEach((cat: ProductCategory) => {
      selectedNodes.value[cat.id] = true;
    });
    //attached attribute ids to use when we want to find the saved values
    attributeIds.value = productDetailsService.productAttachedCategories.reduce(
      (ids: number[], current: ProductCategory) =>
        current.attributes && [...ids, ...current.attributes.map((attr) => attr.id)],
      [],
    );
  } catch {
    toastService.displayErrorToast(ts.getFailed(ts.tGlobal('unknown')));
  } finally {
    loading.value = false;
  }
};

/*  select attribute options  */
const selectedOptions = ref<Record<string, string[] | string | null>>({});

const updateFieldValue = async (attributeId: number, value: string) => {
  await store.dispatch('products/SET_EDITING_FIELD_DATA', {
    attribute_id: attributeId,
    value: value,
    locale_id: currentUser.value.locale.id,
    overwrite: false,
  });
};
</script>
<template>
  <div>
    <loading-indicator v-if="loading"></loading-indicator>
    <div v-else>
      <!-- Categories are displayed only for retailer specific products (not displayed for Master data variant) -->
      <div v-if="productDetailsService.currentVariant?.module_id">
        <div class="mb-4">
          <span class="block font-bold mb-3 text-xl w-full">{{
            ts.tModule('product_catrgories.add_category')
          }}</span>
          <Tree-select
            v-model="selectedNodes"
            class="w-20rem"
            :options="nodes"
            :placeholder="ts.tModule('product_catrgories.select_placeholder')"
          ></Tree-select>
          <Button
            class="ml-2 p-button-rounded"
            icon="mdi mdi-check"
            :label="ts.tModule('product_catrgories.add_btn')"
            :disabled="!selectedCategoryIds || !selectedCategoryIds.length"
            @click="addCategories"
          ></Button>
          <Divider></Divider>
        </div>
        <!-- Show the attributes -->
        <div class="border-1 border-200 border-round-xl p-4 surface-50">
          <div
            v-for="category in productDetailsService.productAttachedCategories"
            :key="category.id"
          >
            <h3 class="mb-4">{{ category.name }}</h3>
            <div class="flex grid">
              <div v-for="attribute in category.attributes" :key="attribute.id" class="col-3 w-4">
                <span class="font-bold">{{ attribute.key }}</span>
                <div class="mt-3">
                  <Select
                    v-model="selectedOptions[attribute.id]"
                    :options="attribute.options?.choices"
                    :filter="true"
                    :placeholder="
                      currentUser.workspace.workspace_type?.type !== ClientTypeEnum.RETAILER
                        ? ts.tModule('product_catrgories.select_placeholder')
                        : ts.tModule('product_catrgories.categories_not_filled')
                    "
                    :show-clear="true"
                    class="mb-3 w-full"
                    @change="(event: DropdownChangeEvent) => updateFieldValue(attribute.id, event.value)"
                  >
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <EmptyState
          :button-visible="false"
          :icon-name="'categories'"
          :empty-state-title="ts.tModule('categoriesEmptyStateTitle')"
          :empty-state-subtitle="ts.tModule('categoriesEmptyStateDescription')"
        />
      </div>
    </div>
  </div>
</template>
