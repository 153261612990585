<script setup lang="ts">
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { Defaults } from '@/general/utils/constants/defaults';
import useCheckable from 'composables/checkable';
import { AssetType, AssetTypeRestService } from 'platform-unit2-api/asset-types';
import { ModulesRestService } from 'platform-unit2-api/modules';
import { ProductsRestService, UpdateProductUpload } from 'platform-unit2-api/products';
import { Upload } from 'platform-unit2-api/uploads';
import { assetIds, colSize } from 'retailer/modules/media/media.keys';
import { ProductUpload } from 'retailer/modules/products/products.types';
import { onMounted, provide, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

provide(colSize, 2);

//** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('retailer', 'products');
const confirmService = new ConfirmService();
const api = new ProductsRestService();
const assetTypeApi = new AssetTypeRestService();
const moduleApi = new ModulesRestService();

//** Constants */
const store = useStore();
const route = useRoute();
const { checkedRows, checkedIds } = useCheckable();
const checkAllAssets = ref(false);
const loading = ref<boolean>(true);
const assets = ref<ProductUpload[]>([]);

provide(
  assetIds,
  assets.value.map((asset: Upload) => asset.id),
);

const assetTypes = ref<AssetType[]>([]);

const selectedAsset = ref<ProductUpload>();
const mediaInstructions = ref('');

const mediaPicker = ref(false);

const editDialogActive = ref<boolean>(false);

const menu = ref();
const bulkMenu = ref();

const bulkActions = ref<Array<Object>>([
  {
    label: ts.tModule('product_assets.include_in_export'),
    icon: 'mdi mdi-check',
    command: () => {
      updateIncludeInExport(checkedRows.value, true);
    },
  },
  {
    label: ts.tGlobal('download'),
    icon: 'mdi mdi-download',
    command: () => {
      bulkDownloadAssets();
    },
  },
  {
    separator: true,
  },
  {
    label: ts.tGlobal('remove'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      confirmBulkDelete();
    },
  },
]);

const menuItems = ref<Array<Object>>([
  {
    label: ts.tModule('product_assets.open_asset'),
    icon: 'mdi mdi-open-in-new',
    command: () => {
      window.open(selectedAsset.value!.public_url, '_blank');
    },
  },

  {
    label: ts.tModule('product_assets.download_asset'),
    icon: 'mdi mdi-tray-arrow-down',
    command: () => {
      downloadAsset(selectedAsset.value!);
    },
  },
  {
    label: ts.tModule('product_assets.rename_asset'),
    icon: 'mdi mdi-pencil',
    command: () => {
      editDialogVisible(true);
    },
  },
  {
    separator: true,
  },
  {
    label: ts.tModule('product_assets.remove_asset'),
    icon: 'mdi mdi-delete-outline',
    class: 'delete',
    command: () => {
      confirmAssetDelete(selectedAsset.value!.id);
    },
  },
]);

const showMediaPicker = (): void => {
  mediaPicker.value = true;
};

const hideMediaPicker = (): void => {
  mediaPicker.value = false;
};

const loadAsyncData = async (): Promise<void> => {
  loading.value = true;
  mediaInstructions.value = '';
  try {
    if (route.params.id == null) {
      return;
    }

    const response = await store.dispatch('products/GET_PRODUCT_ASSETS', route.params.id);
    const moduleId = await store.getters['products/currentProduct']?.module_id;

    if (moduleId) {
      try {
        mediaInstructions.value = (await moduleApi.get(moduleId)).media_instructions ?? '';
      } catch (err) {
        mediaInstructions.value = '';
      }
    }

    assets.value = response.sort((a: ProductUpload, b: ProductUpload) => {
      if (a.order < b.order) {
        return -1;
      } else if (a.order > b.order) {
        return 1;
      }

      return 0;
    });
  } catch (err: any) {
    toastService.displayErrorToast(ts.loadFailed('product_assets.title'));
  } finally {
    loading.value = false;
  }
};

const toggleActionMenu = (event: MouseEvent, asset: ProductUpload) => {
  selectedAsset.value = asset;
  menu.value.toggle(event);
};

const toggleBulkActionMenu = (event: MouseEvent) => {
  bulkMenu.value.toggle(event);
};

const toggleCheckAll = () => {
  const assetIds = assets.value.map((asset) => asset.id);
  if (checkAllAssets.value) {
    checkedRows.value = [
      ...checkedRows.value,
      ...assets.value.filter((asset) => !checkedIds.value.includes(asset.id)),
    ];
  } else {
    checkedRows.value = checkedRows.value.filter(
      (row: ProductUpload) => !assetIds.includes(row.id),
    );
  }
};

const formatBytes = (bytes?: number, decimals = 2) => {
  if (bytes == null) return '';

  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const formatResolution = (height?: number, width?: number): string => {
  if (height == null || width == null) return '';

  return `${height} x ${width}`;
};

const truncateFilename = (value: string) => {
  if (value.length > 22) {
    return value.substring(0, 11) + '...' + value.substring(value.length - 11);
  }

  return value;
};

const onRowReorder = async (event: any): Promise<void> => {
  loading.value = true;
  try {
    await store.dispatch('products/UPDATE_UPLOAD_ORDER', {
      productId: route.params.id,
      uploadId: event.value[event.dropIndex].id,
      data: { order: event.dropIndex + 1 },
    });
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('product_assets.warnings.reordering_failed'));
  } finally {
    await loadAsyncData();
  }
};

const attachUploadData = async (event: any): Promise<void> => {
  loading.value = true;
  try {
    await store.dispatch('products/ATTACH_MANY', {
      productIds: [route.params.id],
      uploadIds: event.map((file: Upload) => file.id),
    });
    toastService.displaySuccessToast(ts.uploadSuccess());
  } catch (err) {
    toastService.displayErrorToast(ts.uploadFailed());
  } finally {
    loading.value = false;
    await loadAsyncData();
  }
};

const detachUploadAsset = async (uploadIds: number[]): Promise<void> => {
  try {
    await store.dispatch('products/DETACH_UPLOAD', {
      product: store.getters['products/currentProduct'],
      uploadIds,
    });
    if (store.getters['products/currentProduct']) {
      store.getters['products/currentProduct'].published = false;
    }

    toastService.displaySuccessToast(ts.tModule('product_assets.success.removed_successfully'));
  } catch (ex) {
    toastService.displayErrorToast(ts.tModule('product_assets.warnings.removing_failed'));
  } finally {
    await loadAsyncData();
  }
};

const confirmAssetDelete = (id: number): void => {
  confirmService.confirmDelete({
    callback: () => detachUploadAsset([id]),
    group: 'assets',
    message: ts.deleteConfirm(selectedAsset.value!.filename),
  });
};

const confirmBulkDelete = (): void => {
  confirmService.confirmDelete({
    callback: () => {
      detachUploadAsset(checkedRows.value.map((asset: ProductUpload) => asset.id));
    },
    group: 'assets',
    header: ts.tModule('product_assets.remove_asset'),
    message: ts.tModule('product_assets.bulk_delete_assets'),
  });
};

const downloadAsset = (asset: ProductUpload): Promise<void> => {
  return fetch(asset.public_url)
    .then((resp) => resp.blob())
    .then((blobobject) => {
      const blob = window.URL.createObjectURL(blobobject);
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = blob;
      anchor.download = asset.filename;
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(blob);

      toastService.displaySuccessToast(
        ts.tModule('product_assets.success.downloaded_successfully'),
      );
    })
    .catch(() =>
      toastService.displayErrorToast(ts.tModule('product_assets.warnings.downloading_failed')),
    );
};

const bulkDownloadAssets = (): void => {
  checkedRows.value.forEach((asset: ProductUpload) => {
    downloadAsset(asset);
  });
};

const updateIncludeInExport = async (
  assetsForExport: ProductUpload[],
  value: boolean,
): Promise<void> => {
  const requestBody = assetsForExport.map((asset) => ({
    id: asset.id,
    include_in_export: value,
  }));
  try {
    await api.updateProductAsset(+route.params.id, { uploads: requestBody } as UpdateProductUpload);
    loadAsyncData();
    toastService.displaySuccessToast(
      ts.tModule('product_assets.success.included_assests_successfully'),
    );
  } catch (ex) {
    toastService.displayErrorToast(
      ts.updateFailed(ts.tModule('product_assets.title', { params: { count: 2 } }).toLowerCase()),
    );
  }
};

const editDialogVisible = (value: boolean): void => {
  editDialogActive.value = value;
};

watch(
  () => route,
  async () => {
    if (route.params.id == null) return;
    await loadAsyncData();
  },
  {
    deep: true,
  },
);

watch(
  () => checkedRows.value,
  () => {
    checkAllAssets.value = assets.value.every((asset) => checkedIds.value.includes(asset.id));
  },
);

onMounted(async () => {
  assetTypes.value = (await assetTypeApi.getAll({ query: '', limit: Defaults.REQUEST_LIMIT })).data;
  await loadAsyncData();
});
</script>
<template>
  <div>
    <Message severity="info" :closable="false">
      <p v-if="mediaInstructions" v-html="mediaInstructions" />
      <p v-else v-html="ts.tModule('product_assets_old.quality_tip')"></p>
    </Message>

    <DataTable
      v-model:selection="checkedRows"
      class="border-round p-datatable-sm"
      :value="assets"
      :loading="loading"
      removable-sort
      @row-reorder="onRowReorder"
    >
      <template #header>
        <div class="align-items-center flex gap-3 justify-content-end mb-2">
          <Button
            icon="mdi mdi-tray-arrow-up"
            :label="ts.tModule('product_assets.add')"
            @click="showMediaPicker"
          />
          <Button
            :disabled="checkedRows.length ? false : true"
            :label="ts.tGlobal('actions')"
            icon="mdi mdi-chevron-down"
            icon-pos="right"
            class="border-100 p-button-secondary"
            aria-haspopup="true"
            aria-controls="overlay_bulk_menu"
            @click="toggleBulkActionMenu"
          />
          <Menu id="overlay_bulk_menu" ref="bulkMenu" :model="bulkActions" :popup="true" />
        </div>
      </template>
      <template #empty>
        <EmptyState
          :translation-service="ts"
          :icon-name="'assets-folder'"
          :button-visible="false"
          :empty-state-title="
            ts.tGlobal('emptyStateTitle', {
              choice: 2,
              entity: ts.tModule('product_assets.title'),
            })
          "
          :empty-state-subtitle="ts.tModule('product_assets.empty_state_description')"
        >
        </EmptyState>
      </template>

      <Column
        :row-reorder="true"
        header-style="width: 3rem"
        :reorderable-column="false"
        row-reorder-icon="mdi mdi-drag cursor-move"
        class="text-4xl text-gray"
      />

      <Column frozen>
        <template #header>
          <div class="align-items-center flex">
            <Checkbox v-model="checkAllAssets" :binary="true" @change="toggleCheckAll" />
          </div>
        </template>
        <template #body="slotProps: { data: ProductUpload }">
          <div class="align-items-center flex">
            <Checkbox v-model="checkedRows" name="assets" :value="slotProps.data" />
          </div>
        </template>
      </Column>

      <Column field="filename" :header="ts.tModule('product_assets.asset_name')" sortable>
        <template #body="slotProps: { data: ProductUpload }">
          <div class="align-items-center flex">
            <UploadComponent preview :upload="slotProps.data" class="h-5rem mr-5 w-5rem" />
            <a :href="slotProps.data.public_url" target="_blank"
              >{{ truncateFilename(slotProps.data.filename) }}
            </a>
          </div>
        </template>
      </Column>

      <Column field="asset_type" :header="ts.tModule('product_assets.asset_type')" sortable>
        <template #body="slotProps: { data: ProductUpload }">
          {{ slotProps.data.asset_type?.name }}
        </template>
      </Column>

      <Column field="resolution" :header="ts.tModule('product_assets.resolution')" sortable>
        <template #body="slotProps: { data: ProductUpload }">
          <div
            v-if="
              slotProps.data.resolution_in_pixels_height &&
              slotProps.data.resolution_in_pixels_width
            "
          >
            {{
              formatResolution(
                slotProps.data.resolution_in_pixels_height,
                slotProps.data.resolution_in_pixels_width,
              )
            }}
          </div>
        </template>
      </Column>

      <Column
        field="file_size"
        :header="ts.tModule('product_assets.table_header.filesize')"
        sortable
      >
        <template #body="slotProps: { data: ProductUpload }">
          {{ formatBytes(slotProps.data.file_size) }}
        </template>
      </Column>

      <Column frozen :header="ts.tModule('product_assets.include_in_export')">
        <template #body="slotProps: { data: ProductUpload }">
          <div class="align-items-center flex justify-content-center">
            <Checkbox
              v-model="slotProps.data.include_in_export"
              :binary="true"
              class="mr-5"
              name="includeInExport"
              @input="updateIncludeInExport([slotProps.data], slotProps.data.include_in_export)"
            />
          </div>
        </template>
      </Column>

      <Column field="actions" :header="ts.tGlobal('actions')">
        <template #body="slotProps: { data: ProductUpload }">
          <Button
            plain
            text
            rounded
            icon="mdi mdi-dots-vertical"
            aria-haspopup="true"
            aria-controls="overlay_menu"
            @click="(event: MouseEvent) => toggleActionMenu(event, slotProps.data)"
          ></Button>
          <Menu id="overlay_menu" ref="menu" :model="menuItems" :popup="true" />
        </template>
      </Column>
    </DataTable>

    <!-- Edit Asset Filename Dialog -->
    <EditAssetNameModal
      v-if="editDialogActive"
      :is-active="editDialogActive"
      :selected-asset="(selectedAsset as any)"
      @refresh="loadAsyncData"
      @hide="editDialogVisible(false)"
    />

    <MediaPicker
      v-model:visible="mediaPicker"
      :multiple="true"
      @choose-multiple-images="attachUploadData($event)"
      @hide="hideMediaPicker"
    />

    <ConfirmDialog group="assets" />
  </div>
</template>

<style lang="scss" scoped>
.p-menu {
  background-color: #fff !important;
}
.p-datatable :deep(.p-datatable-header) {
  background-color: #fff;
  border: 0;
}
.p-datatable :deep(.p-datatable-wrapper) {
  border-color: var(--surface-100) !important;
  border-width: 1px !important;
  border-style: solid;
  border-radius: var(--border-radius) !important;
}
</style>

<style lang="scss">
.p-menu.p-menu-overlay {
  background-color: #fff !important;
}
#overlay_menu {
  .delete {
    span {
      color: rgb(179, 0, 0) !important;
    }
  }
}
</style>
