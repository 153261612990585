<script setup lang="ts">
import { Activity, Locale } from 'platform-unit2-api';
import ActivityService from '../activity.service';
import { ActivityGroupedByTime } from '../views/activities.vue';
import { groupBy, map } from 'lodash';
import { computed } from 'vue';
import { useService } from '@/general/composables/useService';
import { activityServiceServiceKey } from '../activity.key';

interface Props {
  timeActivity: ActivityGroupedByTime;
  locales: Locale[];
}

const props = defineProps<Props>();
const activityService = useService(activityServiceServiceKey, ActivityService);
const activitiesBySimilarity = computed(() => groupBySimilarity(props.timeActivity.activities));
const groupBySimilarity = (activities: Activity[]): { code: string; activities: Activity[] }[] => {
  return map(
    groupBy(activities, (activity) => {
      const attrKey = activityService.getAttributeName(activity);
      return attrKey + (activity.event?.toString() ?? '');
    }),
    (activities, code) => ({
      code,
      activities,
    }),
  );
};
</script>

<template>
  <div v-for="similarity in activitiesBySimilarity" :key="similarity.code" class="pup-my-6">
    <div v-if="similarity.activities.length > 1">
      <Accordion multiple collapse-icon="mdi mdi-chevron-up" expand-icon="mdi mdi-chevron-down">
        <AccordionPanel :value="similarity.code">
          <AccordionHeader :pt="{ root: { class: 'pup-p-3 bg-gray-50 border-0' } }">
            <div>
              <i
                :class="`mdi ${activityService.getAccordionIcon(similarity.activities)} pup-mr-2`"
              />
              {{ activityService.getAccordionHeader(similarity.activities) }}
            </div>
          </AccordionHeader>
          <AccordionContent :pt="{ content: { class: 'bg-gray-50 border-0 m-0 p-0' } }">
            <Component
              :is="activityService.generateSingleActivityBox(activity)"
              v-for="activity in similarity.activities"
              :key="activity.id"
              :activity="activity"
              :locales="locales"
              disable-date
              part-of-an-accordion
            />
          </AccordionContent>
        </AccordionPanel>
      </Accordion>
      <div class="pup-ml-1 pup-mt-1 text-sm">
        {{ timeActivity.time }} by
        {{ similarity.activities[0]?.user }}
      </div>
    </div>

    <Component
      :is="activityService.generateSingleActivityBox(activity)"
      v-for="activity in similarity.activities"
      v-else
      :key="activity.id"
      :activity="activity"
      :locales="locales"
      class="pup-my-6"
    />
  </div>
</template>
