<script setup lang="ts">
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { ActionRestService } from 'platform-unit2-api';
import { ref } from 'vue';

/** Props */
interface Props {
  ids: number[];
  isActive: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isActive: false,
  ids: () => [],
});

/** Emits */
const emit = defineEmits<{
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const actionsApi = new ActionRestService();

/** Consts */
const email = ref('');
const message = ref('');

const share = async () => {
  buttonsOptions.value.saving = true;

  try {
    await actionsApi.shareProducts(props.ids, email.value, message.value);
    toastService.displaySuccessToast(
      ts.tModule('share_products.sharing_started', {
        params: {
          amount: props.ids.length,
          email: email.value,
        },
      }),
    );
    emit('hide');
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('share_products.sharing_failed'));
  } finally {
    buttonsOptions.value.saving = false;
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const shareButton = new CreateButton({
  label: ts.tGlobal('share'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !!email.value,
  onClick: share,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, shareButton],
});
</script>

<template>
  <BaseDialog
    :title="ts.tModule('share_products.title')"
    :visible="isActive"
    :buttons-options="buttonsOptions"
    size="medium"
    closable
    @update:visible="emit('hide')"
  >
    <div class="field mb-3">
      <label>
        {{ ts.tGlobal('email') }}
      </label>
      <InputText v-model="email" class="w-full" type="text" />
    </div>

    <div class="field mb-3">
      <label>
        {{ ts.tModule('share_products.message') }}
      </label>
      <Textarea
        v-model="message"
        class="w-full"
        type="text"
        rows="4"
        :placeholder="ts.tModule('share_products.message_placeholder')"
      />
    </div>
  </BaseDialog>
</template>
