import { GlobalTranslationsType } from '@/core/i18n/translations/en/global.translation';
import { Platform } from '@/core/router/route.factory';
import { GeneralTranslationsType } from '@/general/translations/en';
import { RetailerTranslationsType } from '@/platforms/retailer/translations/en';
import { SupplierTranslationsType } from '@/platforms/supplier/translations/en';
import { Composer, useI18n } from 'vue-i18n';
import { DebugLogger } from '@/core/main/debug.logger';

type PlatformSettingsType<P extends Platform> = P extends 'supplier'
  ? SupplierTranslationsType
  : P extends 'retailer'
  ? RetailerTranslationsType
  : P extends 'general'
  ? GeneralTranslationsType
  : GlobalTranslationsType;

type ModuleKeys<P extends Platform> = keyof PlatformSettingsType<P>;

type TranslationKeys<P extends Platform, M extends ModuleKeys<P>> = NestedKeyOf<
  PlatformSettingsType<P>[M]
>;

// Recursively generate dot-separated keys for a nested object
type NestedKeyOf<T> = T extends object
  ? {
      [K in keyof T]: K extends string
        ? T[K] extends object
          ? `${K}.${NestedKeyOf<T[K]>}` | K
          : K
        : never;
    }[keyof T]
  : never;

class BetterTranslationService<P extends Platform = never, M extends ModuleKeys<P> = never> {
  private currentPlatform?: P;
  private currentModule?: keyof PlatformSettingsType<P>;
  private _i18n: Composer;

  constructor(platform?: P, module?: ModuleKeys<P>, i18n = useI18n()) {
    this._i18n = i18n;
    this.currentPlatform = platform;
    this.currentModule = module;
  }

  get global(): BetterTranslationService<'global', 'words'> {
    return this.platform('global').module('words');
  }

  platform<NewP extends Platform>(platform: NewP): BetterTranslationService<NewP> {
    return new BetterTranslationService<NewP>(platform);
  }

  module<NewM extends ModuleKeys<P>>(moduleName: NewM): BetterTranslationService<P, NewM> {
    if (this.currentPlatform == null) {
      DebugLogger.error(
        'Platform not set. In order to do that, use the platform method before the module one.',
      );

      return new BetterTranslationService<P, NewM>(this.currentPlatform, this.currentModule);
    }

    return new BetterTranslationService<P, NewM>(this.currentPlatform, moduleName);
  }

  translate<T extends TranslationKeys<P, M>>(key: T, args?: any, count?: number): string {
    const finalKey = `${this.currentPlatform}.${
      this.currentPlatform !== 'global' ? this.currentModule?.toString() + '.' : ''
    }${key}`;

    if (this.currentPlatform == null || this.currentModule == null) {
      DebugLogger.error(
        'Platform or module not set. Use the platform and module methods before calling the translate method.',
      );

      return finalKey;
    }

    return this._i18n.t(finalKey, args, count ?? 1);
  }
}

export default BetterTranslationService;
