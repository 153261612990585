export class DebugLogger {
  private static _debugLogging = false;

  private static _instance: DebugLogger;

  /**
   * singleton
   */
  constructor() {
    if (DebugLogger._instance) {
      return DebugLogger._instance;
    }

    return this;
  }

  public static async init() {
    const debugOn =
      window.location.hostname?.includes('localhost') ||
      window.location.hostname?.includes('127.0.0.1') ||
      window.location.hostname?.includes('staging') ||
      window.location.hostname?.includes('dev-retail');

    DebugLogger._debugLogging = debugOn;

    DebugLogger.warn(
      'Hi!, debugger mode is enabled. This is done throught the feature flag, These messages should not be shown to clients',
    );
  }

  //#region static
  public static log(...content: any) {
    if (DebugLogger._debugLogging) {
      console.log('[DEBUGGER]:', ...content);
    }
  }

  public static info(...content: any) {
    if (DebugLogger._debugLogging) {
      console.info('[DEBUGGER]:', ...content);
    }
  }

  public static warn(...content: any) {
    if (DebugLogger._debugLogging) {
      console.warn('[DEBUGGER]:', ...content);
    }
  }

  public static error(...content: any) {
    if (DebugLogger._debugLogging || process.env.NODE_ENV !== 'production') {
      console.error('[DEBUGGER]:', ...content);
    }
  }
  //#endregion

  //#region instance
  public log(...content: any) {
    DebugLogger.log(content);
  }

  public info(...content: any) {
    DebugLogger.info(content);
  }

  public warn(...content: any) {
    DebugLogger.warn(content);
  }

  public error(...content: any) {
    DebugLogger.error(content);
  }
  //#endregion
}
