<script setup lang="ts">
import { Client } from 'platform-unit2-api/clients';
import { ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import ClientSelect from '@/general/ui/components/selects/client-select.vue';
import { ActionRestService } from 'platform-unit2-api';

/** Props */
interface Props {
  ids: number[];
  isActive: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  id: () => [],
  isActive: false,
});

/** Emits */
const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const actionApi = new ActionRestService();

/** Consts */
const selectedClient = ref<Client>();

const assign = async () => {
  if (selectedClient.value == null) {
    return;
  }

  try {
    await actionApi.assignProductsToClient(
      selectedClient.value.id,
      props.ids.map((id) => id.toString()),
    );
    toastService.displaySuccessToast(
      ts.tModule('assign_to_client.transfer_started', {
        params: {
          amount: props.ids.length,
          client: selectedClient.value.name,
        },
      }),
    );
    emit('close');
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('assign_to_client.transfer_failed'));
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const assignButton = new CreateButton({
  label: ts.tModule('assign_to_client.title'),
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !!selectedClient.value,
  onClick: assign,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, assignButton],
});
</script>
<template>
  <BaseDialog
    :title="ts.tModule('assign_to_client.title')"
    :visible="isActive"
    :buttons-options="buttonsOptions"
    size="medium"
    @update:visible="$emit('hide')"
  >
    <div class="field mb-3">
      <ClientSelect v-model="selectedClient" />
    </div>
  </BaseDialog>
</template>
