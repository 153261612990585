import { isPositive } from '@/general/utils/isPositive';
import { RefetchService } from '@/general/services/overview-service/refetch.service';
import { useRoute, Router, useRouter } from 'vue-router';
import { ConfirmService } from '../../confirm/confirm.service';
import { FormValidationService } from '../../form-validation/form-validation.service';
import { TranslationService } from '../../translations/translation.service';
import { ToastService } from '../../toasts/toast.service';
import { Api, BaseRest, ListResponse, PaginationObject } from 'platform-unit2-api/core';
import { cloneDeep } from 'lodash';
import { OverviewBase } from '../interfaces/overview-base.interface';
import { OverviewDialogBase } from '../interfaces/overview-dialog-base.interface';
import { OverviewSidebarBase } from '../interfaces/overview-sidebar.interface';

/**
 * Base class for all view.vue services.
 * This class contains all the logic for a overview page that contains a crud sidebar.
 */
export abstract class BaseViewService<
    API extends BaseRest<T, C, U, F> | Api<T, C, U>,
    T extends { id: number },
    C,
    U extends { id: number },
    F = {},
  >
  extends RefetchService<F>
  implements OverviewBase, OverviewDialogBase, OverviewSidebarBase
{
  //#region Abstracts

  /**
   * Get the a body to send to the backend for post calls to create the object.
   */
  public abstract createBody(): C | undefined;

  /**
   * Get the a body to send to the backend for put calls to update the object.
   */
  public abstract updateBody(): U | undefined;

  /**
   * Function to determine if the current object is valid.
   */
  public abstract get validated(): boolean;
  //#endregion Abstracts

  //#region Validation Helper functions
  public hasErrors = () => this._formValidationService.hasErrors();

  public fieldErrorMessage = (name: string) => this._formValidationService.fieldErrorMessage(name);

  public hasError = (field: string): boolean => this._formValidationService.hasError(field);
  //#endregion

  //#region Services and composables
  protected _restService: API;
  protected _ts: TranslationService;
  protected _toastService: ToastService;
  protected _route: ReturnType<typeof useRoute>;
  protected _router: Router;
  protected _formValidationService: FormValidationService;
  protected _confirmService: ConfirmService;
  //#endregion

  //#region constants

  //Loading states
  protected _isLoadingOverview = false;
  protected _isLoadingCrudComponent = false;

  //Page states
  public crudComponent = false;
  public isInitialized = false;

  //#region routes
  protected _overviewRouteName: string;
  protected _createRouteName: string;
  protected _updateRouteName: string;
  //#endregion routes

  protected _fetchAllFunction: keyof API = 'getAll';

  /**
   * Function which happens after successfull fetch
   */
  getAllCallback?: Function;

  /**
   * Data of type T[]. T is the type of the object that is returned from the api.
   */
  protected _data: T[] = [];

  /**
   * Current object of type T. T is the type of the object that is returned from the api.
   */
  protected _currentObject: T | Partial<T> | undefined;

  /**
   * Confirm pop up group name. For deletebutton in sidebar.
   */
  public confirmPopUpGroup: string;
  //#endregion

  //#region Getters and setters
  /**
   * Get the data from the service.
   * Data is of type T[]. T is the type of the object that is returned from the api.
   */
  protected get data(): T[] {
    return this._data;
  }

  /**
   * Set the data from the service.
   */
  protected set data(data: T[]) {
    this._data = data;
  }

  /**
   * Get a partial object from the service from type T to use in a create form.
   */
  public get partialObject(): Partial<T> {
    if (this._currentObject == null) {
      this._currentObject = {};
    }

    return this._currentObject;
  }

  /**
   * Getter for current object
   */
  protected get current(): T | Partial<T> | undefined {
    return this._currentObject;
  }

  /**
   * Setter for current object.
   * Also sets current object to a copy of the object to prevent changes in the form from changing the original object
   */
  protected set current(object: T | Partial<T> | undefined) {
    if (object != null) {
      //Copy the object to prevent changes in the form from changing the original object
      this._currentObject = cloneDeep(object);
    } else {
      this._currentObject = object;
    }
  }

  /**
   * Getter for isLoadingOverview
   */
  public get isLoadingOverView(): boolean {
    return this._isLoadingOverview;
  }

  /**
   * Getter for isLoadingSidebar
   */
  public get isLoadingCrudComponent(): boolean {
    return this._isLoadingCrudComponent;
  }

  /**
   * Getter checks for current route being the create route.
   */
  public get isCreating(): boolean {
    return this._route.name === this._createRouteName;
  }

  /**
   * Disable save button if the form is not validated or if the sidebar is loading
   */
  public get disableSave(): boolean {
    if (this._isLoadingCrudComponent) {
      return true;
    }

    return !this.validated;
  }

  public get ts() {
    return this._ts;
  }
  //#endregion

  /**
   * Constructor
   * Set all the services and composables
   */
  constructor(params: {
    Api: { new (): API };
    ts: TranslationService;
    overviewRouteName: string;
    createRouteName: string;
    updateRouteName: string;
    confirmPopUpGroup: string;
    fetchAllFunction?: keyof API;
  }) {
    super();
    this._restService = new params.Api();
    this._ts = params.ts;
    this._overviewRouteName = params.overviewRouteName;
    this._createRouteName = params.createRouteName;
    this._updateRouteName = params.updateRouteName;
    this.confirmPopUpGroup = params.confirmPopUpGroup;

    this._toastService = ToastService.getInstance();
    this._formValidationService = new FormValidationService();
    this._confirmService = new ConfirmService();

    this._route = useRoute();
    this._router = useRouter();

    //Set the refetch function
    this.refetch = this.fetchAll;
    this._fetchAllFunction = params.fetchAllFunction ?? 'getAll';
  }

  /**
   * protected function to load the data and close the sidebar
   */
  protected _reloadPage(): void {
    this.refetch();
    this.closeCrudComponent();
  }

  public get resolveCrudComponentCondition(): boolean {
    return (
      isPositive(this._route.params.id) ||
      this._route.name === this._createRouteName ||
      this._route.name === this._updateRouteName
    );
  }

  /**
   * Resolve if the sidebar needs to be opened
   */
  public resolveCrudComponent() {
    this.crudComponent = false;
    if (this.resolveCrudComponentCondition) {
      this.openCrudComponent();
    }
  }

  /**
   * Open sidebar, also fetches the object if the id is set when reloading page
   * @param object set current object
   */
  public openCrudComponent(object?: T, redirect = true): void {
    this.crudComponent = true;
    this._formValidationService.resetErrors();

    //If page is refreshed and the id is set, fetch the objects
    //Router doesn't need to be called because user is already on update route
    if (object == null && this._route.params?.id != null && isPositive(this._route.params.id)) {
      this._get(Number(this._route.params.id));
      return;
    } else if (object != null) {
      this._get(object.id);
    }

    //set the current object
    this.current = object;

    //Route to child component to store the id in the url
    if (redirect) {
      if (this.current != null && this.current?.id != null) {
        this._router.push({ name: this._updateRouteName, params: { id: this.current.id } });
      } else {
        this._router.push({ name: this._createRouteName });
      }
    }
  }

  /**
   * Close sidebar and reset the current object
   */
  public closeCrudComponent(): void {
    this.crudComponent = false;
    this.current = undefined;
    this._router.push({ name: this._overviewRouteName });
  }

  /**
   * Create a new object
   */
  protected _create(createBody?: C): void {
    this._isLoadingCrudComponent = true;

    const body = createBody ?? this.createBody();

    if (body != null && this.validated) {
      this._restService
        .post(body)
        .then(() => {
          this._toastService.displaySuccessToast(this._ts.createSuccess());
          this._reloadPage();
        })
        .catch((e) => {
          this._formValidationService.handleError(e, () => {
            this._toastService.displayErrorToast(this._ts.createFailed());
          });
        })
        .finally(() => {
          this._isLoadingCrudComponent = false;
        });
    } else {
      this._isLoadingCrudComponent = false;
    }
  }

  /**
   * Update a object
   */
  protected _update(updateBody?: U): void {
    this._isLoadingCrudComponent = true;

    const body = updateBody ?? this.updateBody();

    if (body != null && this.validated) {
      if (body.id != null) {
        this._restService
          .update(body.id, body)
          .then(() => {
            this._toastService.displaySuccessToast(this._ts.updateSuccess());
            this._reloadPage();
          })
          .catch((e) => {
            this._formValidationService.handleError(e, () => {
              this._toastService.displayErrorToast(this._ts.updateFailed());
            });
          })
          .finally(() => {
            this._isLoadingCrudComponent = false;
          });
      }
    } else {
      this._isLoadingCrudComponent = false;
    }
  }

  /**
   * Save the object
   */
  public save(): void {
    this._formValidationService.resetErrors();

    if (this.isCreating) {
      this._create();
    } else {
      this._update();
    }
  }

  /**
   * Function to fetch all data. This function is called when the page is loaded.
   * Function should include the fetchvariables from the OverViewService.
   */
  public fetchAll(): void {
    this._isLoadingOverview = true;

    (
      this._restService[this._fetchAllFunction] as (
        paginationparams: PaginationObject,
        filterParams?: F,
      ) => Promise<ListResponse<T>>
    )(this._paginationParams, this._filterParams)
      .then((response: ListResponse<T>) => {
        this._data = response.data;
        this.total = response.meta?.total;
        this.getAllCallback?.();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
        this._data = [];
      })
      .finally(() => {
        this._isLoadingOverview = false;
        this.isInitialized = true;
      });
  }

  /**
   * Fetch the currunt object.
   * @param id id of the object to fetch
   */
  protected _get(id: number): void {
    this._isLoadingCrudComponent = true;

    this._restService
      .get(id)
      .then((object) => {
        if (!this.isCreating) {
          this.current = object;
        }
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.loadFailed());
        this.closeCrudComponent();
      })
      .finally(() => {
        this._isLoadingCrudComponent = false;
      });
  }

  protected delete(): void {
    if (this._currentObject?.id == null) {
      return;
    }

    this._isLoadingCrudComponent = false;

    this._restService
      .delete(this._currentObject.id)
      .then(() => {
        this._toastService.displaySuccessToast(this._ts.deleteSuccess());
        this._reloadPage();
      })
      .catch(() => {
        this._toastService.displayErrorToast(this._ts.deleteFailed());
      })
      .finally(() => {
        this._isLoadingCrudComponent = false;
      });
  }
  /**
   * Returns popup confirmation for deleting a module
   * @param event PointerEvent
   */
  public confirmDelete(event: PointerEvent): void {
    if (this.current?.id == null) {
      return;
    }

    this._confirmService.confirmDelete({
      event: event,
      group: this.confirmPopUpGroup,
      callback: () => this.delete(),
      message: this._ts.deleteConfirm(),
    });
  }
}
