<script lang="ts" setup>
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import CardSkeleton from '@/general/ui/components/skeletons/card-skeleton.vue';
import { Upload } from 'platform-unit2-api/uploads';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import AssetsList from '../assets/components/assets-list.vue';
import { assetViewServiceKey } from '../assets/keys/asset-view-service.key';

interface Props {
  selectedIds?: number[];
  acceptFiles?: string;
  colSize?: number;
  checkedAssets?: Upload[];
}
const props = withDefaults(defineProps<Props>(), {
  selectedIds: () => [],
  acceptFiles: '*',
  colSize: 2,
  checkedAssets: () => [],
});

const emit = defineEmits<{
  (e: 'hide'): void;
  (e: 'choose-image'): void;
  (e: 'choose-multiple-images', images: Upload[]): void;
}>();

const ts = new TranslationService('supplier', 'media');
const service = useService(assetViewServiceKey, AssetViewService) ?? new AssetViewService();
const visible = ref(false);

const setImages = (): void => {
  emit('choose-multiple-images', service.checkedAssets);
  service.checkedAssets = [];
  service.page = 1;
  service.fetchAll();
  emit('hide');
};

const closeMediaPicker = (): void => {
  service.page = 1;
  service.fetchAll();
  emit('hide');
};

onBeforeUnmount(() => {
  service.disabled = false;
});

onMounted(() => {
  service.disabled = true;
  service.checkedAssets = props.checkedAssets;
  if (service.assets.length === 0) {
    service.fetchAll();
  }
});
</script>

<template>
  <Drawer v-model:visible="visible" position="full" @hide="closeMediaPicker">
    <template #header>
      <h2>{{ ts.tModule('media_picker.title') }}</h2>
    </template>
    <div class="flex flex-column h-full">
      <CardSkeleton v-if="service.isLoadingOverView" />
      <AssetsList v-else @set-images="setImages" />
    </div>
  </Drawer>
</template>
<style lang="scss" scoped>
:deep(.p-speeddial-action) {
  width: 3rem;
  height: 3rem;
}
</style>
