<script setup lang="ts">
import useFileTypeColor from '@/general/utils/file-type-color';

/**Props */
defineProps<{
  filename: string;
}>();
/**Consts */
const { getFileTypeColor } = useFileTypeColor();
</script>
<template>
  <Tag
    class="align-self-center border-round-sm text-xs"
    :style="'background-color:' + getFileTypeColor(filename)"
    :value="filename.split('.').slice(-1)[0]?.toUpperCase()"
  />
</template>
