<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import { assetViewServiceKey } from '../../keys/asset-view-service.key';
import MatchedProduct from './matched-product.vue';
import { computed } from 'vue';
import { MatchedProductUploadsResponse } from 'platform-unit2-api';
import BetterTranslationService from '@/general/services/translations/better-translation.service';

const service = useService(assetViewServiceKey, AssetViewService);
const ts = new BetterTranslationService().platform('supplier').module('media');

const finalResult = computed<MatchedProductUploadsResponse[]>(() => {
  return [
    ...service.matchMediaModal.matchedAssets,
    ...service.matchMediaModal.unmatchedAssets,
  ].filter((result) => result.product != null);
});
</script>

<template>
  <div>
    <div
      v-if="finalResult.length === 0"
      class="align-items-center flex flex-column h-30rem items-center justify-content-center"
    >
      <i class="mdi mdi-barcode-scan text-gray-400" style="font-size: 100px" />
      <span class="font-semibold text-xl">
        {{ ts.translate('matchMedia.noConfiguration') }}
      </span>
    </div>
    <div v-else>
      <div v-if="finalResult.length > 0">
        <div class="flex flex-col gap-4 pup-my-5">
          <div class="flex gap-2 items-center">
            <Checkbox
              v-model="service.matchMediaModal.overwrite"
              input-id="match-media-checkbox-overwrite"
              binary
            />
            <label for="match-media-checkbox-overwrite">
              {{ ts.translate('matchMedia.overwrite') }}
            </label>
          </div>
          <div class="flex gap-2 items-center">
            <Checkbox
              v-model="service.matchMediaModal.updateVariants"
              binary
              input-id="match-media-checkbox-update-assets"
            />
            <label for="match-media-checkbox-update-assets">
              {{ ts.translate('matchMedia.updateVariants') }}
            </label>
          </div>
        </div>

        <div v-for="result in finalResult" :key="result.value" class="pup-mb-4">
          <MatchedProduct :result="result" />
        </div>
      </div>
    </div>
  </div>
</template>
