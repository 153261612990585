<script setup lang="ts">
import ProductStatusSelect from '@/general/ui/components/selects/product-status-select.vue';
import { ref } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { ProductStatus } from 'platform-unit2-api/product-statuses';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { ActionRestService } from 'platform-unit2-api';

/** Props */
interface Props {
  ids: number[];
  isActive: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isActive: false,
  ids: () => [],
});

/** Emits */
const emit = defineEmits<{
  (e: 'refresh'): void;
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');

/** Consts */
const selectedProductStatus = ref<ProductStatus>();
const actionsApi = new ActionRestService();

const submit = async () => {
  try {
    buttonsOptions.value.saving = true;
    await actionsApi.transitionState(props.ids, selectedProductStatus.value?.label ?? '');
    toastService.displaySuccessToast(
      ts.tModule('transition_state.success', {
        params: {
          state: selectedProductStatus.value?.label,
          amount: props.ids.length,
        },
      }),
    );
  } catch (err) {
    toastService.displayErrorToast(ts.tModule('transition_state.error'));
  } finally {
    emit('hide');
    emit('refresh');
    buttonsOptions.value.saving = false;
  }
};

const cancelButton = new CancelButton({
  label: ts.tGlobal('cancel'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => {
    emit('hide');
  },
});

const transitionButton = new CreateButton({
  label: ts.tGlobal('transition'),
  position: CrudButtonPosition.RIGHT,
  onClick: () => submit(),
  isEnabled: () => selectedProductStatus.value != null,
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [cancelButton, transitionButton],
});
</script>

<template>
  <BaseDialog
    :title="ts.tModule('transition_state.title')"
    :visible="isActive"
    :buttons-options="buttonsOptions"
    size="small"
    @update:visible="emit('hide')"
  >
    <div class="col field mb-3">
      <ProductStatusSelect v-model="selectedProductStatus" />
    </div>
  </BaseDialog>
</template>
