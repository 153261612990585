const translationObject = {
  title: 'Activity | Activities',
  placeholder: 'Activity',

  emptyStateTitle: 'No activities found',
  emptyStateSubTitle: '',

  updatedAttributes: "Attribute updated | Updated {count} languages for '{attribute}'",
  updatedStatus: 'Product status change',
  productCreated: 'Variant created | Created {count} variants',
  productDeleted: 'Variant deleted | Deleted {count} variants',
  storyCreated: 'Story created',
  completenessScoreChange: 'Completeness score changed',
  assetAttached: 'Asset attached | Attached {count} assets',
  assetDeleted: 'Asset deleted from Assets page',
  productRestored: 'Product was restored | Restored {count} variants',
} as const;

export default translationObject;
