export class AdminModeToggleService {
  private _urlMap = new Map<string, string>([
    ['localhost', 'http://localhost:8088'],
    ['dev.worldofcontent.com', 'https://dev-retail-admin.productsup.dev'],
    ['staging.worldofcontent.com', 'https://staging-retail-admin.productsup.dev'],
    ['retail.productsup.com', 'https://retail-admin.productsup.com/'],
  ]);

  isHostCorrect(adminToggled: boolean): boolean {
    const host = window.location.hostname;
    return adminToggled ? !this._urlMap.has(host) : this._urlMap.has(host); //! if the key exists, the host is NOT an admin
  }

  public switchToAdminPanel(): void {
    const url = this._urlMap.get(window.location.hostname);

    if (!url) return;

    window.location.href = url;
  }
}
