<script setup lang="ts">
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import { assetViewServiceKey } from '../../keys/asset-view-service.key';
import Upload from '@/general/ui/components/upload.vue';
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import ProductSearchSelect from '@/general/ui/components/selects/product-search-select.vue';
import BetterTranslationService from '@/general/services/translations/better-translation.service';

const service = useService(assetViewServiceKey, AssetViewService);
const ts = new BetterTranslationService().platform('supplier').module('media');
</script>

<template>
  <div>
    <div v-if="service.matchMediaModal.unmatchedAssets.length > 0">
      <div v-for="result in service.matchMediaModal.unmatchedAssets" :key="result.value">
        <div v-for="file in result.thumbnails" :key="file.id">
          <div class="p-fileupload-file">
            <Upload v-if="file.public_thumbnail_url" preview :upload="file" class="w-4rem" />
            <SvgIcon v-else :filename="file.public_url" custom-style="width: 4rem" />
            <div class="p-fileupload-file-info">
              <div class="p-fileupload-file-name">
                {{ file.filename }}
              </div>
              <span class="p-fileupload-file-size">
                {{ file.file_size }}
              </span>
            </div>
            <div class="p-fileupload-file-actions w-25rem">
              <ProductSearchSelect
                :model-value="(result.product as any)"
                @update:model-value="(e) => (result.product = e)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="align-items-center flex flex-column h-30rem items-center justify-content-center"
    >
      <i class="mdi mdi-barcode-scan text-gray-400" style="font-size: 100px" />
      <span class="font-semibold text-xl">{{
        ts.translate('matchMedia.noProductsUnmatched')
      }}</span>
    </div>
  </div>
</template>
