/* eslint-disable vue/no-reserved-component-names */
import { App } from 'vue';

import Accordion from 'primevue/accordion';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import AccordionPanel from 'primevue/accordionpanel';
import AutoComplete from 'primevue/autocomplete';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import ColorPicker from 'primevue/colorpicker';
import Column, { ColumnContext } from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DatePicker from 'primevue/datepicker';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Drawer from 'primevue/drawer';
import Editor from 'primevue/editor';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import IconField from 'primevue/iconfield';
import Image from 'primevue/image';
import Inplace from 'primevue/inplace';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputIcon from 'primevue/inputicon';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayBadge from 'primevue/overlaybadge';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import Popover from 'primevue/popover';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import ScrollPanel from 'primevue/scrollpanel';
import Select from 'primevue/select';
import SelectButton from 'primevue/selectbutton';
import Skeleton from 'primevue/skeleton';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Tab from 'primevue/tab';
import TabList from 'primevue/tablist';
import Tabs from 'primevue/tabs';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToggleButton from 'primevue/togglebutton';
import ToggleSwitch from 'primevue/toggleswitch';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import VirtualScroller from 'primevue/virtualscroller';
import Stepper from 'primevue/stepper';
import StepList from 'primevue/steplist';
import StepPanels from 'primevue/steppanels';
import StepItem from 'primevue/stepitem';
import Step from 'primevue/step';
import StepPanel from 'primevue/steppanel';

// Soon to be depricated
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';

import { colorPalette, semantic } from '@/assets/theme/primeVueTheme/colorPalette';
import { components } from '@/assets/theme/primeVueTheme/componentsStyling';
import '@/assets/theme/theme.scss';
import { definePreset } from '@primevue/themes';
import Lara from '@primevue/themes/lara';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import PrimeVue from 'primevue/config';

const pupTheme = definePreset(Lara, {
  // Specifying the color palette
  primitive: colorPalette,
  // Defining theme colors & main styling
  semantic: semantic,
  // Modifying theme components using design tokens
  components: components,
});

export function installPrimeVue(app: App) {
  app.use(PrimeVue, {
    theme: {
      preset: pupTheme,
      options: {
        darkModeSelector: 'light',
      },
    },
    pt: {
      datatable: {
        column: {
          pcColumnFilterButton: ({ context }: { context: ColumnContext }) => ({
            style: context.active ? 'background: var(--primary); color: white;' : '',
            class: 'w-2rem h-2rem',
          }),
        },
      },
    },
  });
  app.component('Button', Button);
  app.component('InputText', InputText);
  app.component('Textarea', Textarea);
  app.component('MultiSelect', MultiSelect);
  app.component('Password', Password);
  app.component('Message', Message);
  app.component('InputMask', InputMask);
  app.component('InputNumber', InputNumber);
  app.component('Card', Card);
  app.component('Avatar', Avatar);
  app.component('AvatarGroup', AvatarGroup);
  app.component('Divider', Divider);
  app.component('Select', Select);
  app.component('Menu', Menu);
  app.component('Checkbox', Checkbox);
  app.component('ToggleSwitch', ToggleSwitch);
  app.component('Steps', Steps);
  app.component('Chart', Chart);
  app.component('DataTable', DataTable);
  app.component('DataView', DataView);
  app.component('Column', Column);
  app.component('ProgressBar', ProgressBar);
  app.component('Skeleton', Skeleton);
  app.component('DatePicker', DatePicker);
  app.component('Drawer', Drawer);
  app.component('FileUpload', FileUpload);
  app.component('Paginator', Paginator);
  app.component('Breadcrumb', Breadcrumb);
  app.component('Dialog', Dialog);
  app.component('ConfirmPopup', ConfirmPopup);
  app.component('Toast', Toast);
  app.component('ConfirmDialog', ConfirmDialog);
  app.component('TabView', TabView);
  app.component('TabPanel', TabPanel);
  app.component('Badge', Badge);
  app.component('OverlayBadge', OverlayBadge);
  app.component('Fieldset', Fieldset);
  app.component('Tree', Tree);
  app.component('TreeTable', TreeTable);
  app.component('TreeSelect', TreeSelect);
  app.component('Chip', Chip);
  app.component('ToggleButton', ToggleButton);
  app.component('ProgressSpinner', ProgressSpinner);
  app.component('Timeline', Timeline);
  app.component('ContextMenu', ContextMenu);
  app.component('Listbox', Listbox);
  app.component('Carousel', Carousel);
  app.component('Tag', Tag);
  app.component('RadioButton', RadioButton);
  app.component('Menubar', Menubar);
  app.component('Accordion', Accordion);
  app.component('AccordionPanel', AccordionPanel);
  app.component('AccordionHeader', AccordionHeader);
  app.component('AccordionContent', AccordionContent);
  app.component('ColorPicker', ColorPicker);
  app.component('ScrollPanel', ScrollPanel);
  app.component('SplitButton', SplitButton);
  app.component('AutoComplete', AutoComplete);
  app.component('Image', Image);
  app.component('SpeedDial', SpeedDial);
  app.component('SelectButton', SelectButton);
  app.component('Panel', Panel);
  app.component('Splitter', Splitter);
  app.component('SplitterPanel', SplitterPanel);
  app.component('Popover', Popover);
  app.component('OrderList', OrderList);
  app.component('PickList', PickList);
  app.component('Inplace', Inplace);
  app.component('VirtualScroller', VirtualScroller);
  app.component('TabMenu', TabMenu);
  app.component('Editor', Editor);
  app.component('OrganizationChart', OrganizationChart);
  app.component('InputIcon', InputIcon);
  app.component('IconField', IconField);
  app.component('InputGroup', InputGroup);
  app.component('InputGroupAddon', InputGroupAddon);
  app.component('Tabs', Tabs);
  app.component('Tab', Tab);
  app.component('TabList', TabList);
  app.component('Stepper', Stepper);
  app.component('StepList', StepList);
  app.component('StepPanels', StepPanels);
  app.component('StepItem', StepItem);
  app.component('Step', Step);
  app.component('StepPanel', StepPanel);

  app.directive('tooltip', Tooltip);
}
