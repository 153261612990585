<script setup lang="ts">
import SidebarSkeleton from '@/general/ui/components/skeletons/sidebar-skeleton.vue';
import ClientModuleSelect from '@/general/ui/components/selects/client-module-select.vue';
import { ref, watch } from 'vue';
import { TranslationService } from '@/general/services/translations/translation.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { Product } from 'platform-unit2-api/products';
import DatamodelSelect from '@/general/ui/components/selects/datamodel-select.vue';
import { ClientTypeEnum } from 'platform-unit2-api/client-types';
import { useService } from '@/general/composables/useService';
import { productDetailsServiceKey } from '../key/product-details-service.key';
import { ProductDetailsService } from '../services/product-details.service';

/** Props */
withDefaults(
  defineProps<{
    isActive: boolean;
  }>(),
  {
    isActive: false,
  },
);

/** Emits */
const emit = defineEmits<{
  (e: 'saved', id: number): void;
  (e: 'hide'): void;
}>();

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const productDetailsService = useService(productDetailsServiceKey, ProductDetailsService);

/** Consts */
const copyDataFrom = ref<Product>();
const copyAssetsFrom = ref<Product>();
const variantGtin = ref(productDetailsService.currentVariant?.gtin ?? '');
const variantName = ref('');
const hideDetails = () => {
  emit('hide');
};

const handleSubmit = async () => {
  try {
    const response = await productDetailsService.createVariant(
      variantGtin.value,
      variantName.value,
      copyDataFrom.value,
      copyAssetsFrom.value,
    );
    if (response?.id) {
      productDetailsService.productVariants.push({ ...response });
      emit('saved', response.id);
    }

    toastService.displaySuccessToast(ts.createSuccess());
  } catch (ex) {
    toastService.displayErrorToast(ts.createFailed());
  }
};

watch(
  () => productDetailsService.selectedModule,
  () => {
    let result = variantName.value;
    if (productDetailsService.selectedModule) {
      result = `${productDetailsService.selectedModule.name}`;
    }

    variantName.value = result;
  },
);
</script>

<template>
  <Drawer
    :visible="isActive"
    class="p-drawer-md"
    :dismissable="false"
    position="right"
    :header="ts.tModule('product_details.create_variant.title')"
    @update:visible="$emit('hide')"
  >
    <div class="h-full">
      <SidebarSkeleton v-if="productDetailsService.loadingProduct" />
      <div v-else class="flex flex-column h-full">
        <div class="field mb-3">
          <label for="variant-name">{{ ts.tGlobal('name') }}</label>
          <InputText
            id="variant-name"
            v-model="variantName"
            class="w-full"
            :placeholder="ts.tModule('product_details.create_variant.name.placeholder')"
            type="text"
          />
        </div>

        <div class="field mb-3">
          <label for="gtin">{{ ts.tGlobal('gtin') }}</label>
          <InputText id="gtin" v-model="variantGtin" class="w-full" type="text" />
        </div>

        <div class="field mb-3">
          <ClientModuleSelect v-model="productDetailsService.selectedModule" type="export" />
        </div>

        <div
          v-if="
            productDetailsService.selectedModule != null &&
            productDetailsService.selectedModule.client?.workspace_type?.type ===
              ClientTypeEnum.RETAILER
          "
          class="field mb-3"
        >
          <DatamodelSelect
            v-model="productDetailsService.selectedDatamodel"
            :channel-id="productDetailsService.selectedModule.id"
          />
        </div>

        <div class="field mb-3">
          <label for="variant-name">{{
            ts.tModule('product_details.create_variant.copy_data_from.label')
          }}</label>
          <Select
            v-model="copyDataFrom"
            option-label="variant_name"
            class="w-full"
            :show-clear="true"
            :options="productDetailsService.productVariants"
          >
            <template #value="slotProps: { value: Product }">
              {{
                slotProps.value
                  ? slotProps.value.variant_name ?? ts.tGlobal('master_data')
                  : ts.tModule('product_details.create_variant.select_product')
              }}
            </template>
            <template #option="slotProps">
              {{ slotProps.option.variant_name ?? ts.tGlobal('master_data') }}
            </template>
          </Select>
        </div>
        <div class="field mb-3">
          <label for="variant-name">
            {{ ts.tModule('product_details.create_variant.copy_assets_from.label') }}
          </label>
          <Select
            v-model="copyAssetsFrom"
            option-label="variant_name"
            class="w-full"
            :show-clear="true"
            :options="productDetailsService.productVariants"
          >
            <template #value="slotProps: { value: Product }">
              {{
                slotProps.value
                  ? slotProps.value.variant_name ?? ts.tGlobal('master_data')
                  : ts.tModule('product_details.create_variant.select_product')
              }}
            </template>
            <template #option="slotProps: { option: Product }">
              {{ slotProps.option.variant_name ?? ts.tGlobal('master_data') }}
            </template>
          </Select>
        </div>
        <div
          class="bg-white border-100 border-top-1 bottom-0 flex justify-content-between mt-5 mt-auto py-3 sticky"
        >
          <Button
            plain
            text
            :label="ts.tGlobal('cancel')"
            class="ml-auto mr-2"
            @click="hideDetails"
          />
          <Button :label="ts.tGlobal('save')" icon-pos="right" @click="handleSubmit()" />
        </div>
      </div>
    </div>
  </Drawer>
</template>
