<script lang="ts" setup>
import { useService } from '@/general/composables/useService';
import { assetViewServiceKey } from '../../keys/asset-view-service.key';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import BetterTranslationService from '@/general/services/translations/better-translation.service';
import { CrudButtonPosition } from '@/general/ui/components/crud-buttons/ts/enums/crud-button-position.enum';
import { CancelButton } from '@/general/ui/components/crud-buttons/ts/classes/cancel-crud-button.class';
import { CrudButtonsOptions } from '@/general/ui/components/crud-buttons/ts/interfaces/crud-button-option.interface';
import { ref, watch } from 'vue';
import BaseDialog from '@/general/ui/components/dialog/base-dialog.vue';
import { CreateButton } from '@/general/ui/components/crud-buttons/ts/classes/create-crud-button.class';

const ts = new BetterTranslationService().platform('supplier').module('media');
const service = useService(assetViewServiceKey, AssetViewService);

const prevButton = new CancelButton({
  label: ts.global.translate('cancel'),
  position: CrudButtonPosition.RIGHT,
  styleClasses: 'p-button-outlined',
  isEnabled: () => !service?.matchMediaModal?.loading,
  onClick: () => service?.matchMediaModal?.toggleVisible(),
});

const nextButton = new CreateButton({
  label: () => service.matchMediaModal.getNextModalButtonConfig().label as string,
  position: CrudButtonPosition.RIGHT,
  isEnabled: () => !service.matchMediaModal.loading && !service.matchMediaModal.errorMatching,
  onClick: () => service.matchMediaModal.getNextModalButtonConfig().onClick(),
});

const buttonsOptions = ref<CrudButtonsOptions>({
  buttons: [prevButton, nextButton],
});

watch(
  () => service.checkedAssets,
  () => {
    service.matchMediaModal.assignGTINsToAssets(service.checkedAssets);
  },
  { deep: true },
);
</script>

<template>
  <BaseDialog
    v-if="service != null"
    :visible="service.matchMediaModal.visible"
    :title="ts.translate(`matchMedia.title`)"
    :subtitle="ts.translate(`matchMedia.subtitle`)"
    :buttons-options="buttonsOptions"
    size="medium"
    content-style="min-height: 35rem"
    @update:visible="service.matchMediaModal.toggleVisible()"
  >
    <Stepper :value="service.matchMediaModal.currentStep" linear>
      <StepList>
        <Step v-for="step in service.matchMediaModal.steps" :key="step.value" :value="step.value">
          {{ ts.translate(step.label as any) }}
        </Step>
      </StepList>
      <StepPanels>
        <StepPanel
          v-for="step in service.matchMediaModal.steps"
          :key="step.value"
          :value="step.value"
        >
          <Component :is="step.component" />
        </StepPanel>
      </StepPanels>
    </Stepper>
  </BaseDialog>
</template>
