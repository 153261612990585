<script setup lang="ts">
import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { useService } from '@/general/composables/useService';
import { productDetailsServiceKey } from '../key/product-details-service.key';
import { ProductDetailsService } from '../services/product-details.service';
import { DebugLogger } from '@/core/main/debug.logger';
import { RouteLocationRaw, RouteParamsRaw, useRoute, useRouter } from 'vue-router';
import { computed, inject, ref } from 'vue';
import { ProductCompact } from 'platform-unit2-api';

/**Props */
interface Props {
  isSelected: boolean;
  variant?: ProductCompact;
}

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
  variant: undefined,
});

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const confirmService = new ConfirmService();
const route = useRoute();
const router = useRouter();
const productAttributeFieldsService = inject(productAttributeFieldsServiceKey)!;
const productDetailsService = useService(productDetailsServiceKey, ProductDetailsService);

const isMasterData = computed(() => {
  return props.variant?.variant_name == null;
});

const channelThumbnail = computed(() => {
  return props.variant?.module?.retailer?.thumbnail?.public_thumbnail_url || '';
});

const confrimVariantDelete = () => {
  confirmService.confirmDelete({
    callback: () => deleteVariant(),
    group: 'top-level',
    message: ts.deleteConfirm(props.variant?.variant_name || ''),
  });
};

const deleteVariant = async () => {
  if (isMasterData.value) {
    return;
  }

  try {
    if (!productDetailsService.currentVariant) {
      DebugLogger.error('Variant is not set');
      return;
    }

    await productDetailsService.deleteVariant(productDetailsService.currentVariant?.id);
    toastService.displaySuccessToast(ts.deleteSuccess());

    router.push({
      name: route.name,
      params: {
        id: productDetailsService.currentMasterDataProduct?.id.toString(),
      } as RouteParamsRaw,
    } as RouteLocationRaw);

    await productDetailsService.getProduct(productDetailsService.currentMasterDataProduct!.id);
  } catch (ex) {
    toastService.displayErrorToast(ts.deleteFailed());
  }
};

const menu = ref();
const items = ref([
  {
    visible: () => !isMasterData.value,
    label: ts.tModule('remove_variant'),
    icon: 'mdi mdi-delete-outline',
    command: () => {
      confrimVariantDelete();
    },
  },
]);

const toggleVariantMenu = (event: MouseEvent) => {
  menu.value.toggle(event);
};
</script>

<template>
  <OverlayBadge
    v-if="isSelected && productAttributeFieldsService.isSaveButtonDisabled()"
    :value="productAttributeFieldsService.errorCount"
    severity="danger"
    class="mt-1 w-max"
  >
    <div
      class="align-items-center border-1 border-200 border-round cursor-pointer h-4rem inline-flex mt-2 px-2"
      :class="{
        'bg-primary text-white border-primary': isSelected,
        'border-0': isMasterData,
      }"
    >
      <p class="mx-2">
        {{ variant?.variant_name ? variant.variant_name : ts.tGlobal('master_data') }}
      </p>
      <p class="mr-2">{{ variant?.completeness || 0 }}%</p>
      <img
        v-if="!isMasterData && variant?.module"
        class="border-circle"
        :src="channelThumbnail"
        style="width: 2.5rem"
      />
      <Button
        v-if="!isMasterData"
        aria-haspopup="true"
        aria-controls="variant_menu"
        :class="{
          'text-white': isSelected,
        }"
        :disabled="!isSelected"
        icon="mdi mdi-dots-vertical"
        text
        @click="(event: MouseEvent) => toggleVariantMenu(event)"
      />
      <Menu id="variant_menu" ref="menu" :model="items" :popup="true" />
    </div>
  </OverlayBadge>
  <div
    v-else
    class="align-items-center border-1 border-200 border-round cursor-pointer h-4rem inline-flex ml-3 mt-2 px-2"
    :class="{
      'bg-primary text-white border-primary': isSelected,
      'border-0': isMasterData,
    }"
  >
    <p class="mx-2">
      {{ variant?.variant_name ? variant.variant_name : ts.tGlobal('master_data') }}
    </p>
    <p class="mr-2">{{ variant?.completeness || 0 }}%</p>
    <img
      v-if="!isMasterData && variant?.module"
      class="border-circle"
      :src="channelThumbnail"
      style="width: 2.5rem"
    />
    <Button
      v-if="!isMasterData"
      aria-haspopup="true"
      aria-controls="variant_menu"
      :class="{
        'bg-white text-gray-600 border-0': !isSelected,
        'text-white': isSelected,
      }"
      :disabled="!isSelected"
      icon="mdi mdi-dots-vertical"
      @click="(event: MouseEvent) => toggleVariantMenu(event)"
    />
    <Menu id="variant_menu" ref="menu" :model="items" :popup="true" />
  </div>
</template>
