import { Activity } from 'platform-unit2-api/activities';
<script setup lang="ts">
import BetterTranslationService from '@/general/services/translations/better-translation.service';
import ActivityBox, { ActivityBoxProps } from './activity-box.vue';

const props = defineProps<ActivityBoxProps>();
const ts = new BetterTranslationService().platform('supplier').module('activities');
</script>
<template>
  <ActivityBox v-bind="{ ...props }">
    <i class="mdi mdi-restore pup-mr-3 text-green-500" />
    <span v-if="!partOfAnAccordion" class="font-semibold">
      {{ ts.translate('productRestored') }} -
      {{ activity.modified?.variant_name?.new ?? 'Master data' }}
    </span>
    <span v-if="partOfAnAccordion" class="font-semibold">
      {{ activity.modified?.variant_name?.new ?? 'Master data' }}
    </span>
  </ActivityBox>
</template>
