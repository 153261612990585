<script setup lang="ts">
import { TranslationService } from '@/general/services/translations/translation.service';
import LazyLoader from '@/general/ui/components/lazyLoader.vue';
import ActivitiesSkeleton from '@/general/ui/components/skeletons/customs/activities-skeleton.vue';
import { groupBy } from 'lodash';
import { ProductsRestService } from 'platform-unit2-api';
import { Activity } from 'platform-unit2-api/activities';
import ActivityService from 'retailer/modules/activities/activity.service';

/** Define the interface for the props */
interface ActivityProps {
  isActive: boolean;
  moduleId?: number;
}
/** Props */
withDefaults(defineProps<ActivityProps>(), {
  isActive: false,
  moduleId: undefined,
});

/** Emits */
defineEmits<{
  (e: 'hide'): void;
}>();

/** Services */
const ts = new TranslationService('supplier', 'products');

/** constants */
const activityService = new ActivityService();

/** Generate the correct component to show the activity details */
const generateActivityBox = (activity: Activity[]) => {
  return activityService.generateActivityBox(activity);
};

const groupByType = (activities: Activity[]) => {
  return Object.values(groupBy(activities, 'event'));
};

const sortChronologically = (activities: Activity[]) => {
  return activities.sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );
};

const api = new ProductsRestService();
</script>
<template>
  <Drawer
    :visible="isActive"
    class="p-drawer-md"
    :dismissable="false"
    :modal="false"
    position="right"
    :header="ts.tModule('product_activities.title')"
    @update:visible="$emit('hide')"
  >
    <!-- Activity list -->
    <LazyLoader
      v-if="moduleId"
      :api="api"
      fetch-function-name="getProductActivities"
      :params="[moduleId]"
      :style="{ height: 'calc(100vh - 75px)' }"
      class="mt-5"
    >
      <template #layout="slotProps: { data: Activity[] }">
        <div v-if="slotProps.data?.length != null">
          <div
            v-for="activity in groupByType(sortChronologically(slotProps.data))"
            :key="activity.length"
          >
            <Component :is="generateActivityBox(activity)" :activity="activity" />
          </div>
        </div>
      </template>

      <!-- Loading -->
      <template #loading>
        <ActivitiesSkeleton />
      </template>
    </LazyLoader>
  </Drawer>
</template>
