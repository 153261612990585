<script setup lang="ts">
import { TaskViewService } from '@/general/services/overview-service/modules/task-view.service';
import { TranslationService } from '@/general/services/translations/translation.service';
import useCheckable from 'composables/checkable';
import { Product } from 'platform-unit2-api/products';
import { onMounted, provide, ref, watch } from 'vue';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import { taskViewServiceKey } from '../../tasks/ts/keys/task-view-service.key';
import ProductsTable from '../components/table/products-table.vue';
/** Services */
const ts = new TranslationService('supplier', 'products');

/** Constants */
const router = useRouter();
const route = useRoute();
const { checkedRows } = useCheckable();
const loading = ref(false);
const refreshProducts = ref(false);
const productstable = ref();

const showSidebar = ref(false);

const hideDetails = () => {
  router.push({
    name: 'products',
  } as RouteLocationRaw);
};

const resetRefresh = () => {
  refreshProducts.value = false;
};

const handleCreateProduct = async () => {
  router.push({ name: 'new-product' });
};

watch(
  () => route,
  () => {
    if (route.name === 'new-product') {
      showSidebar.value = true;
    }

    if (route.name === 'products') {
      showSidebar.value = false;
    }
  },
  {
    deep: true,
  },
);

onMounted(async () => {
  if (route.name === 'new-product') {
    showSidebar.value = true;
  }
});

const updateCheckedRows = (rows: Product[]) => {
  checkedRows.value = rows;
};

const taskService = ref(new TaskViewService('products'));
provide(taskViewServiceKey, taskService.value as TaskViewService);
</script>
<template>
  <section id="section" class="flex flex-column h-full">
    <!-- Product List Table  -->
    <ProductsTable
      ref="productstable"
      :refresh-products="refreshProducts"
      :load-data="loading"
      @update:checked-rows="updateCheckedRows"
      @reset-refresh="resetRefresh"
      @create-product="handleCreateProduct"
    />

    <Drawer
      v-model:visible="showSidebar"
      class="p-drawer-md"
      :dismissable="false"
      position="right"
      @hide="hideDetails"
    >
      <template #header>
        <div>
          <h3 class="font-bold text-2xl">
            {{ ts.moduleCreateTitle }}
          </h3>
          <p class="text-lg">{{ ts.moduleCreateSubTitle }}</p>
        </div>
      </template>
      <router-view @hide="hideDetails" @refresh="refreshProducts = true" />
    </Drawer>
  </section>
</template>
<style lang="scss" scoped>
#section {
  padding: 0px 18px 0px 18px !important;
}
</style>
