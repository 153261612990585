<script setup lang="ts">
import { Activity } from 'platform-unit2-api';
const props = defineProps<{
  activity: Activity;
}>();

const assetDeleted = 'thumbnail_id' in (props.activity.modified ?? {});
</script>
<template>
  <div>
    <div v-if="assetDeleted" class="flex">
      <i class="mdi mdi-image-remove pup-mr-3 text-red-500" />
      <div class="w-full">
        <div class="font-semibold pup-mb-2 w-full">All assets detached</div>
      </div>
    </div>
    <div v-else>
      <i class="mdi mdi-image-edit-outline pup-mr-3" />
      <span class="font-semibold pup-mb-2 w-full">Asset updated</span>
      <div class="w-full">
        <div>
          <Chip
            :label="
              Boolean(activity?.modified?.filename?.old)
                ? activity?.modified?.filename?.old?.toString()
                : '<empty value>'
            "
          />
          <i class="mdi mdi-arrow-right pup-mx-1" />
          <Chip
            :label="
              Boolean(activity?.modified?.filename?.new)
                ? activity?.modified?.filename?.new?.toString()
                : '<empty value>'
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
