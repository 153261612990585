<script lang="ts" setup>
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import { tsKey } from '@/general/services/translations/translation.key';
import { TranslationService } from '@/general/services/translations/translation.service';
import OverviewSidebar from '@/general/ui/components/over-view/overview-sidebar.vue';
import CardSkeleton from '@/general/ui/components/skeletons/card-skeleton.vue';
import Overview from '@/general/ui/views/over-view/over-view.vue';
import { provide, ref, Ref } from 'vue';
import AssetsForm from '../components/assets-form.vue';
import AssetsList from '../components/assets-list.vue';
import AttachAssetsModal from '../components/attach-assets-modal.vue';
import MatchMediaModal from '../components/match-media/match-media-modal.vue';
import { assetViewServiceKey } from '../keys/asset-view-service.key';

const service = useService(assetViewServiceKey, AssetViewService);
const ts = ref<TranslationService>(new TranslationService('supplier', 'media'));
provide(tsKey, ts as Ref<TranslationService>);
</script>

<template>
  <Overview :has-search="false" platform="supplier" module-name="media" :service="service">
    <template #skeleton>
      <CardSkeleton />
    </template>
    <template #content>
      <AssetsList />
    </template>
    <template #crudComponent>
      <OverviewSidebar :service="service">
        <template #form>
          <AssetsForm />
        </template>
      </OverviewSidebar>
    </template>
    <template #others>
      <ConfirmDialog group="media-dialog" />
      <AttachAssetsModal />
      <MatchMediaModal />
    </template>
  </Overview>
</template>
