<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import GenericTable from '@/general/ui/components/generic-table.vue';
import LoadingIndicator from '@/general/ui/components/skeletons/loading-indicator.vue';
import { ColumnProps } from 'primevue/column';
import { DataTableRowExpandEvent } from 'primevue/datatable';
import { TreeNode } from 'primevue/treenode';
import { categoryServiceKey } from 'supplier/modules/categories/ts/keys/categories.keys';

import { inject, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const ts = inject(tsKey)!;
const service = inject(categoryServiceKey)!;
const router = useRouter();

const expandedRows = ref<number[]>([]);

const columns: ColumnProps[] = [
  {
    class: 'flex-none',
    bodyStyle: 'width: 5rem',
    expander: true,
    headerStyle: 'width: 5rem',
    field: 'expander',
  },
  {
    header: 'Name',
    field: 'name',
  },
  {
    header: 'Parent',
    field: 'parent.name',
  },
  {
    header: 'Actions',
    field: 'actions',
  },
];

onMounted(async () => {
  service.value.treeViewNodes = await service.value.getTreeViewNodes();
});
</script>
<template>
  <GenericTable
    v-if="service.selectedTab.key === 'list'"
    v-model:expanded-rows="expandedRows"
    :value="service.categories"
    :columns="columns"
    :service="service"
    empty-state-button-icon-name="tasks"
    @row-expand="(event: DataTableRowExpandEvent) => service.loadAttributes((event.data as any).id)"
  >
    <template #actions>
      <Column class="flex-none" header-style="width: 10rem" body-style="width: 10rem">
        <template #body="slotProps">
          <div class="flex justify-content-end">
            <Button
              text
              icon="mdi mdi-pencil-outline"
              class="mr-2"
              @click="service.openCrudComponent(slotProps.data)"
            />
            <Button
              text
              severity="danger"
              icon="mdi mdi-delete-outline"
              @click="service.confirmCategoryDelete(slotProps.data)"
            />
          </div>
        </template>
      </Column>
    </template>
    <template #expansion="slotProps">
      <div
        v-if="service.categoryAttributes[slotProps.data.id]"
        class="bg-cyan-50 border-round-md p-4"
      >
        <div v-if="!service.categoryAttributes[slotProps.data.id].length" class="text-center">
          {{ ts.tModule('noAttributes') }}
        </div>
        <div
          v-for="(attr, index) in service.categoryAttributes[slotProps.data.id]"
          v-else
          :key="index"
        >
          <div class="flex justify-content-between py-2 w-full">
            <div class="w-2">{{ attr.key }}</div>
            <div class="w-8">
              <span v-for="(option, i) in attr.options?.choices" :key="i">{{ option }} , </span>
            </div>

            <Button
              text
              severity="danger"
              @click="service.detachAttribute(slotProps.data.id, attr.id)"
              >{{ ts.tModule('detach') }}</Button
            >
          </div>
        </div>
      </div>
      <LoadingIndicator v-else-if="service.loadingAttributes" />
    </template>
  </GenericTable>

  <Tree
    v-if="service.selectedTab.key === 'tree'"
    :value="service.treeViewNodes"
    selection-mode="single"
    @node-select="(node: TreeNode) => router.push({ name: 'edit-category', params: { id: node.id } })"
  />
</template>
