<template>
  <div>
    <div v-for="j in 3" :key="j" class="grid">
      <div v-for="i in 5" :key="i" class="col mb-2 p-1">
        <div class="p-3 shadow-1">
          <Skeleton width="100%" height="150px" class="mb-2" />
          <div class="align-items-center flex justify-content-between mb-3">
            <div class="mr-3 w-full">
              <Skeleton width="100%" class="mb-2" />
              <Skeleton width="80%" />
            </div>
            <Skeleton shape="circle" size="3rem" class="flex-shrink-0 mr-2" />
          </div>
          <Skeleton width="100%" height="2px" class="mb-3" />
          <Skeleton width="30%" class="mb-2" />
          <Skeleton width="100%" height="1.5rem" border-radius="2rem" class="mb-2" />
        </div>
      </div>
    </div>
  </div>
</template>
