<script setup lang="ts">
import ProductMetaEdit from 'supplier/modules/products/components/product-meta-edit.vue';
import ProductMetaLinkProducts from 'supplier/modules/products/components/product-meta-link-products.vue';
import ProductPreview from 'supplier/modules/products/components/product-preview.vue';

import { ConfirmService } from '@/general/services/confirm/confirm.service';
import { ToastService } from '@/general/services/toasts/toast.service';
import { TranslationService } from '@/general/services/translations/translation.service';

import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import Activites from 'supplier/modules/activities/views/activities.vue';
import GenerateProductData from 'supplier/modules/ai-enhancements/components/product-details/generate-product-data.modal.vue';
import { featureFlagEnum } from '@/core/featureflags/ts/service/feature-flags.enum';
import { productAttributeFieldsServiceKey } from '@/general/services/attribute-fields/service-keys';
import { formatDate } from '@/general/utils/format-date';
import { useService } from '@/general/composables/useService';
import { productDetailsServiceKey } from '../key/product-details-service.key';
import { ProductDetailsService } from '../services/product-details.service';
import ProductTasks from 'supplier/modules/products/components/product-tasks.vue';
import { inject, ref } from 'vue';

/** Services */
const toastService = ToastService.getInstance();
const ts = new TranslationService('supplier', 'products');
const confirmService = new ConfirmService();
const productAttributeFieldService = inject(productAttributeFieldsServiceKey)!;
const productDetailsService = useService(productDetailsServiceKey, ProductDetailsService);
// const dataTableFilterService = new DataTableFilterService(FilterPageKey.PRODUCT_OVERVIEW);

/** Consts */
const router = useRouter();
const route = useRoute();

const showPreviousProduct = ref(false);
const showNextProduct = ref(false);

const metaEditActive = ref(false);
const metaEditVisible = (value: boolean) => {
  metaEditActive.value = value;
};

const metaLinkProductsActive = ref(false);
const metaLinkProductsVisible = (value: boolean) => {
  metaLinkProductsActive.value = value;
};

const tasksActive = ref(false);
const tasksVisible = (value: boolean) => {
  tasksActive.value = value;
};

const actions = ref([
  {
    label: ts.tGlobal('edit'),
    icon: 'mdi mdi-pencil-outline',
    command: () => metaEditVisible(true),
  },
  {
    separator: true,
  },
  {
    label: ts.tModule('product_details.link_products.menu'),
    icon: 'mdi mdi-link',
    command: () => metaLinkProductsVisible(true),
  },
  {
    label: ts.tGlobal('delete'),
    class: 'delete',
    icon: 'mdi mdi-delete-outline',
    command: () => {
      confirmService.confirmDelete({
        callback: () => deleteProduct(),
        group: 'product-delete-modal',
        message:
          productDetailsService.currentVariant?.module_id == null &&
          productDetailsService.productVariants?.length > 1
            ? ts.tModule('deleteProduct.withVariants')
            : ts.deleteConfirm(),
      });
    },
  },
]);

async function getProduct(sequence: 'prev' | 'next') {
  switch (sequence) {
    case 'next': {
      if (productDetailsService.nextProduct?.id != null) {
        router.push({
          name: 'product-details',
          params: { id: productDetailsService.nextProduct?.id },
        });
        await productDetailsService.getProduct(productDetailsService.nextProduct.id);
      }

      break;
    }

    case 'prev': {
      if (productDetailsService.previousProduct?.id != null) {
        router.push({
          name: 'product-details',
          params: { id: productDetailsService.previousProduct?.id },
        });
        await productDetailsService.getProduct(productDetailsService.previousProduct.id);
      }

      break;
    }
  }
}

const deleteProduct = async () => {
  try {
    if (!isNaN(Number(route.params.id))) {
      await productDetailsService.deleteProduct(Number(route.params.id));
    }

    router.push({ name: 'products' } as RouteLocationRaw);
    toastService.displaySuccessToast(ts.deleteSuccess());
  } catch (err: any) {
    toastService.displayErrorToast(ts.deleteFailed());
  }
};

/** Activity sidebar */
const activitySidebarActive = ref(false);
const activitySidebarVisible = (value: boolean) => {
  activitySidebarActive.value = value;
};
</script>
<template>
  <div>
    <ProductMetaEdit :is-active="metaEditActive" @hide="metaEditVisible(false)" />

    <ProductMetaLinkProducts
      :is-active="metaLinkProductsActive"
      @hide="metaLinkProductsVisible(false)"
    />

    <ProductTasks :is-active="tasksActive" @hide="tasksVisible(false)" />

    <!-- Activity sidebar -->
    <Activites
      v-if="activitySidebarActive"
      :is-active="activitySidebarActive"
      module-name="products"
      :module-id="productDetailsService.currentVariant?.id"
      @hide="activitySidebarVisible(false)"
    />

    <ConfirmDialog group="product-delete-modal"></ConfirmDialog>
    <div class="flex flex-column justify-content-between">
      <div class="align-items-center flex">
        <FeatureFlag :flag="featureFlagEnum.AI_ENHANCEMENTS">
          <GenerateProductData />
        </FeatureFlag>

        <Button
          class="mr-2"
          plain
          outlined
          icon="mdi mdi-calendar-check"
          :label="ts.tModule('tasks.title')"
          @click="tasksVisible(true)"
        />
        <div>
          <Button
            class="mr-2"
            plain
            outlined
            icon="mdi mdi-clock-outline"
            :label="ts.tModule('product_details.product_action_group.activity')"
            @click="activitySidebarVisible(true)"
          />
        </div>
        <SplitButton
          :label="ts.tGlobal('actions')"
          :model="actions"
          class="mr-2 p-button-plain"
          outlined
          plain
          :pt="{
            pcButton: {
              root: {
                class: 'p-button-plain',
              },
            },
            pcDropdown: {
              root: {
                class: 'p-button-plain',
              },
            },
          }"
        />
        <div class="border-700 border-left-1 h-full inline-flex ml-2 mr-3 w-1px" />
        <span class="p-buttonset">
          <Button
            plain
            outlined
            class="border-noround-right"
            icon="mdi mdi-chevron-left"
            :disabled="
              productDetailsService.previousProduct == null ||
              productAttributeFieldService.loading ||
              productDetailsService.loadingPrevAndNextProducts
            "
            @mouseover="showPreviousProduct = true"
            @mouseleave="showPreviousProduct = false"
            @click="() => getProduct('prev')"
          />
          <div
            v-if="productDetailsService.previousProduct != null && showPreviousProduct"
            class="absolute pup-m-2 right-0"
          >
            <ProductPreview :product="productDetailsService.previousProduct" />
          </div>
          <Button
            plain
            outlined
            class="border-noround-left"
            icon="mdi mdi-chevron-right"
            :disabled="
              productDetailsService.nextProduct == null ||
              productAttributeFieldService.loading ||
              productDetailsService.loadingPrevAndNextProducts
            "
            @mouseover="showNextProduct = true"
            @mouseleave="showNextProduct = false"
            @click="() => getProduct('next')"
          />
          <div
            v-if="productDetailsService.nextProduct != null && showNextProduct"
            class="absolute pup-m-2 right-0"
          >
            <ProductPreview :product="productDetailsService.nextProduct" />
          </div>
        </span>
      </div>
      <p class="mt-4 text-right">
        {{ ts.tGlobal('updated_at') }}:
        {{
          productDetailsService.currentVariant
            ? formatDate(new Date(productDetailsService.currentVariant.updated_at))
            : ''
        }}
      </p>
    </div>
  </div>
</template>
