import { TranslationObjectAction } from '@/core/i18n/ts/interfaces/translation-object-action.interface';

const update: TranslationObjectAction = {
  title: 'Edit Import Mapping Setting',
  subTitle: 'Here you can edit an Import Mapping Setting.',
};

const translationObject = {
  title: 'Import Mapping Setting | Import Mapping Setting',
  entity: 'Import Mapping Setting',

  placeholder: 'Select a setting | Select settings',
  regex: 'Regex',
  testHeader: 'Test source',
  delimiter: 'Source delimiter',
  customRegex: 'Custom regex',
  headerResult: 'Source result',
  pathResult: 'Path result',
  fillHeader: 'Fill in test source to get a result',
  settings: 'Mapping Setting',
  pathValidator: 'Path validator',
  pathDefinition: 'Path configuration',
  noPathResult: 'No path found, try changing the regex or your test header.',

  regexExplained:
    'The regular expression (regex) is used to extract specific path details from your input source within the mapped items. It identifies patterns to locate the required path.',
  testSourceExplained:
    'Test your input sources to verify if the paths are correctly extracted based on the provided regular expression.',
  delimiterExplained:
    'The delimiter serves as a substitute for matches found in your input paths. It separates or formats the path in the desired way.',
  sourceResultExplained:
    'The expected source inputs, which fills in the paths or information within your mapped items, after applying the regex and delimiter.',
  update,

  uniqueProductIdentifierTitle: 'Unique Product Identifiers',
  uniqueProductIdentifierAttributesExplained:
    'These attributes uniquely identify a product and specify which data model fields serve as import sources for core product information, displayed in the corresponding column on the product overview page.',
  uniqieGtin: 'GTIN attribute',
  uniqueDisplayName: 'Display Name attribute',
  uniqueBrand: 'Brand attribute',
  uniqueCategory: 'Category attribute',
};

export default translationObject;
