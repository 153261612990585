import { Activity } from 'platform-unit2-api/activities';
<script setup lang="ts">
import BetterTranslationService from '@/general/services/translations/better-translation.service';
import ActivityBox, { ActivityBoxProps } from './activity-box.vue';

const props = defineProps<ActivityBoxProps>();
const ts = new BetterTranslationService().platform('supplier').module('activities');

const isProduct = () => {
  return props.activity?.modified?.variant_uuid?.old != null;
};

const isAsset = () => {
  return props.activity?.modified?.filename?.old != null;
};
</script>
<template>
  <ActivityBox v-bind="{ ...props }">
    <div class="flex">
      <i v-if="isProduct()" class="align-items-center mdi mdi-minus-thick pup-mr-3 text-red-500" />
      <i v-if="isAsset()" class="align-items-center mdi mdi-image-remove pup-mr-3 text-red-500" />
      <div>
        <div v-if="isProduct() && !partOfAnAccordion" class="font-semibold pup-mb-2">
          {{ ts.translate('productDeleted') }} -
          {{ activity.modified?.variant_name?.old ?? 'Master data' }}
        </div>

        <div v-if="isProduct() && partOfAnAccordion" class="font-semibold pup-mb-2">
          {{ activity.modified?.variant_name?.old ?? 'Master data' }}
        </div>

        <div v-if="isAsset()" class="font-semibold pup-mb-2">
          {{ ts.translate('assetDeleted') }}
        </div>
        <div v-if="isProduct()">
          {{ activity?.modified?.display_name?.old }}
        </div>
        <div v-if="isAsset()">
          {{ activity.modified?.filename?.old }}
        </div>
      </div>
    </div>
  </ActivityBox>
</template>
