<script lang="ts" setup>
import { useService } from '@/general/composables/useService';
import AssetViewService from '@/general/services/overview-service/modules/media/asset-view.service';
import LabeledTag from '@/general/ui/components/labeled-tag.vue';
import StatusChip from '@/general/ui/components/status-chip.vue';
import SvgIcon from '@/general/ui/components/svg-icon.vue';
import Upload from '@/general/ui/components/upload.vue';
import { Upload as UploadType } from 'platform-unit2-api/uploads';
import { onMounted, ref } from 'vue';
import { assetViewServiceKey } from '../keys/asset-view-service.key';

interface Props {
  image: UploadType;
  hideSelect?: boolean;
  colSize?: number;
}

defineProps<Props>();
const uploadRef = ref();
// Services
const service = useService(assetViewServiceKey, AssetViewService);

onMounted(() => {
  // This is needed because the preview is set to false
  // PrimeVue's function is the same, just wrapped in an if statement checking the preview boolean
  if (uploadRef.value?.imageRef != null) {
    uploadRef.value.imageRef.onImageClick = () => {
      uploadRef.value.imageRef.maskVisible = true;
      setTimeout(() => {
        uploadRef.value.imageRef.previewVisible = true;
      }, 25);
    };
  }
});
</script>

<template>
  <div class="relative">
    <StatusChip
      v-if="service.isChecked(image)"
      class="absolute m-2 right-0 text-2xl z-1"
      label=""
      :icon-only="true"
    />
    <Card
      :class="`${'clickable'} ${service.isChecked(image) ? 'border-primary border-2' : ''}`"
      class="align-items-between flex flex-column justify-content-between pup-p-5 w-full"
      @click="service.toggleChecked(image)"
    >
      <template #content>
        <div class="image-container">
          <Upload
            v-if="image.public_thumbnail_url"
            ref="uploadRef"
            :preview="false"
            :upload="image"
            class="image"
          />
          <SvgIcon v-else :filename="image.filename" class="image" />
        </div>

        <div
          class="flex flex-column my-3 overflow-hidden text-center text-overflow-ellipsis w-full white-space-nowrap"
        >
          <span>{{ image.filename }}</span>
        </div>

        <div class="align-content-center grid justify-content-between">
          <div class="col-8 flex gap-1">
            <LabeledTag :filename="image.filename" />

            <Tag
              v-if="image.asset_type"
              :value="image?.asset_type?.name"
              severity="secondary"
              class="align-self-center border-round-sm flex overflow-hidden white-space-nowrap"
              style="text-overflow: ellipsis"
            />
          </div>

          <div class="col-4 flex">
            <Button
              v-if="!service.disabled && image.public_thumbnail_url != null"
              text
              plain
              icon="mdi mdi-eye-outline"
              @click.stop="uploadRef.imageRef.onImageClick()"
            />
            <Button
              v-if="!service.disabled"
              text
              plain
              icon="mdi mdi-pencil-outline"
              @click.stop="!service.disabled ? service?.redirectToUpdate(image.id) : () => {}"
            />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<style lang="scss" scoped>
.image-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
