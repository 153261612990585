<script setup lang="ts">
import { tsKey } from '@/general/services/translations/translation.key';
import { inject } from 'vue';
import { serviceKey } from '../ts/keys/asset-types.keys';

const ts = inject(tsKey)!;
const service = inject(serviceKey);
</script>

<template>
  <div v-if="service != null">
    <div class="field mb-3">
      <label>{{ ts.tGlobal('name') }}</label>
      <div>
        <InputText
          v-model="service.partialObject.name"
          class="w-full"
          :class="{ 'p-invalid': service.hasError('name') }"
        />
        <small
          v-if="service.hasError('name')"
          :class="{ 'p-error block': service.hasError('name') }"
          class="hidden"
          >{{ service.fieldErrorMessage('name').toString() }}</small
        >
      </div>
    </div>
    <div class="field mb-3">
      <label>{{ ts.tModule('suffix') }}</label>
      <div>
        <InputText
          v-model="service.partialObject.suffix"
          class="w-full"
          :class="{ 'p-invalid': service.hasError('suffix') }"
        />
        <small
          v-if="service.hasError('suffix')"
          :class="{ 'p-error block': service.hasError('suffix') }"
          class="hidden"
          >{{ service.fieldErrorMessage('suffix').toString() }}</small
        >
      </div>
    </div>
  </div>
</template>
