import { Activity } from 'platform-unit2-api/activities';
<script setup lang="ts">
import BetterTranslationService from '@/general/services/translations/better-translation.service';
import Flag from '@/general/ui/components/attribute-fields/flag.vue';
import { Activity, ProductFieldCompact } from 'platform-unit2-api';
import ActivityBox, { ActivityBoxProps } from './activity-box.vue';
import CompletenessChange from './completeness-change.vue';
import MediaChanges from './media-changes.vue';
import StatusChange from './status-change.vue';
import { useService } from '@/general/composables/useService';
import { activityServiceServiceKey } from '../activity.key';
import ActivityService from '../activity.service';

const props = defineProps<ActivityBoxProps & { partOfAnAccordion?: boolean }>();
const ts = new BetterTranslationService().platform('supplier').module('activities');

const activityService = useService(activityServiceServiceKey, ActivityService);

const getAttribute = (activity: Activity) => {
  const attr =
    activity.modified &&
    Object.keys(activity.modified).find((key) => !activityService.blackList.includes(key));
  return attr
    ? {
        name: attr,
        new: activity.modified && activity.modified[attr].new,
        old: activity.modified && activity.modified[attr].old,
      }
    : {};
};

const renderBox = () => {
  if (activityService.isCompletenessScoreChange(props.activity)) {
    return props.activity.resource != null;
  }

  if (activityService.isMedia(props.activity)) {
    return props.activity.modified?.thumbnail_id?.new == null;
  }

  return true;
};
</script>
<template>
  <ActivityBox v-if="renderBox()" v-bind="{ ...props }">
    <div
      v-if="
        activityService.isMedia(activity) ||
        activityService.isStatusChange(activity) ||
        activityService.isStoryChange(activity) ||
        activityService.isCompletenessScoreChange(activity)
      "
    >
      <CompletenessChange
        v-if="activityService.isCompletenessScoreChange(activity)"
        :activity="activity"
      />
      <MediaChanges v-if="activityService.isMedia(activity)" :activity="activity" />
      <StatusChange v-if="activityService.isStatusChange(activity)" :activity="activity" />

      <div v-if="activityService.isStoryChange(activity)">
        <div class="font-semibold pup-mb-2">{{ ts.translate('storyCreated') }}:</div>
        <div>
          {{ activity?.modified?.data?.new }}
        </div>
      </div>
    </div>

    <div v-else>
      <span v-if="!partOfAnAccordion" class="font-semibold"
        >{{ ts.translate('updatedAttributes') }}:</span
      >
      <div class="align-items-center flex">
        <Flag
          :locale="locales.find((l) => l.id === ((activity.resource as ProductFieldCompact)?.locale_id ?? activity.modified?.locale_id?.new))"
          :global="!locales.find((l) => l.id === ((activity.resource as ProductFieldCompact)?.locale_id ?? activity.modified?.locale_id?.new))"
          class="border-gray-50 pup-p-3"
        />
        {{ getAttribute(activity)?.name }}:
        <Chip
          :label="
            Boolean(getAttribute(activity)?.old)
              ? getAttribute(activity)?.old?.toString()
              : '<empty value>'
          "
        />
        <i class="mdi mdi-arrow-right pup-mx-1" />
        <Chip
          :label="
            Boolean(getAttribute(activity)?.new)
              ? getAttribute(activity)?.new?.toString()
              : '<empty value>'
          "
        />
      </div>
    </div>
  </ActivityBox>
</template>
