import { Defaults } from '@/general/utils/constants/defaults';
import Keycloak, { KeycloakResourceAccess } from 'keycloak-js';
import { DebugLogger } from '../main/debug.logger';
import { SsoRestService } from 'platform-unit2-api/sso';
import { installHttpClient } from '../main/httpClient.install';

interface Callback<T1 = void> {
  (): T1;
}

export class KeycloakService {
  private static _instance: KeycloakService;

  private _ssoRestService: SsoRestService;
  private _keycloak: Keycloak;

  constructor() {
    if (KeycloakService._instance) {
      throw new Error('Another instance already exists, use KeycloakService.getInstance() instead');
    }

    this._keycloak = new Keycloak({
      url: import.meta.env.VITE_APP_KEYCLOAK_URL,
      realm: import.meta.env.VITE_APP_KEYCLOAK_REALM,
      clientId: import.meta.env.VITE_APP_KEYCLOAK_CLIENT,
    });

    KeycloakService._instance = this;
    this._ssoRestService = new SsoRestService();
  }

  /**
   * Get the instance of the feature flag service.
   * @returns The instance of the feature flag service.
   */
  public static getInstance(): KeycloakService {
    if (KeycloakService._instance == null) {
      new KeycloakService();
    }

    return KeycloakService._instance;
  }

  public async init(callback?: Callback) {
    await this._keycloak
      .init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      })
      .then(async () => {
        if (!this._keycloak.authenticated) {
          this.login(window.location.href);
          return;
        }

        installHttpClient();
        localStorage.setItem('keycloakToken', this._keycloak.token ?? '');
        localStorage.setItem('keycloakRefreshToken', this._keycloak.refreshToken ?? '');

        this._keycloak.onTokenExpired = () => this.onTokenExpired();

        if (this._keycloak.idTokenParsed && this._keycloak.tokenParsed?.idp_alias) {
          await this._validateSso(
            this._keycloak.idTokenParsed.email,
            this._keycloak.tokenParsed.idp_alias,
          );
        }
      })
      .catch((err: any) => {
        throw new Error('Keycloak initialization failed', err);
      });

    callback?.();
  }

  public async resolveDebug() {
    const debugMode =
      window.location.hostname?.includes('localhost') ||
      window.location.hostname?.includes('127.0.0.1') ||
      window.location.hostname?.includes('staging') ||
      window.location.hostname?.includes('dev-retail');

    DebugLogger.info('[Keycloak]', 'Debug mode enabled');

    (this._keycloak as any).enableLogging = debugMode;
  }

  private async onTokenExpired() {
    // Get the token and refresh token from localStorage
    const token = localStorage.getItem('keycloakToken');
    const refreshToken = localStorage.getItem('keycloakRefreshToken');

    // Set the token and refresh token in the Keycloak instance
    if (token && refreshToken) {
      this._keycloak.token = token;
      this._keycloak.refreshToken = refreshToken;

      this.updateToken().catch((error) => {
        DebugLogger.error('[keycloak]', 'Token refresh error:', error);

        // Handle token refresh failure (e.g. redirect to login page)
        this.logout('/login');
      });
    } else {
      await this.login(window.location.href);
    }
  }

  public async login(redirectTo: string) {
    await this._keycloak.login({ redirectUri: redirectTo });
  }

  public async logout(redirectTo: string) {
    await this._keycloak.logout({ redirectUri: redirectTo });
  }

  public async updateToken(): Promise<string> {
    const token = localStorage.getItem('keycloakToken');
    const refreshToken = localStorage.getItem('keycloakRefreshToken');

    if (token && refreshToken) {
      this._keycloak.token = token;
      this._keycloak.refreshToken = refreshToken;
    }

    await this._keycloak.updateToken(Defaults.MIN_TOKEN_EXPIRATION_THRESHOLD);

    localStorage.setItem('keycloakToken', this._keycloak.token ?? '');
    localStorage.setItem('keycloakRefreshToken', this._keycloak.refreshToken ?? '');

    return this._keycloak.token as string;
  }

  public isAuthenticated() {
    return this._keycloak.authenticated;
  }

  public accountmanagement() {
    return this._keycloak.createAccountUrl();
  }

  public resourceAccess(): KeycloakResourceAccess | undefined {
    return this._keycloak.tokenParsed?.resource_access;
  }

  public hasAccessToResource(resource: string): boolean {
    return (this.resourceAccess()?.[resource]?.roles ?? []).length !== 0;
  }

  private async _validateSso(email: string, idp_alias: string) {
    await this._ssoRestService.validateSso({ email, idp_alias }).catch((error) => {
      if (error.response.status === 403) {
        this.logout(`403/ssoNotConfigured`);
      }

      if (error.response.status === 404) {
        this.logout('403/userNotInvited');
      }
    });
  }
}
