import { RouteFactory } from '@/core/router/route.factory';
import { RouterService } from '@/core/router/router.service';
import AssetTypes from 'supplier/modules/asset-types/views/asset-type.view.vue';
import Collection from 'supplier/modules/media/collection/collection.view.vue';
import { RouteRecordRaw } from 'vue-router';
import assetsForm from '../assets/components/assets-form.vue';
import Assets from '../assets/views/assets.view.vue';

const tabs = [
  {
    label: 'All assets',
    key: 'media-assets',
    command: () => {
      RouterService.getInstance().router.push({ name: 'media-assets' });
    },
  },
  {
    label: 'Collections',
    key: 'media-collection',
    command: () => {
      RouterService.getInstance().router.push({ name: 'media-collection' });
    },
  },
  {
    label: 'Asset types',
    key: 'asset-types',
    command: () => {
      RouterService.getInstance().router.push({ name: 'asset-types' });
    },
  },
];

const routeFactory = new RouteFactory({
  platform: 'supplier',
  module: 'media',
  tabs: tabs,
  navigationGroup: 'media',
});

const mediaRoutes: RouteRecordRaw[] = [
  {
    path: '/media',
    name: 'media',
    redirect: { name: 'media-assets' },
  },

  routeFactory.createAppRoute({
    path: '/media/assets',
    name: 'media-assets',
    title: 'Assets',
    component: Assets,
    children: [
      routeFactory.createChildRoute({
        name: 'update-media-asset',
        path: ':id',
        component: assetsForm,
        title: 'Edit upload',
      }),
    ],
  }),

  RouteFactory.createAppRoute({
    path: '/media/collection',
    name: 'media-collection',
    title: 'Collection',
    component: Collection,
    tabs: tabs,
  }),

  routeFactory.createAppRoute({
    path: '/media/asset-types',
    name: 'asset-types',
    title: 'Asset types',
    translations: {
      module: 'assetTypes',
    },
    component: AssetTypes,
    children: [
      RouteFactory.createChildRoute({
        name: 'create-asset-type',
        path: 'create',
        title: ' Create asset type',
      }),
      RouteFactory.createChildRoute({
        name: 'update-asset-type',
        path: ':id',
        title: 'Edit asset type',
      }),
    ],
  }),
];

export default mediaRoutes;
